@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.resume-page { background-image: url('../../assets/login/login-hero.svg');  min-height: 100vh; height: 100%; width: 100%; background-size: cover; background-attachment: fixed; object-fit: cover; background-position: center center; background-repeat: repeat-y;  position: relative; height: 100vh; width: 100%;}
.resume-page .resume-box { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: inline-flex; padding: 40px 32px; flex-direction: column; align-items: center; gap: 40px; border-radius: 12px; background: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 250px 0px rgba(255, 255, 255, 0.15); width: 480px;}
.resume-box .resume-text { color: #F8FAFC; text-align: center; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 400; line-height: 22px; margin: 0; white-space: pre-line;}
.resume-box .resume-btn-box { display: flex; flex-direction: row; width: 100%; gap: 16px;}
.resume-btn-box .resume-btn { width: 100%; border-radius: 8px; background-color: #F1F5F9; padding: 16px; color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s; }
.resume-btn-box .resume-btn:hover { color: #11274B; background-color: #E2E8F0;}
.resume-btn-box .resume-back-btn {  width: 100%; border-radius: 8px; background: #FFCA27; padding: 16px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s;}
.resume-btn-box .resume-back-btn:hover { color: #64748B; background-color: #FFC000;}


.template-container { background: #F1F5F9; }

@media(max-width: 480px) {
    .template-container { padding: 90px 12px;}
}

.template-search { border-radius: 8px; border: 1px solid #808080; background:#FFF; padding: 8px 8px 8px 10px; display: flex; justify-content: space-between; gap: 8px;}
.template-search input { border: none; outline: none; color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }

.template-body { display: flex; gap: 16px; align-items: flex-start;}
.template-body .resume-tamp { border-radius: 12px; background: #FFF; display: flex; padding: 16px 10px; flex-direction: column; gap: 12px; width: 246px;}
.resume-tamp .resume-tamp-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px;}
.resume-tamp .resume-tamp-text { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 4px;}

.coral-card { display: flex; padding: 16px 12px; flex-direction: column; gap: 8px; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF;}
.coral-card .coral-title { color:  #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.coral-card .coral-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.coral-card .coral-img { width: 202px; height: 168px; border-radius: 1px; background: #CBD5E1;}
.coral-card .coral-apply { display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border-radius: 8px; background: #F1F5F9; border: none; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.coral-card .coral-apply:hover { color: #11274B; background-color: #E2E8F0;}
.coral-slider { padding-bottom: 20px;}

@media(max-width: 480px) {
    .coral-card { display: flex; padding: 16px 12px; flex-direction: column; gap: 8px; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; max-width: 226px;}
    .resume-tamp-text { margin: 0; color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; padding-bottom: 4px;}
    .coral-card .coral-img { width: 100%; height: 168px; border-radius: 1px; background: #CBD5E1;}
    .coral-card-3 { display: flex; padding: 16px 12px; flex-direction: column; gap: 8px; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF;}
    .coral-card-3 .coral-title-3 { color:  #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
    .coral-card-3 .coral-text-3 { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
    .coral-card-3 .coral-img-3 { width: 100%; height: 168px; border-radius: 1px; background: #CBD5E1;}
    .coral-card-3 .coral-apply-3 { display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border-radius: 8px; background: #F1F5F9; border: none; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
    .coral-card-3 .coral-apply-3:hover { color: #11274B; background-color: #E2E8F0;}

    .design-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 0; padding-bottom: 6px;}
    .design-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
}
.more-options { display: flex; flex-direction: column; gap: 4px;}
.basic-card { display: flex; padding: 16px 12px; flex-direction: column; gap: 8px; border-radius: 12px; border: 1px solid #2E3D55; background: #11274B;}
.basic-card .basic-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.basic-card .basic-text { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.basic-card .basic-apply { display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border-radius: 8px; background: #F1F5F9; border: none; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.basic-card .basic-apply:hover { color: #11274B; background-color: #E2E8F0;}


.sub-card { border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; display: flex; padding: 16px 12px; flex-direction: column; gap: 8px;}
.sub-card .sub-title {  color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.sub-card .sub-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.sub-card .sub-features { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.sub-card .sub-learn-more { border: none; background-color: transparent; color: #11274B; text-align: left; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding: 0;}

.sub-card .sub-get-plan {  width: 100%; border-radius: 8px; background: #FFCA27; padding: 6px 12px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s;}
.sub-card .sub-get-plan:hover { color: #64748B; background-color: #FFC000;}


.design-container { display: flex; flex-direction: column; width: 100%; position: -webkit-sticky; position: sticky; top: 0;}
.design-body { width: 100%; display: flex; padding: 44px 71px 35.25px 71px; flex-direction: column; justify-content: flex-end; align-items: center; border-radius: 0px 0px 12px 12px; background: #FFF1F1;}
.design-head { display: flex; justify-content: space-between; align-items: center; padding: 16px 16px 8px 16px; border-radius: 16px 16px 0px 0px; background: #FFF; }
.design-head .design-img { width: 60px; height: 60px; background: #CBD5E1; margin-right: 16px;}
.design-head .design-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin: 0;}
.design-head .design-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.design-btns { display: flex; gap: 16px;}
.design-btns .design-edit { display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border-radius: 8px; background: #F1F5F9; border: none; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.design-btns .design-edit:hover { color: #11274B; background-color: #E2E8F0;}
.design-btns .design-save {  width: 100%; border-radius: 8px; background: #FFCA27; padding: 6px 12px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s;}
.design-btns .design-save:hover { color: #64748B; background-color: #FFC000;}
.design-save-btn { width: 100%; border-radius: 8px; background: #FFCA27; padding: 6px 12px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s; margin-top: 4px;}
.design-save-btn:hover { color: #64748B; background-color: #FFC000;}
.design-btns { display: flex; gap: 16px;}
.design-btns-mobile { display: none;}


@media(max-width: 480px) {
    .design-btns { display: none;}
    .design-btns-mobile { display: flex; gap: 8px;}
}

.template-body .paid-tamp { border-radius: 16px; background: #FFF; display: flex; padding: 16px; flex-direction: column; gap: 20px; max-width: 350px; width: 100%;}
.nav-tabs { border: none !important; margin-bottom: 0 !important;}
.nav-tabs .nav-link { width: 50%; padding: 10px 12px; color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; border-bottom: 2px solid transparent !important; border: none;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { color: #172742; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; border-bottom: 2px solid #172742 !important; border: none; background-color: transparent; width: 50%;}
.tab-content { padding-top: 0; height: 910px; overflow-y: scroll;}
.tab-content::-webkit-scrollbar { width: 4px; }
/* .tab-content::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  } */
.tab-content::-webkit-scrollbar-thumb { background: #D9D9D9; border-radius: 100px;}
.paid-title { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin: 0;}
.paid-btn  { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; border: none; background-color: transparent;}


.coral-2-container { display: flex; flex-direction: column; gap: 20px;}
.coral-2-box { display: flex; gap: 10px; flex-wrap: wrap;}
.coral-card-2 { border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; display: flex; padding: 16px 12px; flex-direction: column; gap: 8px; width: 149px;}
.coral-card-2 .coral-title { margin: 0; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.coral-card-2 .coral-text { margin: 0; color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.coral-card-2 .coral-2-img { margin: 0; width: 125px; height: 165px; border-radius: 1px; background: #CBD5E1;}

.resume-file { margin-top: 24px; border-radius: 16px 16px 0px 0px; background: #FFF; display: flex; width: 100%; padding: 16px; flex-direction: column; gap: 24px;}
.resume-file .resume-file-title { margin: 0; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.resume-file-card { display: flex; justify-content: space-between; align-items: center; }
.resume-file-card .resume-file-card-title { margin: 0; color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.resume-file-card .resume-file-card-text { margin: 0; padding-top: 4px; color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }

@media(max-width: 480px) {
    .resume-file { margin-top: 8px; border-radius: 12px; background: #FFF; display: flex; width: 100%; padding: 16px; flex-direction: column; gap: 12px;}
    .resume-file .resume-file-title { margin: 0; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
    .resume-file-card { display: flex; justify-content: space-between; align-items: center; }
    .resume-file-card .resume-file-card-title { font-size: 12px; line-height: 16px; }
    .resume-file-card .resume-file-card-text { font-size: 10px; line-height: 16px; }

}

.personal-from { padding: 16px; border-radius: 12px; background-color: rgba(255, 255, 255, 0.85); margin: 0 20px 8px 20px;}
.personal-from- { border-radius: 8px; background-color: rgba(237, 237, 237, 0.6); margin: 0;}
.personal-from- .resume-box { padding: 10px 8px; border-radius: 8px;}
.personal-from- .personal-form-title { display: flex; align-items: center; justify-content: space-between; padding: 10px 12px; border-radius: 8px; background-color: rgba(255, 255, 255, 1); margin: 0; border: 1px solid #E2E8F0;}


.resume-design { background-color: #FFF; padding: 30px 30px; width: 100%; max-width: 595px; height: 842px;}
.design-item .design-name { color: #101214; font-family: 'Poppins', sans-serif; font-size: 28.235px; font-style: normal; font-weight: 700; line-height: 32.941px;  margin-bottom: 0;}
.design-item .design-designation { color: #101214; font-family: 'Poppins', sans-serif; font-size: 21.176px; font-style: normal; font-weight: 400; line-height: 32.941px; margin-bottom: 0;}
.skill-resume { padding: 0 32px;}

.design-experience { display: flex; flex-direction: column; gap: 20px; width: 100%;}
.design-skill { display: flex; flex-direction: column; gap: 20px; width: 50%; width: 100%;}
.design-experience-title { color: #73808D; font-family: 'Poppins', sans-serif; font-size: 8.235px; font-style: normal; font-weight: 400; line-height: 21.176px; letter-spacing: 2.353px; text-transform: uppercase; margin-bottom: 4px;}
.design-experience-titles { color: #101214; font-family: 'Poppins', sans-serif; font-size: 11px; font-style: normal; font-weight: 700; line-height: 21.176px; margin-bottom: 0; padding-top: 0px;}
.design-experience-text { color: #101214; font-family: 'Poppins', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 18px; margin-bottom: 0;}
.design-experience-text-1 { color: #73808D; font-family: 'Poppins', sans-serif; font-size: 11px; font-style: normal; font-weight: 400; line-height: 18px; margin-bottom: 0;}
.design-experience-text-11 { color: #73808D; font-family: 'Prata', sans-serif; font-size: 11px; font-style: normal; font-weight: 400; line-height: 18px; margin-bottom: 0;}
.design-experience-text-2 { color: #73808D; font-family: 'Poppins', sans-serif; font-size: 10.588px; font-style: normal; font-weight: 700; line-height: 21.176px; margin-bottom: 0;}

.resume-box-1 { background-color: #FFFFFF; height: 842px; max-width: 595px; width: 100%; padding: 24px;}
.resume-box-1 .r-title { font-family: 'Bebas Neue', sans-serif; font-weight: 400; font-size: 66px; line-height: 66px; text-align: center; margin: 0;}
.resume-box-1 .r-text { font-family: 'Roboto', sans-serif; font-weight: 700; font-size: 12px; line-height: 19.64px; text-align: center;}
.r-width { width: 100%; display: flex; gap: 36px; margin-top: 40px;}
.r-width .r-left { max-width: 227px; width: 100%; }
.r-width .r-right { max-width: 490px; width: 100%; }
.r-card-title { font-family: 'Bebas Neue', sans-serif; font-size: 21px; font-weight: 400; line-height: 25px; padding: 16px 0px 12px 0px; border-bottom: 1px solid #000000;}
.r-card-text { font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 12px; line-height: 15px; padding-top: 6px; margin-bottom: 0;}

.form-to {font-family: 'Roboto', sans-serif; font-weight: 700; font-size: 12px; line-height: 15px; color: #000000; padding-top: 14px;}


.custom-row {
    --bs-gutter-x: unset !important;
  }
.resume-box-2 { background-color: #FFF; height: 842px; max-width: 595px; width: 100%; overflow: hidden;}
.resume-box-2 .r-right-top { padding: 51px 51px 20px 29px; background-color: #EAEDF3; width: 100%;}
.resume-box-2 .r-right-bottom { padding: 17px 51px 20px 29px; width: 100%;}
.r-right-top .r-title { font-family: 'Bebas Neue', sans-serif; font-weight: 400; font-size: 66px; line-height: 66px; text-align: left; margin: 0;}
.r-right-top .r-text { font-family: 'Roboto', sans-serif; font-weight: 700; font-size: 12px; line-height: 19.64px; text-align: left;}
.resume-box-2 .r-2-left { background-color: #02266C; width: 100%; padding: 51px 16px;}
.resume-box-2 .r-2-right { background-color: #FFFFFF; width: 100%; }
.r-card-title-white { font-family: 'Bebas Neue', sans-serif; color: #FFFFFF; font-size: 21px; font-weight: 400; line-height: 25px; padding: 12px 0px 12px 0px; border-bottom: 1px solid #FFFFFF;}
.r-card-text-white { font-family: 'Poppins', sans-serif; color: #FFFFFF; font-weight: 400; font-size: 12px; line-height: 15px; padding-top: 6px; margin-bottom: 0;}


.resume-box-3 { position: relative; max-width: 595px; width: 100%; height: 842px; background-color: #FFF;}
.resume-box-3-top { padding: 36px 40px 60px 32px; background-image: url('../../assets/talent/resume-banner.svg'); height: 200px; width: 100%; object-fit: cover; top: 0;}
.r-title-1 { font-family: 'Prata', sans-serif; font-weight: 400; font-size: 14px; line-height: 24px; color: #CA6D18; margin: 0;}
.r-text-1 { font-family: 'Prata', sans-serif; font-weight: 400; font-size: 35px; line-height: 42px; color: #222222; margin: 0;}
.resume-box-3-bottom { display: flex; gap: 32px; padding: 32px; padding-bottom: 24px;}
.resume-box-3-bottom .resume-box-3-left {width: 50%;}
.resume-box-3-bottom .resume-box-3-right {width: 50%;}
.r-title-1-black { font-family: 'Prata', sans-serif; font-weight: 400; font-size: 14px; line-height: 24px; color: #000000; margin: 0; padding-top: 16px;}
.r-title-1-black- { font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 14px; line-height: 24px; color: #000000; margin: 0; padding-top: 0px;}
.r-text-1-black { font-family: 'Prata', sans-serif; color: #000000; font-weight: 400; font-size: 11px; line-height: 14px; margin-bottom: 0;}
.five-name { color: #1C8EB5; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; margin: 0;}
.five-text { color: #222222; font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; margin: 0;}


.resume-box-4 { position: relative; max-width: 595px; width: 100%; height: 842px; background-color: #FFF;}
.resume-box-4-top { padding: 36px 40px 60px 32px; background-color: rgba(228, 246, 251, 0.5); height: 200px; width: 100%; object-fit: cover; top: 0;}
.r-title-4-1 { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 16px; line-height: 24px; color: #000000; margin: 0;}
.r-text-4-1 { font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 16px; line-height: 24px; color: rgba(28, 142, 181, 1); margin: 0;}
.resume-box-4-bottom { display: flex; gap: 32px; padding: 32px;}
.resume-box-4-bottom .resume-box-4-left {width: 50%;}
.resume-box-4-bottom .resume-box-4-right {width: 50%;}
.r-title-4-left { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 14px; line-height: 20px; color: rgba(28, 142, 181, 1); margin: 0; padding-bottom: 12px;}
.r-title-4-1-black { font-family: 'Poppins', sans-serif; color: #000000; font-weight: 600; font-size: 14px; line-height: 20px; margin-bottom: 0;}
.r-text-4-black { font-family: 'Poppins', sans-serif; color: #000000; font-weight: 400; font-size: 14px; line-height: 18px; margin-bottom: 0; padding-top: 8px;}

.resume-email { color: #0E6CC2; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 14px; line-height: 18px; margin: 0;} 
.resume-text { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 14px; line-height: 18px; margin-bottom: 12px; white-space: pre-line;} 

.resume-box-7 { max-width: 595px; width: 100%; height: 842px; background-color: #FFFFFF; padding: 24px;}
.resume-box-7-left { width: 100%; }
.resume-box-7-right { background-color: #F9F9F9; width: 100%; padding: 16px 20px; border-radius: 8px;}
.resume-7-box  { margin-bottom: 24px;}
.resume-box-7-right .title-7 { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 20px; line-height: 24px; margin-bottom: 4px;}
.resume-box-7-right .sub-title-7 { color: #0E6CC2; font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 14px; line-height: 20px; margin-bottom: 8px;}
.resume-box-7-right .text-7 { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 12px; line-height: 18px; margin-bottom: 0px;}
.resume-box-7-left .r-text-7 { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 10px; line-height: 10px; margin-bottom: 0px; padding-top: 14px; padding-bottom: 24px;}
.resume-box-7-left .left-7-title { color: #0E6CC2; font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 12px; line-height: 10px; margin-bottom: 4px; }
.resume-box-7-left .resume-email-text { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 8px; line-height: 14px; margin-bottom: 0px; }

.resume-7-bottom { background-color: #F9F9F9; border-radius: 8px; padding: 16px; margin-top: 20px;}
.resume-7-bottom .title-7 { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 14px; line-height: 18px; margin-bottom: 0px;}
.resume-7-bottom .title-77 { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 9px; line-height: 18px; margin-bottom: 0px;}
.resume-7-bottom .sub-title-7 { color: #0E6CC2; font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 14px; line-height: 20px; margin-bottom: 8px;}
.resume-7-bottom .text-7 { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 8px; line-height: 18px; margin-bottom: 0px;}

.resume-box-8 { max-width: 595px; width: 100%; height: 842px; background-color: #FFFFFF; padding: 32px;}
.resume-8-title { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 20px; line-height: 24px; color: #222222; margin-bottom: 4px;}
.resume-8-text { font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 10px; line-height: 16px; color: #222222; margin-bottom: 4px;}
.resume-email-text { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 8px; line-height: 14px; margin-bottom: 0px; text-decoration: underline;}
.resume-8-sub-text { color: #222222; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 8px; line-height: 14px; margin-bottom: 0px; padding-top: 22px; padding-bottom: 30px;}

.skill-title-8 { font-family: 'Poppins', sans-serif; font-size: 10px; font-weight: 600; line-height: 16px; color: #222222; margin: 0;}
.skill-name-8 { font-family: 'Poppins', sans-serif; font-size: 10px; font-weight: 400; line-height: 16px; color: #222222; margin: 0;}
.skill-date-8 { font-family: 'Poppins', sans-serif; font-size: 9px; font-weight: 400; line-height: 16px; color: #797979; margin: 0;}
.skill-desc-8 { font-family: 'Poppins', sans-serif; font-size: 8px; font-weight: 400; line-height: 16px; color: #222222; margin: 0;}


.temp-container { width: 100%; background-color: #f1f5f9; padding: 0 88px; padding-top: 71px;}
.resume-box-9 { max-width: 595px; width: 100%; padding: 24px; background-color: #FFF; height: 842px; position: relative;}
.resume-9-box { padding: 24px; background-color: #FBFBFB; display: flex; gap: 16px; align-items: start;}
.resume-text-9 { font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 10px; line-height: 15px; color: #969696; margin-bottom: 0;}
.resume-title-99 { font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 16px; line-height: 24px; color: #000000; margin: 0;}
.resume-title-9 { font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 12px; line-height: 24px; color: #000000; margin: 0;}
.skill-title-9 { color: #000000; font-size: 12px; font-family: 'Poppins', sans-serif; font-weight: 600; line-height: 18px; margin-bottom: 8px;}
.skill-title-9 span { color: #000000; font-size: 10px; font-family: 'Poppins', sans-serif; font-weight: 400; line-height: 16px;}
.skill-sub-text-9 { font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 8px; line-height: 16px; color: #131890; margin-bottom: 0;}
.skill-sub-text-9 span { font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 8px; line-height: 16px; color: #000000; margin-bottom: 0;}
.skill-desc-text { font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 8px; line-height: 14px; color: #000000; margin-bottom: 0;}

.resume-9-bottom { position: absolute; bottom: 0; background: linear-gradient(90deg, rgba(255,84,40,1) 35%, rgba(167,67,41,1) 75%); height: 32px; width: 100%; left: 0;}
.resume-9-bottom .resume-text { color: #FFFFFF; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 10px; text-align: center; padding: 9px 0; position: relative; white-space: pre-line;}
.resume-9-bottom .resume-text::before { position: absolute; content: ""; background-image: url('./img/qu.svg'); height: 11px; width: 15px; left: 94px; bottom: 8px;}
.resume-9-bottom .resume-text::after { position: absolute; content: ""; background-image: url('./img/qu.svg'); height: 11px; width: 15px; right: 94px; top: 8px;}

.link-inputs { border: 1px solid #808080; background-color: #FFFFFF; border-radius: 8px; padding: 10px; display: flex; gap: 8px; align-items: center; margin-bottom: 8px;}
.link-inputs input { background-color: transparent; font-family: 'Inter', sans-serif; font-weight: 400; font-size: 16px; line-height: 24px; color: #808080; outline: none; border: none;} 