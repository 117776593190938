.form-header { position: fixed; width: 100%; top: 0; z-index: 100; height: 84px; display: flex; align-items: center; padding: 0px 10px; justify-content: space-between;  }
.color-header { position: fixed; width: 100%; top: 0; background-color: rgb(23, 39, 66); z-index: 100; height: 84px; display: flex; align-items: center; padding: 0px 10px; justify-content: space-between;  }
.home-header { position: fixed; width: 100%; top: 0; z-index: 100; height: 84px; display: flex; align-items: center; padding: 0px 10px; justify-content: space-between;  }
header .bras-btn { font-size: 32px; border: 0; padding-right: 20px; background-color: transparent; }
/* header .list { display: block; position: fixed; width: 200px; right: 0; bottom: 0; top: 0; background-color: #172742; z-index: 1000; transition: ease 0.5s; } */
header .list .login-btn { color:  #475569; padding: 6px 30px; background-color: #F1F5F9; border: none; border-radius: 50px; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-right: 0px; transition: ease-in 0.3s;}
header .list .login-btn:hover { color: #11274B; background: #E2E8F0;}

/* header .list a::after {content: ''; position: absolute; background-color: #FE9C3A; transform: scaleX(0); height: 2px; width: 100%; bottom: 0; left: 0; transition: ease 0.4s; will-change: transform; transform-origin: right; transition: transform 0.5s cubic-bezier(0.2, 0.6, 0.35, 1);} 
header .list a:hover::after { transform: scaleX(1); transform-origin: left; } */
header .bras-btn { display: none; color: #FFF; }
.mobile-logo { display: block;}
.logo {display: none;}
header .overlay { background-color: rgba(0, 0, 0, 0.66); position: fixed; top: 0; left: 0; bottom: 0; z-index: 990; transition: ease 0.5s;}
.search-input { width: 100%; border-radius: 8px; background-color: #475569; border: none; display: flex; align-items: center; padding: 8px 16px; justify-content: space-between;}
.search-input input { border: none; background-color: transparent; padding: 0; outline: none; color: #FFF;}
.header-search-btn { background-color: transparent; border: none; margin-right: 20px; padding: 0;}
.header-search-btn-job { background-color: transparent; border: none; margin-right: 20px; padding: 0; display: none;}
.upgrade-btn { display: none;}
.upgrade-btn-img { display: inline-block;}
.menu-dropdown { display: inline-block; border: none; border-radius: 1000px; background: rgba(241, 245, 249, 0.10); color: #E2E8F0; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px;  padding: 8px 16px; transition: all 0.5s;}

.menu { display: inline-block; border-radius: 8px; background-color: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); top: 66px; right: 30px; position: absolute; width: 300px; margin: 0; list-style: none; padding: 4px;}
.menu .menu-item { padding: 9px 16px;}
.menu .menu-item a { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; text-decoration: none;}
.menu .menu-upgrade-btn { color: #FFC000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 2px; background-color: #172742; width: 100%; border: none; padding: 9px 16px;}

.notification-count-2 { position: relative;    top: -19px;    right: 36px;    background-color: #ffc000;    color: white;    border-radius: 50%;    padding: 1px 6px;    font-size: 11px;    font-weight: bold;}
@media (max-width:480px){
  .header-search-btn-job { background-color: transparent; border: none; margin-right: 20px; padding: 0; display: flex;}
  .notification-count-2 { margin-right: -27px;}
}
@media (min-width: 1024px) {
  .mobile-logo { display: none !important;}
  .logo { display: block;}
  .form-header { padding: 0 60px; height: 80px; display: flex; justify-content: space-between; align-items: center; }
  .home-header { padding: 0 60px; height: 80px; display: flex; justify-content: space-between; align-items: center; }
  .color-header { padding: 0 60px; height: 80px; display: flex; justify-content: space-between; align-items: center; }
  header .bras-btn { display: none;  margin-right: 16px;}
  header .list { position: static; width: unset; background-color: transparent; border-radius: 60px; margin-left: 0px; margin-right: 0px; }
  .header-search-btn { display: none;}
  .upgrade-btn { display: inline-block; background-color: transparent; border: none; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-right: 30px;}
  .upgrade-btn-img { display: inline-block;}
  header .list .login-btn { width: 128px; height: 36px; padding: 6px 12px; font-size: 14px; line-height: 20px; }
  /* .menu {display: none;} */
}

@media (min-width: 1474px) {
  header {
    padding: 16px 85px;
    height: 80px;
  }
  header .list a {
    display: inline-block;
  }
  header .list {
    position: static;
    width: unset;
    background-color: transparent;
    border-radius: 60px;
  }
}


.form-header { position: fixed; width: 100%; top: 0; background-color: #172742; z-index: 1000; height: 84px; display: flex; align-items: center; padding: 0px 16px; justify-content: space-between;  }
.form-header .bras-btn { font-size: 32px; border: 0; padding-right: 20px; background-color: transparent; }
.form-header .list .login-btn { color:  #475569; padding: 6px 30px; border: none; border-radius: 50px; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-right: 0px;}
.build-btn { display: flex;}
.build-btn button{ background-color: transparent; border: none; padding: 0; color: #E2E8F0; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-left: 6px;}

@media (min-width: 1024px) {
  /* .menu-dropdown { display: none;} */
  .mobile-logo { display: none !important;}
  .logo { display: block;}
  .form-header { padding: 0 60px; height: 80px; display: flex; justify-content: space-between; align-items: center; }
  .form-header .bras-btn { display: none;  margin-right: 16px;}
  .form-header .list { position: static; width: unset; background-color: transparent; border-radius: 60px; margin-left: 0px; margin-right: 0px; }
  .header-search-btn { display: none;}
  .upgrade-btn { display: inline-block; background-color: transparent; border: none; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-right: 30px;}
  .upgrade-btn-img { display: inline-block;}
  /* .menu {display: none;} */
}

@media (min-width: 1474px) {
  .form-header {
    padding: 16px 230px;
    height: 80px;
  }
  .form-header .list a {
    display: inline-block;
  }
  .form-header .list {
    position: static;
    width: unset;
    background-color: transparent;
    border-radius: 60px;
  }
}

.search-container { position: relative; width: 100%;}
.search-items-box { position: absolute; top: 75px; border-radius: 8px; background: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); display: flex; width: 100%; padding: 4px 4px 12px 4px; flex-direction: column; align-items: center; gap: 2px; left: 0; height: 260px; overflow-y: scroll;}
.search-items-box ul { margin: 0; padding: 0; width: 100%;}
.search-d-item { list-style: none; text-align: left; height: 36px; padding: 9px 20px 9px 16px; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.search-border { border-bottom: 1px solid #CBD5E1; width: 92%; margin-bottom: 8px;}

.apply-filter { border-radius: 12px; background: #FFF; width: 92%; border: none; display: flex; justify-content: space-between; padding: 16px; margin: 0 16px; color: #475569; font-family: 'Inter'; font-size: 12px; font-style: normal; font-weight: 600; line-height: 12px; align-items: center;}
