/* Table Page css Start */

.table-select-btn { border-radius: 4px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; display: flex; width: 100%; padding: 4px 5px; align-items: center; gap: 10px; border: none;}
.table-container { display: flex; padding: 16px 20px 32px 20px; flex-direction: column; align-items: flex-start; gap: 24px; border-radius: 24px 0px 0px 24px; background-color: #FFF; }
.table-overflow {width: 100%; overflow-x: scroll; height:auto; position: relative; height: 500px;}
.table-overflow::-webkit-scrollbar { height: 4px; width: 7px;}
.table-overflow::-webkit-scrollbar-track { background: transparent; }
.table-overflow::-webkit-scrollbar-thumb { background: #94A3B8; border-radius: 8px;}
.table-container .table-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px;}
.table-container .download-btn { border-radius: 8px; background-color: #FFCA27; border: none; padding: 6px 8px 6px 12px; width: 160px; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; display: flex; align-items: center; justify-content: center;}
.table-body { width: 1550px;}
.table-body thead tr th { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #E2E8F0; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 130%; position: sticky;top: 0;z-index: 1;}
.table-body tbody tr .first-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .second-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; text-decoration-line: underline; transition: all 0.5s;}
.table-body tbody tr .second-box:hover { color: #0D9488;}
.table-body tbody tr .third-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .four-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .screen-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .five-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .six-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .seven-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .seven-box button { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; border-radius: 4px; background: #F1F5F9; border: none; padding: 3px 4px;}
.table-body tbody tr .eight-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .nine-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; }
.table-body tbody tr .ten-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; width: 180px;}
.interviewing-btn { border-radius: 4px; background: #F59E0B; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}
.decision-btn { border-radius: 4px; background: #DBD9D9; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}
.hire-btn { border-radius: 4px; background: #2DD4BF; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}
.not-hire-btn { border-radius: 4px; background: #FB7185; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}

.table-box { border-radius: 12px; background: #FFF; padding: 16px;}
.table-box .table-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.table-card { padding: 12px; border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF;}
.table-card .table-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 4px;}
.table-card .table-card-email { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 4px;}
.table-card .table-card-date { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 4px; text-align: right;}
.table-card .table-card-date-text { color: #000; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; text-decoration-line: underline; text-align: right;}
.table-card .table-card-border { border: 1px solid #F1F5F9; width: 100%; margin: 8px 0;}
.table-card .table-card-summary { color: #262626; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; }
.table-card .table-card-summary-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px;}
.table-card .table-card-status { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; }
.table-card .view-profile-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-top: 8px; border-radius: 8px; background: #F1F5F9; transition: all 0.5s; border: none; padding: 6px 12px; width: 100%;}
.table-card .view-profile-btn:hover {color: #11274B; background: #E2E8F0;}


/* Table Page css End */







.recruiter-container { background-color: #F1F5F9; padding: 24px 120px 53px 120px; overflow: hidden; }
.new-job-container { border-radius: 24px; background: #FFF; padding: 24px 20px; display: flex; flex-direction: column; gap: 16px;}
.new-job-container .new-job-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0;}
.custom-accordion { padding: 20px 24px; border-radius: 24px; border: 1px solid #CBD5E1; background: #FFF; display: flex; gap: 12px; flex-direction: column;}
.custom-accordion .acc-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.custom-accordion .acc-text { color: #000; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.acc-box { transition: all 0.5s; }
.acc-box .acc-input { display: flex; flex-direction: column; margin-bottom: 12px; transition: all 0.5s;}
.acc-input label { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 4px;}
.acc-input input { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background: #FFF; outline: none;}
.acc-input .acc-active { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #FB7185; background: #FFF; outline: none;}
.new-job-add-btn { border: none; background-color: transparent; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 0; display: flex; align-items: center;}
.clear-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background: #F1F5F9; border: 0; padding: 16px; width: 280px; transition: all 0.5s; margin-right: 24px;}
.clear-btn:hover { color: #11274B; background-color: #E2E8F0;}
.preview-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background: #FFCA27; border: 0; padding: 16px; width: 280px; transition: all 0.5s;}
.preview-btn:hover { color: #64748B; background-color: #FFC000;}
.draft-container { border-radius: 24px; background: #FFF; padding: 24px 20px; display: flex; flex-direction: column; gap: 16px; margin-top: 16px; position: relative; z-index: 1;}
.draft-container .new-job-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin: 0;}
.show-password-btn { position: absolute; right: 0; border: none; background-color: transparent;}
.acc-input textarea { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background: #FFF; outline: none;} 
.acc-input textarea::-webkit-scrollbar { display: none; }
.acc-input textarea::-webkit-scrollbar-track { background: transparent; }
.acc-input textarea::-webkit-scrollbar-thumb { background: #94A3B8;;}

.draft-boxes { display: flex; flex-direction: row; gap: 16px; flex-wrap: wrap;}
.draft-card { display: flex; flex-direction: column; gap: 12px; border-radius: 12px; border: 1px solid #F1F5F9; background: #FFF; width: 252px; padding: 16px;}
.draft-card .draft-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 2px;}
.draft-card .draft-card-text {color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.draft-card .draft-days { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}
.draft-card .draft-list { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; padding-left: 14px; margin: 0;}
.draft-card .draft-edit-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 100%; border-radius: 8px; background: #F1F5F9; padding: 6px 12px; transition: all 0.5s; text-align: center; border: none;}
.draft-card .draft-edit-btn:hover { color: #11274B; background-color: #E2E8F0;}
.draft-card .draft-sub-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.draft-card .draft-skills { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-right: 12px !important;}
.draft-card .draft-mange-btn { border-radius: 8px; color: #64748B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border: none; background-color: transparent; width: 100%; text-align: center; padding: 6px 12px; transition: all 0.5s;}
.draft-card .draft-mange-btn:hover { background-color: #F8FAFC; color: #94A3B8;}
.draft-view-all-box { display: flex; flex-direction: column; justify-content: center; border-radius: 12px; border: 1px solid #F1F5F9; background: #FFF; width: 252px; padding: 34px 64px;}
.draft-view-all-box img { height: 64px;}
.view-all-box-btn { border-radius: 8px; padding: 16px; color: #172742; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; background-color: transparent;}
.recruiter-detail { border-radius: 16px 24px 24px 16px; background: #FFF; padding: 16px;}

@media(max-width: 480px) {
    .recruiter-container { background-color: #F1F5F9; padding: 12px 20px; overflow: hidden;}
    .custom-accordion { padding: 16px; border-radius: 12px; border: 1px solid #CBD5E1; background: #FFF;}
    .acc-input input { font-size: 14px; line-height: 20px; padding: 8px 10px; }
    .acc-input textarea { font-size: 14px; line-height: 20px; padding: 8px 10px; }
    .draft-container { padding: 20px 0 20px 20px; }
    .table-select-btn {width: 36%;}

}


.summary-box { height: 100px; overflow-y: auto; overflow-x: hidden;}
.summary-box::-webkit-scrollbar { width: 3px; }
.summary-box::-webkit-scrollbar-track { background: transparent; }
.summary-box::-webkit-scrollbar-thumb { background: #94A3B8; border-radius: 8px;}