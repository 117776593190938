.mobile-show-hide { display: none;}
.desktop-show-hide { display: block;}

@media(max-width: 480px) { 
    .mobile-show-hide { display: block;}
    .desktop-show-hide { display: none;}
}
.haAclf { padding: 0 !important; width: 100% !important;}

.hero-section { background-image: url('./assets/hero-bg.svg'); width: 100%; height: 744px; object-fit: cover; position: relative; background-repeat: no-repeat; background-size: cover;} 
.mobile-home-hero { background-image: url('./assets/interview/Comingpage.svg'); width: 100%; height: 100vh; object-fit: cover; position: relative; background-repeat: no-repeat; background-size: cover;} 
.mobile-home-hero .hero-content  { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);} 
.hero-content .hero-title { color: #FFF; text-align: center; font-family: 'Inter', sans-serif; font-size: 48px; font-style: normal; font-weight: 600; line-height: 62px; padding-bottom: 18px; margin: 0;} 
.hero-content .hero-text { color: #F1F5F9; text-align: center; font-family: 'Inter', sans-serif; font-size: 24px; font-style: normal; font-weight: 600; line-height: 30px; margin: 0; padding-bottom: 94px;} 
.mobile-home-hero .hero-btn { display: flex; align-items: center; justify-content: center;}
.hero-content .hero-left-btn { border-radius: 8px; background: #F1F5F9; border: none; padding: 16px 0; width: 300px; color:#475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; margin-right: 24px; box-shadow: 0px 4px 30px 0px rgba(255, 255, 255, 0.20); transition: all 0.5s;} 
.hero-content .hero-left-btn:hover { color: #11274B; background: #E2E8F0} 
.hero-content .hero-right-btn { border-radius: 8px; background: #FFCA27; border: none; padding: 16px 0; width: 300px; color:#2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; margin-right: 24px; box-shadow: 0px 4px 40px 0px rgba(23, 39, 66, 0.40); transition: all 0.5s;} 
.hero-content .hero-right-btn:hover { color: #64748B; background: #FFC000;} 
.mobile-home-hero .learn-more { position: absolute; bottom: 67px; left: 50%; transform: translate(-50%, 0); color: #CBD5E1; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding-top: 50px;} 

.coming-soon { background-image: url('./assets/interview/Comingpage.svg'); width: 100%; height: 100vh; object-fit: cover; position: relative; background-repeat: no-repeat; background-size: cover;} 
.coming-soon .coming-content  { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);} 
.coming-soon .coming-title { color:  #FFF; font-family: 'Inter', sans-serif; font-size: 48px; font-style: normal; font-weight: 600; line-height: 62px; } 
.coming-soon .coming-text { color: #F1F5F9; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 400; line-height: 22px; margin: 0; max-width: 886px; width: 100%;} 
.coming-soon .coming-text-bottom { color: #F1F5F9; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 400; line-height: 22px; margin: 0; max-width: 886px; width: 100%; margin-top: 83px;} 
.coming-soon .coming-span { color: #FFC000; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 400; line-height: 22px; } 
.coming-soon .coming-btn { width: 300px; height: 56px; padding: 16px; border-radius: 8px; background: #FFCA27; box-shadow: 0px 4px 40px 0px rgba(23, 39, 66, 0.40); border: none; transition: all 0.5s; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; margin-top: 68px;} 

@media(max-width: 480px) {
    .coming-soon { background-image: url('./assets/interview/ComingSoonPage.svg'); width: 100%; height: 100vh; object-fit: cover; position: relative; background-repeat: no-repeat; background-size: cover;} 
    .coming-soon .coming-title { font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; margin-bottom: 24px;} 
    .coming-soon .coming-text { font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; margin: 0; width: 295px;} 
    .coming-soon .coming-text-bottom { font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; margin: 0; width: 295px; margin-top: 24px;} 
    .coming-soon .coming-span { font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px;} 
    .coming-soon .coming-btn { width: 300px; height: 44px; padding: 10px 16px; border-radius: 8px; background: #FFCA27; box-shadow: 0px 4px 40px 0px rgba(23, 39, 66, 0.40); border: none; transition: all 0.5s; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; margin-top: 120px;} 
    .mobile-home-hero { background-image: url('./assets/mobile-bg-hero.svg'); width: 100%; height: 716px; object-fit: cover; position: relative; background-repeat: no-repeat; background-size: cover;} 
    .mobile-home-hero .hero-content  { position: absolute; top: 39%; left: 50%; transform: translate(-50%, -50%);} 
    .mobile-home-hero .hero-btn { display: flex; align-items: center; flex-direction: column; justify-content: center; flex-direction: column-reverse; gap: 24px;}
    .hero-content .hero-title { font-size: 24px; line-height: 30px; padding-bottom: 16px;} 
    .hero-content .hero-text { font-size: 12px; line-height: 16px; padding-bottom: 84px; font-weight: 400;} 
    .hero-content .hero-left-btn { border-radius: 8px; background: #F1F5F9; border: none; padding: 10px 0; width: 300px; color:#475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; margin-bottom: 0; margin-right: 0;} 
    .hero-content .hero-right-btn { border-radius: 8px; background: #FFCA27; border: none; padding: 10px 0; width: 300px; color:#2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; margin-right: 24px; margin-right: 0;} 
    .mobile-home-hero .learn-more { position: absolute; bottom: 107px; left: 50%; transform: translate(-50%, 0); color: #CBD5E1; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding-top: 50px; width: 100%; display: flex; justify-content: center; align-items: center; gap: 14px;} 
}

.about-page { padding-top: 80px; background: #172742;}
.about-page .about-hero { max-width: 1280px; margin: auto; padding: 106px 124px; display: flex; flex-direction: row; gap: 27px; align-items: center;}
.about-hero .about-page-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; margin-bottom: 27px;}
.about-hero .about-page-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; }


@media(max-width: 480px) {
    .about-page { padding-top: 84px;}
    .about-hero .hero-img { width: 327px; height: 243px; object-fit: cover;}
    .about-page .about-hero { padding: 28px 24px 24px 24px; display: flex; flex-direction: column; gap: 12px; align-items: center;}
    .about-hero .about-page-title { color: #FFF; font-size: 16px; line-height: 20px; margin-bottom: 16px;}
    .about-hero .about-page-text { font-size: 10px; line-height: 16px; }
}

.mission-section { background: #EEF7FD; padding: 80px 0;}
.mission-section .mission-container { display: flex; margin: auto; max-width: 880px; flex-direction: column; align-items: center; gap: 62px;}
.mission-container .mission-card { display: flex; align-items: center; gap: 45px;}
.mission-card .mission-card-content { display: flex; max-width: 598px; padding: 32px 0px; flex-direction: column; align-items: flex-start; gap: 27px;}
.mission-card-content .mission-card-title { color: #1F2E46; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; margin: 0;}
.mission-card-content .mission-card-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; margin: 0;}

@media(max-width: 480px){
    .mission-section { padding: 32px 16px;}
    .mission-section .mission-container { gap: 24px;}
    .mission-container .mission-card { gap: 12px; align-items: flex-start;}
    .mission-container .mission-card img { height: 60px;}
    .mission-card .mission-card-content { padding: 0; gap: 8px;}
    .mission-card-content .mission-card-title { color: #262626; font-size: 16px; line-height: 20px; }
    .mission-card-content .mission-card-text { color: #262626; font-size: 14px; line-height: 18px; }
}


.recruiter-section { padding: 70px 83px 50px 146px; display: flex; flex-direction: column; align-items: flex-start; gap: 80px;}
.recruiter-about-container { width: 1051px; margin: auto; display: flex; justify-content: space-between; align-items: center; align-self: stretch;}
.recruiter-about-container .recruiter-title { padding: 32px 0;color: #1F2E46; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; margin: 0;}
.job-seeker-card-box { display: flex; flex-direction: column; gap: 22px;}
.job-seeker-card-container { display: flex; gap: 22px; flex-direction: row;}
.job-seeker-card { display: flex; padding: 24px 16px; flex-direction: column; align-items: flex-start; gap: 12px; border-radius: 24px; background: #FFF; box-shadow: 1px 2px 8px 0px rgba(23, 39, 66, 0.14); height: 208px; width: 296px;}
.job-seeker-card .job-card-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 8px;}
.job-seeker-card .job-detail { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0; padding-left: 16px;}
.job-seeker-card .bg-blue { padding: 6px; border-radius: 50%; background-color: #11274B; }
.job-seeker-card .bg-normal { padding: 6px; border-radius: 50%; background-color: #CBD5E1;}
.job-seeker-card .bg-yellow { padding: 6px; border-radius: 50%; background-color: #FFCA27;}
.job-card-box { display: flex; flex-direction: column; gap: 22px;}
.job-card-box .job { display: flex; flex-direction: row; gap: 22px;}

@media(max-width: 480px) {
    .recruiter-section { padding: 24px 0; gap: 48px;}
    .recruiter-about-container { flex-direction: column; width: auto; align-items: center;}
    .recruiter-about-container .recruiter-title { padding: 0 0 16px 0; font-size: 24px; font-style: normal; font-weight: 600; line-height: 30px;}
    .job-seeker-card { padding: 16px 10px; gap: 8px; border-radius: 12px; height: 200px; width: 170px;}
    .job-seeker-card .job-card-title { font-size: 10px; line-height: 16px; margin-bottom: 8px;}
    .job-seeker-card .job-detail { font-size: 10px; line-height: 16px; padding-left: 16px;}
    .job-card-box { gap: 8px;}
    .job-card-box .job { gap: 8px;}
}

.our-about-section { background: #F8FAFC; display: flex; padding: 50px 99px; flex-direction: column; align-items: center; gap: 32px;}
.our-about-section .our-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; margin: 0; margin-bottom: 12px; text-align: center;}
.our-about-section .our-about-box { display: flex; justify-content: center; align-items: flex-start; gap: 16px;}
.hiring-box { display: flex; padding: 16px; flex-direction: column; align-items: center; gap: 16px; border-radius: 16px; border: 1px solid #DBD9D9; width: 464px; height: 290px;}
.hiring-box .hiring-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; text-transform: uppercase; margin: 0; }
.know-box { display: flex; padding: 16px; flex-direction: column; align-items: center; gap: 16px; border-radius: 16px; border: 1px solid #DBD9D9; width: 310px; height: 290px;}
.know-box .know-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; text-transform: uppercase; margin: 0; }
.tech-box { display: flex; padding: 16px; flex-direction: column; align-items: center; gap: 16px; border-radius: 16px; border: 1px solid #DBD9D9; width: 182px; height: 290px;}
.tech-box .tech-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; text-transform: uppercase; margin: 0; }
.our-mobile-logos { width: auto; height: auto; object-fit: cover; border-radius: 2.611px; border: 0.653px solid  #E2E8F0; background: #FFF;}


@media(max-width: 480px) {
    .our-about-section { padding: 24px 0 24px 16px; display: block;}
    .our-about-section .our-title { font-size: 20px; line-height: 26px; margin-bottom: 12px; text-align: left;}
    .hiring-box { padding: 8px; width: 234px; height: 268px;}
    .know-box { padding: 8px; width: 234PX; height: 268px;}
    .tech-box { padding: 8px; width: 120px; height: 268px;}
    .our-mobile-logos { width: 104px; height: 68px; object-fit: cover; border-radius: 2.611px; border: 0.653px solid  #E2E8F0; background: #FFF;}
}

.talent-section { background-color: #FFFFFF; overflow: hidden; padding: 0 54px;}
.talent-section .talent-title { color: #1F2E46; text-align: center; font-family: 'Inter', sans-serif; font-size: 32px; font-style: normal; font-weight: 600; line-height: 62px; margin: 0; padding: 53px 0 30px 0;}
.talent-section .talent-box { max-width: 1309px; margin: auto;}
.talent-section .talent-container {  background-image: url('./assets/talent/talent-bg.svg'); width: 100%; background-repeat: no-repeat; object-fit: cover; margin: auto; background-size: contain; background-position: center; }
.talent-section .talent-box .jov-box { padding-bottom: 120px; max-width: 1017px; margin: auto; }

.talent-section .talent-box .talent-card { border-radius: 24px; background: #FFF; box-shadow: 1px 1px 6px 0px rgba(23, 39, 66, 0.06); padding: 24px 16px; margin-left: 22px; margin-bottom: 22px; height: 186px; width: 296px; }
.talent-section .talent-box .talent-card .bg-yellow { padding: 6px; border-radius: 50%; background-color: #FFCA27; margin-bottom: 12px;}
.talent-section .talent-box .talent-card .bg-normal { padding: 6px; border-radius: 50%; background-color: #FFCA27; margin-bottom: 12px;}
.talent-section .talent-box .talent-card .bg-blue { padding: 6px; border-radius: 50%; background-color: #11274B; margin-bottom: 12px;}
.talent-section .talent-box .talent-card .job-card-title { color:#262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px;}
.talent-section .talent-box .talent-card .job-detail { margin: 0; padding: 0 0 0 18px;}
.talent-section .talent-box .talent-card .job-detail li { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;}

.talent-section .talent-box .recruiter-box { padding-bottom: 20px; max-width: 1017px; margin: auto; }

.talent-section .talent-box .recruiter-img { padding-top: 0px; padding-left: 60px;}

@media(max-width: 480px) {
    .talent-section { background-color: #FFFFFF; overflow: hidden; padding: 0 14px;}
    .talent-section .talent-box { background-image: none; height: unset; width: unset; background-repeat: no-repeat; object-fit: cover;}
    .talent-section .talent-box .talent-card { padding: 10px 10px; margin-left: 0px; margin-bottom: 12px !important; height: 200px; width: 170px; margin: auto; box-shadow: 1px 2px 8px 0px #17274224;    }
    .talent-section .talent-box .talent-card .bg-yellow { padding: 6px; border-radius: 50%; background-color: #FFCA27; margin-bottom: 8px;}
    .talent-section .talent-box .talent-card .job-card-title { font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 8px;}
    .talent-section .talent-box .talent-card .job-detail li { font-size: 10px; font-weight: 400; line-height: 16px;}
    .talent-section .talent-title {  font-size: 24px; line-height: 30px; font-weight: 600; padding: 24px 0 24px 0;}
    .talent-section .talent-box .jov-box {padding-bottom: 0px; }
    /* .talent-section .talent-box .job-img {padding: 75px 0 30px 50px;} */
    .talent-section .talent-box .recruiter-img { float: unset; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0;}
    .talent-section .talent-box .mobile-bg { position: relative; width: 230px; height: 230px; background-repeat: no-repeat; background-size: cover; object-fit: cover; margin: auto; margin-bottom: 16px;}
    .talent-section .talent-box .job-img { position: relative; padding: 0; }
    .talent-section .talent-box .recruiter-box { padding-bottom: 0px; }
}
@media(max-width: 380px) {
    .talent-section .talent-box .talent-card { width: 160px; height: 220px;}
}
@media(max-width: 360px) {
    .talent-section .talent-box .talent-card { width: 100%; height: 160px;}
}


.count-section { background-color: #F1F8FE; margin-top: 50px; overflow: hidden;}
.count-section .count-box { max-width: 1020px; margin: auto; padding: 50px 0;}
.count-section .count-box .count-number { color: #262626; font-family: 'Inter', sans-serif; font-size: 48px; font-style: normal; font-weight: 600; line-height: 62px; }
.count-section .count-box .count-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; }

.our-section { background-color: #F8FAFC; padding: 80px 0;}
.our-section .our-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; text-align: center; font-weight: 600; line-height: 36px; margin-bottom: 32px;}
.our-section .our-box { max-width: 1080px; margin: auto;}

@media(max-width: 480px) {
    .our-section { background-color: #F8FAFC;  padding: 32px 0;}
    .count-section { margin-top: 20px;}
    .our-section .our-title { font-size: 20px; font-style: normal; text-align: center; font-weight: 600; line-height: 26px; margin-bottom: 16px;}
    .count-section .count-box { margin: auto; padding: 32px 0;}
    .count-section .count-box .count-number {  text-align: center; font-size: 24px; font-weight: 600; line-height: 30px; }
    .count-section .count-box .count-text { font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; padding-top: 8px;}
}



.courses-section { overflow: hidden; padding-left: 99px; padding-top: 60px;}
.courses-section .courses-title { color: #000; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px;}
.courses-section .courses-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; padding-bottom: 24px;}
.courses-section .courses-sub-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; padding-bottom: 16px;}
.course-card { width: 248px; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px; height: 346px;}
.course-card .course-img { height: 89px; border-radius: 8px; border: 1px solid #F1F5F9; background: lightgray 0px 0px / 100% 98.876% no-repeat; width: 100%; object-fit: cover; background-size: cover;}
.community-card { width: 100%; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px; height: 350px;}
.community-card .course-img { height: 89px; border-radius: 8px; border: 1px solid #F1F5F9; background: lightgray 0px 0px / 100% 98.876% no-repeat; width: 100%; object-fit: cover; background-size: cover;}
.course-btn { color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding: 0px 4px; border-radius: 4px; background: #64748B; border: none; margin-bottom: 2px;}
.course-content-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 2px; margin-top: 6px;}
.course-content-text {color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.course-border { margin: 6px 0; border-bottom: 1px solid #F1F5F9;}
.course-fee { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 2px;}
.course-list { list-style-type: none; margin: 0; padding-left: 0; color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.view-more-btn { display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; border: none; background: transparent; margin-top: 12px; display: flex; border-radius: 8px; background: #F1F5F9; width: 100%; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }
.courses-section .information-slider { margin-top: 32px;}
.courses-section .view-more { display: none; border-radius: 8px; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; margin-top: 32px; padding: 6px 12px; transition: all 0.5s; margin-right: 30px;}
.courses-section .view-more:hover { color: #11274B;}
.celebration-section { padding-top: 90px; padding-bottom: 80px;}
.celebration-section .celebration-title { color: #000; font-family: 'Inter', sans-serif; text-align: center; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px;}
.celebration-section .celebration-text { color: #4D4D4D; font-family: 'Inter', sans-serif; text-align: center; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px;}
.celebration-section .celebration-box { max-width: 970px; margin: auto; margin-top: 32px;}

@media(max-width: 768px) {
    .courses-section { overflow: hidden; padding-left: 40px; padding-bottom: 16px;}
}

@media(max-width: 480px) {
    .courses-section { overflow: hidden; padding-left: 14px; padding-top: 20px;}
    .courses-section .courses-title { color: #000; font-family: 'Inter', sans-serif; font-size: 24px; font-style: normal; font-weight: 600; line-height: 30px; margin-bottom: 12px;}
    .courses-section .course-card { width: 252px; height: 346px;}
    .courses-section .courses-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; padding-bottom: 32px;}
    .courses-section .courses-sub-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; padding-bottom: 12px;}
    .courses-section .information-slider { margin-top: 24px;}
    .courses-section .view-more { display: block; position: relative; left: 50%; transform: translateX(-50%); border-radius: 8px; background-color: transparent; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; margin-top: 32px !important; padding: 6px 12px; margin-right: 0;}
    .mobile-course-body { width: 70%;}
    .celebration-section { padding-top: 46px; padding-bottom: 46px;}
    .celebration-section .celebration-title { font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px;}
    .celebration-section .celebration-text { font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;}
}
.slick-list {
    padding:0 20% 0 0 !important;
}


.stories-section { background-color: rgba(241, 245, 249, 0.60); padding: 40px 0 40px 99px; position: relative; z-index: 10;}
.stories-section .slick-slider { position: relative; z-index: 15}
.stories-section .stories-title { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; padding-bottom: 24px;}
.stories-section .stories-bg1 { background-color: #FFF1F1;  padding: 20px 24px 48px 16px; height: 202px; position: relative; border-radius: 12px; margin-right: 24px; width: 372px;}
.stories-section .stories-bg2 { background-color: #FFF9E4;  padding: 20px 24px 48px 16px; height: 202px; position: relative; border-radius: 12px; margin-right: 24px; width: 372px;}
.stories-section .stories-bg3 { background-color: #C7D2FE;  padding: 20px 24px 48px 16px; height: 202px; position: relative; border-radius: 12px; margin-right: 24px; width: 372px;}
.stories-section .stories-card::after { position: absolute; content: ""; background-image: url('./assets/stories/wrapper.svg'); height: 53px; width: 47px; background-repeat: no-repeat; top: 45%; transform: translate(0, -50%); right: 12px;}
.stories-section .stories-card .story-name { color: #000; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; padding-left: 18px;}
.stories-section .stories-card .story-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.stories-section .stories-card .story-job-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; padding-top: 22px; }


@media(max-width: 768px) {
    .stories-section { padding: 40px 0 40px 40px;}
    .stories-section .stories-bg1 {width: 340px;}
    .stories-section .stories-bg2 {width: 340px;}
    .stories-section .stories-bg3 {width: 340px;}
}
@media(max-width: 480px) {
    .stories-section { background-color: rgba(241, 245, 249, 0.60); padding: 32px 0 32px 16px ; position: relative; z-index: 10;}
    .stories-section .stories-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; padding-bottom: 16px; margin-bottom: 0;}
    .stories-section .stories-bg1 { background-color: #FFF1F1;  padding: 20px 24px 48px 16px; position: relative; border-radius: 12px; margin-right: 24px; width: 220px; height: 202px;}
    .stories-section .stories-bg2 { background-color: #FFF9E4;  padding: 20px 24px 48px 16px; position: relative; border-radius: 12px; margin-right: 24px; width: 220px; height: 202px;}
    .stories-section .stories-bg3 { background-color: #C7D2FE;  padding: 20px 24px 48px 16px; position: relative; border-radius: 12px; margin-right: 24px; width: 220px; height: 202px;}
    .stories-section .stories-card .story-job-text { padding-top: 12px; }
    .stories-section .stories-card .story-name { font-size: 14px; line-height: 18px; padding-left: 8px;}
    .stories-section .stories-card .story-text { font-size: 10px; line-height: 16px;}
    .stories-section .stories-card::after { position: absolute; content: ""; background-image: url('./assets/stories/wrapper.svg'); height: 53px; width: 47px; background-repeat: no-repeat; top: 40%; transform: translate(0, -50%); right: 8px;}
}
/* @media(min-width: 1400px) { */
    /* .talent-section .talent-box .job-img {padding: 75px 0 0 0; position: relative; left: -40px;} */
    /* .talent-section .talent-box .recruiter-img { padding-top: 50px; position: relative; right: 40px;} */
    /* } */
    

/* set job page  */


.job-section { padding-top: 128px; background-image: url('./assets/login/login-hero.svg'); min-height: 100vh; height: 100%; background-attachment: fixed; background-repeat: repeat-y; width: 100%; background-size: cover; object-fit: cover; background-repeat: no-repeat;}
.set-job-box { max-width: 410px; margin: auto; border-radius: 12px; background-color: rgba(255, 255, 255, 0.75); padding: 24px; opacity: 0.95;}
.set-job-box .set-job-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; text-align: center;}
.set-job-box .set-job-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;  margin-bottom: 12px;}
.set-job-box .job-label { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; margin-bottom: 4px;}
.set-job-box .job-input { border-radius: 8px; border: 1px solid #808080; background-color: #FFF; padding: 10px 12px; color:#808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; margin-bottom: 12px; width: 100%; position: relative; transition: all 0.5s;}
.set-job-box .job-input:focus { outline: 2px solid #94A3B8;}
.set-job-box .job-input:hover { color: #94A3B8; border: 1px solid #94A3B8;}
.set-job-box .job-input::after { position: absolute; content: ""; background-image: url('./assets/select-dropdown.svg'); height: 20px; width: 20px;}
.set-job-box .job-option { border-radius: 8px; border: 2px solid #94A3B8; background-color: #FFF; padding: 10px 12px; color:#808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; margin-bottom: 12px; width: 100%; outline: none;}
.set-job-box .set-job-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; padding: 16px 0; border-radius: 8px; background-color: #FFCA27; width: 100%; border: none; transition: all 0.5s;}
.set-job-box .set-job-btn:hover { color: #64748B; background-color: #FFC000;} 
.set-job-box .form-border { border-bottom: 1px solid #E2E8F0; margin: 0 0 12px 0;}
.set-job-box .select-option { border-radius: 16px; display: inline-block; background-color: #F1F5F9; padding: 4px 8px; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-right: 4px; margin-bottom: 12px;}
.set-job-box .select-option img { padding-left: 6px; opacity: 0.6; }
.select-option-box { border-radius: 16px; display: inline-block; background-color: #F1F5F9; padding: 4px 8px; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-right: 4px; margin-bottom: 12px;}

@media(max-width: 480px) {
    .select-option-box { margin-right: 4px; margin-bottom: 4px;}
    .job-section { padding-top: 103px; }
    .set-job-box { max-width: 410px; padding: 16px; margin: 0 20px;}
    .set-job-box .set-job-title { font-size: 16px; font-weight: 600; line-height: 20px; margin-bottom: 8px;}
    .set-job-box .job-label { font-size: 10px; line-height: 12px; }
    .set-job-box .job-input { color: #808080; font-family: Inter; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
    .set-job-box .set-job-btn { font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 10px 0; border-radius: 8px; background-color: #FFCA27; width: 100%; border: none;}
    .set-job-box .set-job-text { margin-bottom: 12px;}
}
.modal-content { background-color: transparent !important; border: none !important;}
.job-modal { border-radius: 12px; background-color: rgba(17, 39, 75, 0.95); padding: 24px 32px; max-width: 405px; margin: auto;}
.job-modal .job-modal-title { color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 24px;}
.job-modal .create-resume-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFCA27; padding: 16px 0; width: 300px; border: none; margin-bottom: 16px; margin-left: 20px; transition: all 0.5s;}
.job-modal .create-resume-btn:hover { color: #64748B; background-color: #FFC000; }
.job-modal .upload-resume-btn { color: #475569; text-align: center; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFF; padding: 16px 0; width: 300px; border: none; margin-left: 20px; transition: all 0.5s;}
.job-modal .upload-resume-btn:hover { color: #11274B; background-color: #E2E8F0;}
.switch-modal { border-radius: 12px; background-color: rgba(17, 39, 75, 0.95); padding: 24px 32px; max-width: 405px; margin: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.switch-modal .switch-modal-title { color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 24px;}
.switch-modal .create-resume-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFCA27; padding: 16px 0; width: 300px; border: none; margin-bottom: 16px; margin-left: 20px; transition: all 0.5s;}
.switch-modal .create-resume-btn:hover { color: #64748B; background-color: #FFC000; }
.switch-modal .upload-resume-btn { color: #475569; text-align: center; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFF; padding: 16px 0; width: 300px; border: none; margin-left: 20px; transition: all 0.5s;}
.switch-modal .upload-resume-btn:hover { color: #11274B; background-color: #E2E8F0;}

@media(max-width: 480px) {
    .job-modal { padding: 48px 16px; max-width: 405px; margin: 0 12px;}
    .job-modal .job-modal-title { font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 32px; text-align: center;}
    .job-modal .create-resume-btn { font-size: 14px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFCA27; padding: 10px 0; width: 100%; border: none; margin-bottom: 16px; margin-left: 0;}
    .job-modal .upload-resume-btn { font-size: 14px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFF; padding: 10px 0; width: 100%; border: none; margin-left: 0;}
    .switch-modal { padding: 48px 16px; width: 335px; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    .switch-modal .switch-modal-title { font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 32px; text-align: center;}
    .switch-modal .create-resume-btn { font-size: 14px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFCA27; padding: 10px 0; width: 100%; border: none; margin-bottom: 16px; margin-left: 0;}
    .switch-modal .upload-resume-btn { font-size: 14px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background-color:  #FFF; padding: 10px 0; width: 100%; border: none; margin-left: 0;}
}

/* create resume Section CSS */
.create-resume { padding-top: 128px; background-image: url('./assets/login/login-hero.svg'); min-height: 100vh; height: 100%; width: 100%; background-size: cover; background-attachment: fixed; object-fit: cover; background-position: center center; background-repeat: repeat-y; padding-bottom: 100px; }
.profile-build { border-radius: 12px; background-color: rgba(255, 255, 255, 0.75); margin: 0 150px;}
.profile-line { position: relative; margin: 0 16px;}
.profile-line::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #FFF; }
.profile-line::after { position: absolute; top: 0; content: ""; width: 10%; height: 6px; background-color: #FFC000; }
.profile-build .build-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px;  text-align: center; padding-top: 16px;}
.profile-build .build-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 16px; margin: 0;}
.profile-build .auto-button { text-align: center; border-radius: 1000px; padding: 6px 12px; background-color: #F1F5F9; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; margin-bottom: 8px; height: 36px; width: 172px; text-align: center;}
.profile-build .send-resume-btn { position: relative;}
.profile-build .information { color: #D97706; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;  margin-bottom: 10px; }
.profile-build .file-name { color:#11274B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal; }
.profile-build .border-bottom { stroke-width: 1px; stroke:  #E2E8F0;}
.profile-build .resume-box { padding: 16px 24px 0 24px; }
.resume-box .resume-title { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 12px;}
.resume-box .resume-input { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 9px 12px; border-radius: 8px; border: 1px solid #808080; background-color:  #FFF; margin-bottom: 12px; width: 100%;  transition: all 0.5s;}
.resume-box .resume-input:hover { color: #94A3B8; border: 1px solid #94A3B8;}
.resume-box .resume-input:focus { outline: 2px solid #94A3B8; color: #262626;}
.resume-input { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color:  #FFF; margin-bottom: 12px; width: 100%; transition: all 0.5s;}
.resume-input:hover { color: #94A3B8; border: 1px solid #94A3B8;}
.resume-input:focus { outline: 2px solid #94A3B8;}
.resume-box .calender-date-input { display: flex; align-items: center; justify-content: space-between; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 8px 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color:  #FFF; margin-bottom: 12px; width: 100%; position: relative; cursor: pointer; transition: all 0.5s;}
.resume-box .calender-date-input:hover { color: #94A3B8; border: 1px solid #94A3B8; }
.resume-box .input-date { border: none; outline: none;}
.resume-box .calender-date-input:focus { border: 2px solid #94A3B8;}
.search-input-box { outline: none; border: none;}
.skip-for-now-btn { color: #64748B; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; padding: 10px 16px; margin-bottom: 24px; border: none; background-color: transparent;}

@media(max-width: 480px) {   
    .profile-build .resume-box { padding: 12px 16px 4px 16px; }
    .skip-for-now-btn { padding: 10px 16px; margin-bottom: 0px; padding-top: 0; display: none;}
}

.resume-box .custom-file-upload { display: flex; align-items: center; justify-content: space-between; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 8px 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color:  #FFF; margin-bottom: 12px; width: 100%; position: relative; cursor: pointer; transition: all 0.5s;}
.resume-box .custom-file-upload:hover { border: 1px solid #94A3B8; color: #94A3B8;}
.resume-box .custom-file-upload:focus { border: 2px solid #94A3B8;}
.resume-box .input-calender { position: relative; top: 0; right: 0;}
.resume-box .resume-input-date { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color:  #FFF; margin-bottom: 12px; width: 100%; position: relative;}
.resume-box .resume-input-date::after { position: absolute; content: ""; background-image: url('./assets/calender.svg'); height: 20px; width: 20px; right: 10px;}
.tooltip-container { position: relative;}
.tooltip-container .information-text { color: #D97706; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.tooltip-container .tooltip-box { border-radius: 8px; background: #172742; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; padding: 12px 16px; width: 207px; position: absolute; bottom: 36px; left: -24px;}
.tooltip-container .tooltip-box .tooltip-close { background-image: url('./assets/tootip-close-btn.svg'); height: 24px; width: 24px; position: absolute; content: ""; top: -27px; right: 0px; cursor: pointer;}
.tooltip-container .tooltip-box::after { background-image: url('./assets/tooltip-arrow.svg'); height: 7px; width: 14px; position: absolute; content: ""; bottom: -7px; left: 24px;}
.tooltip-container .mobile-tooltip-box { border-radius: 8px; background: #172742; color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding: 12px 8px; width: 303px; position: absolute; bottom: 36px; left: -7px;}
.tooltip-container .mobile-tooltip-box .tooltip-close { background-image: url('./assets/tootip-close-btn.svg'); height: 24px; width: 24px; position: absolute; content: ""; top: -27px; right: 0px; cursor: pointer;}
.tooltip-container .mobile-tooltip-box::after { background-image: url('./assets/tooltip-arrow.svg'); height: 7px; width: 14px; position: absolute; content: ""; bottom: -7px; left: 7px;}

.resume-box .plush-text { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px;  display: flex; align-items: center; transition: all 0.5s;}
.resume-box .plush-text:hover { color: #172742;}
.profile-build .back-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; padding: 16px 0px; border-radius: 8px; background-color: #F1F5F9; width: 128px; transition: all 0.5s;}
.profile-build .back-btn:hover { color: #11274B; background-color: #E2E8F0;}
.profile-build .skip-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; padding: 16px 0px; border-radius: 8px; background-color: #F1F5F9; width: 128px; transition: all 0.5s;}
.profile-build .skip-btn:hover { color: #11274B; background-color: #E2E8F0;}
.profile-build .save-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; padding: 16px; border: none; border-radius: 8px; background-color: #FFCA27; margin: 0 16px; width: 362px; transition: all 0.5s;}
.profile-build .save-btn:hover { background-color: #FFC000; color: #64748B; }
.profile-build .done-btn { color: #0D9488; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 1000px; background-color:  #F1F5F9; border: none; width: 172px; padding: 6px; margin-bottom: 10px;}
.close-btn { border: none; background-color: transparent;}


@media(max-width: 480px) {
   
    .create-resume { padding-top: 98px; height: 100%; width: 100%; padding-bottom: 140px; position: relative;}
    .create-resume .mobile-line-box { border-radius: 4px; background-color: #172742; padding: 0px 16px; padding-bottom: 8px; margin-bottom: 8px; margin-left: 20px; margin-right: 20px;}
    .create-resume .number-line { padding-top: 20px;}
    .mobile-line-box .mobile-line { position: relative; top: 0;}
    .mobile-line-box .mobile-line::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #64748B; border-radius: 20px 0 0 20px; }
    .mobile-line-box .mobile-line::after { position: absolute; top: 0; content: ""; width: 13%; height: 6px; background-color: #FFC000; border-radius: 20px 0 0 20px; }
    .profile-build .information { margin: 0; position: unset; top: 8px; left: 67%; transform: unset; padding-bottom: 12px;}
    .profile-build .auto-button { margin-bottom: 8px;}
    .profile-build { margin: 0 20px;}
    .build-box-mobile { padding: 16px; border-radius: 12px; background-color: rgba(255, 255, 255, 0.85); margin: 0 20px 8px 20px;}
    .build-box-mobile .build-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; text-align: center; margin-bottom: 4px;}
    .build-box-mobile .build-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 16px; margin: 0; text-align: center;}
    .build-box-mobile .auto-button { text-align: center; border-radius: 1000px; padding: 6px 12px; background-color: #F1F5F9; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; margin-bottom: 8px;}
    .build-box-mobile .send-resume-btn { position: relative;}
    .build-box-mobile .information { color: #D97706; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;  margin: 0;}
    .build-box-mobile .file-name { color:#11274B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: normal; margin-bottom: 0;}
    .build-box-mobile .border-bottom { stroke-width: 1px; stroke:  #E2E8F0;}
    .build-box-mobile .done-btn { color: #0D9488; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 1000px; background-color:  #F1F5F9; border: none; width: 172px; padding: 6px; margin-bottom: 8px;}
    .personal-from { padding: 16px; border-radius: 12px; background-color: rgba(255, 255, 255, 0.85); margin: 0 20px 8px 20px;}
    .personal-title {color:#475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase;}
    .profile-build .build-title { font-size: 16px; line-height: 20px; padding-top: 16px;}
    .profile-build .build-text { font-size: 10px; line-height: 16px; padding-bottom: 16px; padding: 0 30px; margin: 0;}
}

.resume-build { padding-top: 128px; background-image: url('./assets/login/login-hero.svg'); height: 100%; width: 100%; background-size: cover; object-fit: cover; background-repeat: repeat; padding-bottom: 100px; background-attachment: fixed; min-height: 100vh;}
.resume-build-1 { padding-top: 128px; background-image: url('./assets/login/login-hero.svg'); height: 100%; width: 100%; background-size: cover; object-fit: cover; background-repeat: repeat; padding-bottom: 220px; background-attachment: fixed; min-height: 100vh;}
.build-profile-line { position: relative; margin: 0 16px;}
.build-profile-line::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #FFF; }
.build-profile-line::after { position: absolute; top: 0; content: ""; width: 50%; height: 6px; background-color: #FFC000; }
.build-profile-one-line { position: relative; margin: 0 16px;}
.build-profile-one-line::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #FFF; }
.build-profile-one-line::after { position: absolute; top: 0; content: ""; width: 70%; height: 6px; background-color: #FFC000; }
.build-profile-two-line { position: relative; margin: 0 16px;}
.build-profile-two-line::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #FFF; }
.build-profile-two-line::after { position: absolute; top: 0; content: ""; width: 85%; height: 6px; background-color: #FFC000; }
.build-resume-two { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 14px; padding-top: 8px; padding-bottom: 8px;}

@media(max-width: 480px) {
    .resume-build { padding-top: 100px; height: 100%; width: 100%; padding-bottom: 140px; position: relative;}
    .resume-build .mobile-line-box { border-radius: 4px; background-color: #172742; padding: 0px 16px; padding-bottom: 8px; margin-bottom: 8px; margin-left: 20px; margin-right: 20px;}
    .resume-build .number-line { padding-top: 20px;}
    .resume-build-1 { padding-top: 100px; height: 100%; width: 100%; padding-bottom: 430px; position: relative;}
    .resume-build-1 .mobile-line-box { border-radius: 4px; background-color: #172742; padding: 0px 16px; padding-bottom: 8px; margin-bottom: 8px; margin-left: 20px; margin-right: 20px;}
    .resume-build-1 .number-line { padding-top: 20px;}
}



.resume-two-build { padding-top: 128px; background-image: url('./assets/login/login-hero.svg'); height: 100%; width: 100%; background-size: cover; object-fit: cover; background-repeat: repeat; padding-bottom: 100px; background-attachment: fixed; min-height: 100vh;}
.radio-button { display: inline-block; position: relative; cursor: pointer; }
.radio-button__input { position: absolute; opacity: 0; width: 0; height: 0; }
.radio-button__label { display: inline-block; padding-left: 30px; position: relative; font-size: 15px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; cursor: pointer; transition: all 0.3s ease; }
.radio-button__input:checked + .radio-button__label .radio-button__custom { background-color: #FFF; border: 6px solid  #FFC000; box-shadow: 0 0 20px #4c8bf580; }
.radio-button__custom { position: absolute; top: 0; left: 0; width: 20px; height: 20px; border-radius: 16px; border: 1px solid  #64748B; transition: all 0.3s ease; }

@media(max-width: 480px) {
    .radio-button__label { display: inline-block; padding-left: 26px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; cursor: pointer; transition: all 0.3s ease; }
}

.lang-input { -webkit-appearance: none; display: inline-block; width: 20px; height: 20px; border-radius: 12px; cursor: pointer; vertical-align: middle; background-color: hsla(0,0%,0%,.2); background-image: -webkit-radial-gradient( rgb(255, 255, 255) 0%, rgb(255, 255, 255) 25%, hsl(0, 0%, 100%) 30%, hsl(45, 100%, 50%) 35% ); background-repeat: no-repeat; -webkit-transition: background-position .15s cubic-bezier(.8, 0, 1, 1), -webkit-transform .25s cubic-bezier(.8, 0, 1, 1); outline: none;}
.lang-input:checked { -webkit-transition: background-position .2s .15s cubic-bezier(0, 0, .2, 1), -webkit-transform .25s cubic-bezier(0, 0, .2, 1); }      
.lang-input:active { -webkit-transform: scale(1.5); -webkit-transition: -webkit-transform .1s cubic-bezier(0, 0, .2, 1); }       
.lang-input, .lang-input:active { background-position: 0 24px; }
.lang-input:checked { background-position: 0 0; }      
.lang-input:checked ~ .lang-input, .lang-input:checked ~ .lang-input:active { background-position: 0 -24px; }

.help-us-section { padding-top: 128px; background-image: url('./assets/login/login-hero.svg'); height: 100vh; width: 100%; background-size: cover; object-fit: cover; background-repeat: repeat;}
.help-us-line { position: relative; margin: 0 16px;}
.help-us-line::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #FFF; }
.help-us-line::after { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #FFC000; }
.help-us-section .get-otp-btn { border-radius: 8px; background: #FFCA27; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 100%; padding: 12px 0; border: none; transition: all 0.5S;}
.help-us-section .get-otp-btn:hover { color: #64748B; background-color: #FFC000;}
.otp-container { display: flex; justify-content: center; align-items: center; padding-bottom: 24px;}
.otp-container .otp-content { width: 300px;}
.resend-btn { color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; border: none; background-color: transparent; padding-top: 8px;}
.complete-section { background-image: url('./assets/login/login-hero.svg'); height: 100vh; width: 100%; background-size: cover; object-fit: cover; background-repeat: repeat; position: relative;}
.complete-section .complete-box { position: relative; border-radius: 12px; background-color: rgba(17, 39, 75, 0.95); display: inline-flex; padding: 90px 32px 40px 32px; flex-direction: column; align-items: center; gap: 40px; left: 50%; top: 200px; transform: translate(-50%, 0%); width: 364px;} 
.complete-box .wrapper { background-image: url('./assets/complete-wrapper.svg'); height: 85px; width: 233px; position: absolute; top: 16px; background-size: cover; background-repeat: no-repeat; object-fit: cover;} 
.complete-box .complete-title { text-align: center; color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 36px; font-style: normal; font-weight: 600; line-height: 50px; margin-bottom: 16px;}  
.complete-box .complete-text { color: #F8FAFC; text-align: center; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 400; line-height: 22px; }  
.complete-box .dashboard-btn { border-radius: 8px; background-color:  #FFCA27; border: none; width: 100%; padding: 16px; color:#475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; transition: all 0.5s;}  
.complete-box .dashboard-btn:hover { color: #64748B; background-color: #FFC000;}  


.bottom-btn { position: fixed; bottom: 0px; border-top: 1px solid #2E3D55; background-color: #172742; box-shadow: 8px 0px 20px 0px rgba(255, 255, 255, 0.10); padding: 20px; width: 100%;}
.bottom-btn .skip-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; padding: 10px 0px; border-radius: 8px; background-color: #F1F5F9; width: 100%; }
.bottom-btn .save-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 10px 0; border: none; border-radius: 8px; background-color: #FFCA27; width: 100%; margin-bottom: 20px;}

@media(max-width: 480px) {
    .lang-input { margin-right: 16px;}
    .resume-two-build { padding-bottom: 500px; height: 100%;}
    .mobile-line-box .mobile-line-2 { position: relative; top: 0;}
    .mobile-line-box .mobile-line-2::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #64748B; border-radius: 20px 0 0 20px; }
    .mobile-line-box .mobile-line-2::after { position: absolute; top: 0; content: ""; width: 38%; height: 6px; background-color: #FFC000; border-radius: 20px 0 0 20px; }
    .mobile-line-box .mobile-line-3 { position: relative; top: 0;}
    .mobile-line-box .mobile-line-3::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #64748B; border-radius: 20px 0 0 20px; }
    .mobile-line-box .mobile-line-3::after { position: absolute; top: 0; content: ""; width: 63%; height: 6px; background-color: #FFC000; border-radius: 20px 0 0 20px; }
    .mobile-line-box .mobile-line-4 { position: relative; top: 0;}
    .mobile-line-box .mobile-line-4::before { position: absolute; top: 0; content: ""; width: 100%; height: 6px; background-color: #64748B; border-radius: 20px 0 0 20px; }
    .mobile-line-box .mobile-line-4::after { position: absolute; top: 0; content: ""; width: 80%; height: 6px; background-color: #FFC000; border-radius: 20px 0 0 20px; }
    .resume-two-build .mobile-line-box { border-radius: 4px; background-color: #172742; padding: 0px 16px; padding-bottom: 8px; margin-bottom: 8px; margin-left: 20px; margin-right: 20px;}
    .resume-two-build .number-line { padding-top: 20px;}
    .otp-container { display: flex; justify-content: center; flex-direction: column; align-items: center; padding-bottom: 16px;}
    .complete-section .complete-box { width: 335px;} 
    .complete-box .complete-title { font-size: 36px; font-weight: 600; line-height: 50px; margin-bottom: 16px;}  
    .complete-box .complete-text { font-size: 12px; font-weight: 400; line-height: 16px; }  
    .complete-box .dashboard-btn { padding: 10px; font-size: 14px; font-weight: 600; line-height: 20px; }  
}


.App { font-family: sans-serif; }
  
.dropdown { margin: 0 auto; width: 100%; margin-bottom: 12px;}
.dropdown .dropdown-btn { cursor: pointer; display: flex; align-items: center; justify-content: space-between; background: white; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10.5px 8px 9.5px 13px; border: 1px solid #808080; border-radius: 8px; transition: all 0.5s;}
.dropdown .dropdown-btn input { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; }
.dropdown .dropdown-btn:hover { border: 1px solid #94A3B8; color: #94A3B8;}
.dropdown-content { margin-top: 8px; position: absolute; z-index: 100; left: 0; width: 100%; padding: 4px; border-radius: 8px; background: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); max-height: 300px; overflow-y: scroll;}
.dropdown-content::-webkit-scrollbar { width: 2px; } 
.dropdown-content::-webkit-scrollbar-track { background: #f1f1f1;  }
.dropdown-content::-webkit-scrollbar-thumb { background: #888;}
.dropdown-content .item { border-radius: 8px; background: #F1F8FE; cursor: pointer; padding: 8px 16px; color: #0F172A; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.dropdown-content .item:hover { background: #FFF; border-radius: 8px; }

.custom-file-input[type="file"] { display: none; }
  
.contact-page { display: flex; padding-bottom: 0px; flex-direction: column; align-items: flex-start;gap: 14px; border-radius: 16px 24px 24px 16px; background: rgba(255, 255, 255, 0.85); width: 100%; padding-bottom: 24px;}
.contact-page .contact-information { display: flex; padding: 16px 24px; flex-direction: column; align-items: flex-start; gap: 14px; width: 100%; }
.contact-information .contact-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.contact-information .contact-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.information-input { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 4px;}
.information-input-box { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; border-radius: 8px; border: 1px solid #808080; background: #FFF; padding: 10px 12px;}
.information-input-box:hover { color: #94A3B8; border: 1px solid #94A3B8;}
.information-input-box:focus { outline: 2px solid #94A3B8;}
.information-border { background: #E2E8F0; height: 1px; width: 100%;}
.contact-page .resume-box { display: flex; padding: 16px 24px; flex-direction: column; align-items: flex-start; gap: 14px; width: 100%;}
.resume-box .resume-title- { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.resume-box .resume-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0; white-space: pre-line;}
.auto-button- { display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; flex-shrink: 0; border-radius: 1000px; background: #F1F5F9;}
.done-btn { border: none; height: 36px; padding: 6px 12px; border-radius: 1000px; background: #F1F5F9; width: 172px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }

.screening-box { display: flex; padding: 0px 24px; flex-direction: column; align-items: flex-start; gap: 14px; width: 100%; }
.screening-box .screening-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.screening-box .screening-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.screen-submit { display: flex; width: 300px; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border-radius: 8px; background: #FFCA27; border: none; transition: all 0.5s; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }
.bottom-submit { position: fixed; bottom: 0; border-top: 1px solid #2E3D55; background: #172742; box-shadow: 8px 0px 20px 0px rgba(255, 255, 255, 0.10); padding: 20px; width: 100%; }

@media(max-width: 480px) {
    .contact-page .contact-information { display: flex; padding: 16px; flex-direction: column; align-items: flex-start; gap: 12px; width: 100%; border-radius: 12px; }
    .information-input-box { font-size: 14px; padding: 8px 10px;}
    .contact-page .resume-box { padding: 16px; gap: 12px; width: 100%;}
    .screening-box { padding: 16px; flex-direction: column; align-items: flex-start; gap: 12px; width: 100%; }
}
/* .screen-submit:hover { } */




/* custom modal */
.desktop-custom-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.315);
}


.community-container { background: rgba(255, 255, 255, 0.75); padding: 48px 108px; margin-top: 80px;}
.community-container .courses-title { color: #000; font-family: 'Inter', sans-serif; font-size: 30px; font-style: normal; font-weight: 600; line-height: 36px; margin-bottom: 12px;}
.community-container .courses-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; margin-bottom: 0;}
.community-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background: #FFCA27; box-shadow: 0px 4px 40px 0px rgba(255, 202, 39, 0.20); display: flex; width: 237px; height: 44px; padding: 10px 16px; justify-content: center; align-items: center; gap: 8px; border: none;}
.community-body { display: grid; gap: 24px; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; padding-top: 30px; }

@media(max-width: 1700px) { 
    .community-body { grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
}
@media(max-width: 1500px) { 
    .community-body { grid-template-columns: 1fr 1fr 1fr 1fr; }
}
@media(max-width: 1200px) { 
    .community-body { grid-template-columns: 1fr 1fr 1fr; }
}
@media(max-width: 900px) { 
    .community-body { grid-template-columns: 1fr 1fr; }
}

.add-skill-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background: #F1F5F9; border: 0; padding: 10px; width: 280px; transition: all 0.5s; }
.add-skill-btn:hover { color: #11274B; background-color: #E2E8F0;}

@media(max-width: 480px) {
    .add-skill-btn { width: 100%; margin-bottom: 10px;}
    .community-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; background: #FFCA27; box-shadow: 0px 4px 40px 0px rgba(255, 202, 39, 0.20); display: flex; width: auto; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border: none;}
    .community-card { width: 192px; border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF; padding: 8px; height: 360px;}
    .community-container {  padding: 14px 16px; }
    .community-container .courses-title { color: #000; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 0px;}
    .community-container .courses-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
    .community-body {gap: 8px; flex-wrap: wrap; padding-top: 30px; }
}
@media(max-width: 375px) {
    .community-card { width: 166px; border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF; padding: 8px; height: 380px;}
}

.loader-container { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: #0000003b; z-index: 1000;}

.loader-container .loader { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100px; height: 100px; border: 5px solid rgb(23, 39, 66); border-bottom-color: transparent; border-radius: 50%; display: inline-block; box-sizing: border-box; animation: rotation 1s linear infinite;}

@media(max-width: 480px) {
    .loader-container .loader { left: 39%; top: 50%; transform: translate(-50%, -50%);}
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.nsm7Bb-HzV7m-LgbsSe { border: none !important; background-color: #E2E8F0; border-radius: 50%;}
