.dashboard { background-color: #F1F5F9; padding-top: 84px; position: relative; z-index: 10; padding-bottom: 100px; height: 100%; min-height: 100vh; background-attachment: fixed; background-repeat: repeat-x; overflow: scroll;}
.dashboard-box { background-color: #F1F5F9; display: flex; background-image: unset;}
.dashboard .sidebar { position: relative; z-index: 15; border-radius: 0px 0 42px 0; /* background-image: url('../assets/sideBar/sidebar-bg.svg'); height: 627px; width: 239px; background-repeat: no-repeat; */ background-color: #172742; padding: 9px 0 9px 0px; display: flex; left: 410px; transition: left 0.3s ease;}
.side-icon-box { position: relative; background-color: #172742; padding: 9px; border-radius: 0px 0 24px 24px; z-index: 20;}
.sidebar-icon { border-radius: 20px; background: #F6F6FA; position: relative; z-index: 20; display: flex; flex-direction: column; width: 67px;}
.sidebar-icon a { padding: 15px 24px; position: relative;}
.sidebar-icon a.active::before { position: absolute; content: ""; height: 49px; width: 5px; background-color: #172742; left: 0; border-radius: 0 16px 16px 0; top: 8px;}
.border { width: 28px; margin: 2px auto;}
.sidebar-text { position: relative; padding: 9px 0 9px 0;}
.search-btn { border-radius: 8px; background-color: #2E3D55; padding: 8px 16px;}
.search-btn .search-input { background-color: transparent; border: none; outline: none; color: #FFF !important; font-family: "Inter", sans-serif; font-size: 12px; font-style: normal; font-weight: 500; line-height: normal;}
.media-title { padding: 9px 0; color: #BCBCC1; font-family: "Inter", sans-serif; font-size: 12px; font-style: normal; font-weight: 300; line-height: normal;}
.unsaved-image { display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 16px; background-color: #fff; width: 100%; padding: 12px; border-radius: 8px;}
.unsaved-image .unsaved-text {  color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 40px;}
.course-card-hour { margin: 0; color: #000; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }

@media(min-width: 1024px) {
    .unsaved-image { display: flex;}
    .dashboard { padding-top: 80px; }
}
.sidebar-wrapper { display: flex; flex-direction: row; background-color: #f1f5f9;}
/* .sidebar-top-wrapper { position: absolute; background-image: url('../assets/sideBar/sidebar-wrapper.svg'); height: 40px; width: 40px; right: -40px; top: 0;} */
.sidemenu { list-style-type: none; padding: 0; margin: 0; color: #fff; }
.sidemenu li { padding: 15px 5px 15px 22px; background-color: #172742;}
.sidemenu li a { text-decoration: none; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 300; line-height: normal;}
.sidemenu li.active a { color: #010023; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; background-color: #f1f5f9; border-radius: 30px; }
.sidemenu li.active { font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; background-color: #f1f5f9; border-radius: 30px; position: relative;}
.sidemenu li.active::before { position: absolute; content: ""; background-image: url('../assets/sideBar/sidebarBorder.svg'); height: 50px; width: 22px; background-repeat: no-repeat; background-size: cover; background-position: center; left: 0px; top: 0px;}
.sidemenu li.active + li  { border-top-right-radius: 20px; }
.sidemenu li:has(+ .active)  { border-bottom-right-radius: 20px; }
.sidebar.open { left: 0px; }  
.sidebar.closed { left: -360px; }  
.close-button { margin: 10px; }



@media(max-width: 480px) {
    .dashboard { padding-top: 84px; padding-bottom: 0; height: 100%;}
    .side-icon-box { display: none;}
    .dashboard .sidebar { display: none;}
    .mobile-box { background-image: url('../assets/mobile-hero-bg.svg'); width: 100%; height: 100%; padding: 10px 12px; background-attachment: fixed;}
}

.job-seeker-dashboard { position: absolute; margin: 20px; transition: left 0.3s ease; right: 0;}
.content {padding: 32px 24px; border-radius: 24px; background-color: #FFF; }
/* .content .content-wrapper { position: absolute; background-image: url('../assets/sidebar-wrapper.svg'); height: 80px; width: 80px; background-repeat: no-repeat;  object-fit: cover; top: -20px; left: -20px;} */
.content .job-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; text-align: center; margin-bottom: 20px;}
.job-input- { display: flex; align-items: center; border-radius: 8px; border: 1px solid #808080; background: #FFF; padding: 8px 10px; margin-right: 20px;}
.job-input- input { border: none; padding: 0; padding-left: 8px; outline: none; color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.content .find-job-btn { border-radius: 8px; background-color: #FFCA27; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 11px 18px; border: none;}
.select-job { color: #475569; text-align: right; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; border-radius: 16px; background: #E2E8F0; border: none; padding: 6px 16px; margin-right: 12px; outline: none;}
.job-active { color: #F8FAFC; text-align: right; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; border-radius: 16px; background: #172742; border: none; padding: 6px 16px; margin-right: 12px; outline: none;}
.recommended { padding: 16px 24px; border-radius: 24px; background-color: #FFF; margin-top: 16px;}
.recommended .recommended-title { color: #000; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.recommended .recommended-text { color: #808080; font-family: 'Inter',sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px;}
.recommended-container { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; overflow-y: scroll; overflow-x: hidden; }
.recommended-container::-webkit-scrollbar { width: 2px; } 
.dropdown-content::-webkit-scrollbar-track { background: #f1f1f1;  }
.dropdown-content::-webkit-scrollbar-thumb { background: #888;}
.dropdown-content .item { border-radius: 8px; background: #F1F8FE; cursor: pointer; padding: 8px 16px; color: #0F172A; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.dropdown-content .item:hover { background: #FFF; border-radius: 8px; }

@media(max-width: 1440px) {
    .recommended-container { grid-template-columns: 1fr 1fr 1fr;}
}
@media(max-width: 1024px) {
    .recommended-container { grid-template-columns: 1fr 1fr;}
}
@media(max-width: 480px) {
    .job-input- { margin-right: 0;}
    .recommended-container { grid-template-columns: 1fr; height: auto; width:100%}
}

.business-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 16px; width: 100%;}
.business-card .business-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 12px; transition: all 0.5s;}
.business-card .business-actively:hover { background-color: #E2E8F0; color: #11274B;}
.business-card .business-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.business-card .business-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.business-card .business-card-border { border: 1px solid #F1F5F9; margin: 12px 0;}
.business-card .business-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.business-card .business-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.business-card .apply-btn { border-radius: 8px; background-color: #F1F5F9; display: flex; align-items: center; margin-top: 12px; text-align: center; padding: 6px 12px; margin-right: 14px; justify-content: center; transition: all 0.5s;}
.business-card .apply-btn:hover { background-color: #E2E8F0; }
.business-card .apply-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent; transition: all 0.5s;}
.business-card .apply-btn:hover button {color: #11274B; }
.business-card .save-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px; border: none; margin-top: 12px; }
.mobile-show-hide { display: none;}
.desktop-show-hide { display: block;}
.recommended-left { left: 324px;}

.trackk-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 16px; width: auto;}
.trackk-card .track-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 12px; transition: all 0.5s;}
.trackk-card .track-actively:hover { background-color: #E2E8F0; color: #11274B;}
.trackk-card .track-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.trackk-card .track-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.trackk-card .track-card-border { border: 1px solid #F1F5F9; margin: 12px 0;}
.trackk-card .track-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.trackk-card .track-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}

.draft-dropdown { border-radius: 16px; background: #E2E8F0; padding: 4px 16px;}
.draft-dropdown .dropdown-btn { cursor: pointer; display: flex; align-items: center; justify-content: space-between; transition: all 0.5s; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 20px; border: none;}
.draft-dropdown:hover { border-radius: 16px; background: #172742;}
.draft-dropdown:hover .dropdown-btn { color: #F8FAFC;}
.draft-dropdown:hover .draft-dropdown-content { display: block;}
.draft-dropdown-content { margin-top: 8px; position: absolute; z-index: 100; width: 250px; padding: 4px; border-radius: 8px; background: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); display: none;}
.draft-dropdown-content .item { border-radius: 8px; background: #F1F8FE; cursor: pointer; padding: 8px 16px; color: #0F172A; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.draft-dropdown-content .item:hover { background: #FFF; border-radius: 8px; }

@media(max-width: 480px) {
    .content {display: none;}
    .recommended {display: none;}
    .mobile-recommended { padding-top: 12px; padding-left: 12px; padding-right: 12px;}
    .mobile-show-hide { display: block;}
    .desktop-show-hide { display: none;}
    .recommended { width: 424px; margin: 0; top: 60px; /* background-image: url('../assets/login/login-hero.svg'); background-position: center; background-repeat: no-repeat; background-size: cover; object-fit: cover; height: 100%; width: 100%; */}
    .business-card { margin-right: 0; margin-bottom: 12px;}
    .recommended-left { left: 0px;}
    .dashboard-box { background-color: unset; display: block; background-image: url('../assets/login/login-hero.svg'); height: 100%; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; background-attachment: fixed;}
    .mobile-recommended-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 12px;}
    .mobile-recommended-text { color: #E2E8F0; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 4px;}
    .mobile-business-card { border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF; padding: 12px; margin-bottom: 4px;}
    .mobile-business-card .business-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 8px;}
    .mobile-business-card .business-m-hour { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 8px; }
    .mobile-business-card .business-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
    .mobile-business-card .business-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
    .mobile-business-card .business-card-border { border: 1px solid #F1F5F9; margin: 8px 0;}
    .mobile-business-card .save-btn { border-radius: 8px; background-color: transparent; padding: 6px; border: none; }
    .mobile-business-card .business-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
    .mobile-business-card .business-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
    .mobile-menu-btn { display: flex; flex-direction: column; position: fixed; bottom: 30px; right: 12px; padding: 18px 24px; background-color: #172742; border-radius: 50px; z-index: 1000;}
}

.all-filter-modal { position: fixed; top: 0; left: 0; bottom: 0; right: 0; background: rgb(63, 76, 98, 0.70); z-index: 1000; padding-top: 107px;}
.all-modal { display: none; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow-y: scroll;}
.all-modal-2 { display: block; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow-y: scroll;}
.all-filter-box { width: 432px; position: relative; margin: auto; background-color: #ffffff; padding: 16px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px;}
.all-filter-box .close-btn { position: absolute; top: -30px; right: 0px;}
.all-filter-box .modal-title { color: #11274B; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 16px;}
 .sort-btn { border-radius: 4px; background-color: #F1F5F9; padding: 2px 6px; color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; border: none; margin-bottom: 16px;}
.modal-radio-label { display: inline-block; padding-left: 30px; margin-bottom: 10px; position: relative; font-size: 15px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; cursor: pointer; transition: all 0.3s ease; }
.modal-radio-button:checked + .modal-radio-label .radio-button__custom { background-color: #FFF; border: 6px solid  #FFC000; box-shadow: 0 0 20px #4c8bf580; }
.mobile-job-box { border-radius: 12px; background: #FFF; box-shadow: 0px 1px 4px 0px rgba(23, 39, 66, 0.25); padding: 8px;}


.checkbox-button { display: inline-block; position: relative; cursor: pointer;}
.checkbox-button-input { position: absolute; opacity: 0; width: 0; height: 0; }
.checkbox-button__label { display: inline-block; padding-left: 30px; margin-bottom: 16px; position: relative; font-size: 15px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; cursor: pointer; transition: all 0.3s ease; }
.checkbox-button-input:checked + .checkbox-button__label .checkbox-button__custom { background-image: url('../assets/checkbox-input.svg'); height: 18px; width: 18px; transition: all 0.3s ease; border: none; object-fit: cover; background-size: cover;}
.checkbox-button__custom { position: absolute; top: 0; left: 0; width: 18px; height: 18px; border-radius: 6px; border: 1px solid  #64748B; transition: all 0.3s ease; }
.apply-btn { padding: 6px 12px; border-radius: 8px; background-color: #FFCA27; width: 100%; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none;}

@media(max-width: 480px) {
    .all-modal { display: block;}
    .all-modal-2 { display: none;}
    .checkbox-button__label { font-size: 14px; margin-bottom: 12px;}
    .checkbox-button__custom { top: 3px;}
}


.job-detail-page { position: absolute; margin: 0 20px; transition: left 0.3s ease; margin-top: 20px; display: flex; right: 0;}
.job-description { width: 277px; padding: 8px 12px; margin-bottom: 16px;  background-color: #FFF;  border-radius: 24px;}
.jobs-btn-active { background-color: transparent; color: #172742; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; padding: 10px 20px; border: none; border-bottom: 2px solid #010023; margin: 0 2px;}
.jobs-btn { border: none; background-color: transparent; color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; padding: 10px 28px; }

.job-description-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin: 16px 0;}
.jobs-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 16px; margin-bottom: 12px;}
.jobs-card .jobs-actively { border-radius: 4px; background-color: #F1F5F9; border: none; color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 16px;}
.jobs-card .jobs-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.jobs-card .jobs-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.jobs-card .jobs-card-border { border: 1px solid #F1F5F9; margin: 16px 0;}
.jobs-card .jobs-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.jobs-card .jobs-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 3px;}
.jobs-card .job-apply-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px 12px; margin-top: 12px;}
.jobs-card .job-apply-btn button { border: none; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; background-color: transparent;}
.view-all-btn { color: #11274B; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 16px 0;}

.job-details { border-radius: 16px 24px 24px 16px; background-color: #FFF; padding: 16px; margin-left: 10px; width: 100%;}
.jobs-details-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.jobs-details-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.detail-apply-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 6px 12px; border-radius: 8px; background-color: #FFCA27; border: none;}

.job-details-ul { padding-left: 20px;}
.job-details-ul li { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.job-details-text {color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.job-detail-link-title { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.job-detail-link { color: #11274B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; text-decoration: none;}
.job-mobile-details { border-radius: 16px 24px 24px 16px; background-color: #FFF; padding: 16px; width: 351px; margin: auto;}
.detail-border { border: 1px solid #E2E8F0; margin: 8px 0;}
.job-link-box { border-radius: 16px 24px 24px 16px; background-color: #FFF; padding: 16px; margin-left: 10px; width: 351px; margin: auto; margin-top: 8px;}


.upgrade-box { width: 710px; position: relative; margin: auto; padding: 20px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px; border-radius: 8px; background-color: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 40px 0px rgba(255, 255, 255, 0.20);}
.upgrade-box .close-btn { position: absolute; top: -30px; right: 0px;}
.upgrade-title { color: #FFF; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.upgrade-text { color: #DBD9D9; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 18px;}
.upgrade-radio-button { padding-left: 30px; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; margin-right: 10px;}
.radio-button__input:checked + .upgrade-radio-button .radio-button__custom { background-color: #FFF; border: 6px solid  #FFC000; box-shadow: 0 0 20px #4c8bf580; }
.upgrade-border { border: 1px solid #475569; margin: 18px auto; max-width: 356px;}
.upgrade-box-one { border-radius: 12px; background-color: #2E3D55; padding: 20px; }
.upgrade-box-title { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.upgrade-box-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; text-transform: uppercase; }
.upgrade-box-border { border: 1px solid #64748B; margin: 8px 0;}
.upgrade-text-one-bold { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 12px;}
.upgrade-text-one { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px;}
.upgrade-starter-btn { border-radius: 8px; background-color: #E2E8F0; padding: 6px 12px; color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 100%; text-align: center;}

.upgrade-box-two { border-radius: 12px; background-color: #CBD5E1; padding: 20px 12px;}
.upgrade-box-two-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.upgrade-box-two-text { color: #000; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; text-transform: uppercase; }
.upgrade-text-two-bold { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 14px;}
.upgrade-box-two-border { border: 1px solid #94A3B8; margin: 8px 0;}
.upgrade-text-two-btn { border-radius: 8px; background-color: #FFCA27; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 20px; width: 100%; border: none; padding: 6px 10px;}

.upgrade-box-three { border-radius: 12px; background-color: #64748B; padding: 20px;}
.upgrade-box-three-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.upgrade-box-three-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; text-transform: uppercase; }
.upgrade-box-three-border { border: 1px solid #94A3B8; margin: 8px 0;}
.upgrade-text-three-bold { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px;  padding-bottom: 12px;}
.upgrade-text-three { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;  padding-bottom: 12px;}
.upgrade-text-three-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 20px; border: none; border-radius: 8px; background-color: #F1F5F9; padding: 6px 0; width: 100%;}


/* Upgrade Page */

.upgrade-hero { background-image: url('../assets/login/login-hero.svg'); height: 100%; width: 100%; padding: 90px 12px 12px 12px; }
.mobile-upgrade-box { border-radius: 8px; background-color: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 40px 0px rgba(255, 255, 255, 0.20); padding: 20px 0; margin-bottom: 6px;}
.mobile-upgrade-title {color: #FFF; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 6px; }
.mobile-upgrade-text { color: #DBD9D9; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 12px;}
.mobile-upgrade-radio-button { padding-left: 30px; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; margin-right: 10px;}
.mobile-upgrade-box-one { border-radius: 12px; background-color: #2E3D55; padding: 20px; margin-bottom: 6px;}
.mobile-upgrade-box-two { border-radius: 12px; background-color: #CBD5E1; padding: 20px 12px; margin-bottom: 6px;}
.mobile-upgrade-box-three { border-radius: 12px; background-color: #64748B; padding: 20px; margin-bottom: 6px;}

/* Save Page  */

.save-page-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.save-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 16px; margin-right: 16px; margin-bottom: 12px; width: 100%; height: 250px;}
.save-card .save-actively { padding: 2px 6px; color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 12px; transition: all 0.5s;}
.save-card .save-actively:hover { color: #11274B; background-color: #E2E8F0;}
.save-card .save-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.save-card .save-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.save-card .save-card-border { border: 1px solid #F1F5F9; margin: 12px 0;}
.save-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.save-card .save-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.save-card .apply-btn { border-radius: 8px; background-color: #F1F5F9; display: flex; align-items: center; margin-top: 12px; text-align: center; padding: 6px 12px; margin-right: 14px; justify-content: center; transition: all 0.5s;}
.save-card .apply-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent; transition: all 0.5s;}
.save-card .apply-btn:hover { background-color: #E2E8F0;}
.save-card .apply-btn:hover button { color: #11274B;}
.save-card .save-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px; border: none; margin-top: 12px; }
.save-box { position: absolute; padding: 16px 24px; margin: 20px; transition: left 0.3s ease; border-radius: 24px; background-color: #FFF; right: 0;}
.mobile-apply-btn { border-radius: 8px; background-color: #F1F5F9; display: flex; align-items: center; margin-top: 12px; text-align: center; padding: 6px 12px; margin-right: 14px; justify-content: center; transition: all 0.5s; width: 100%;}
.mobile-apply-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent; transition: all 0.5s;}
.mobile-apply-btn:hover { background-color: #E2E8F0;}
.mobile-apply-btn:hover button { color: #11274B;}
/* mobile css */

.save-mobile-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 12px; margin-bottom: 4px;}
.save-mobile-card .mobile-hour-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.save-mobile-card .save-mobile-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 8px;}
.save-mobile-card .save-mobile-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.save-mobile-card .save-mobile-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.save-mobile-card .save-mobile-card-border { border: 1px solid #F1F5F9; margin: 8px 0;}
.save-mobile-card .save-mobile-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.save-mobile-card .save-mobile-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.save-mobile-card .apply-btn { border-radius: 8px; background-color: #F1F5F9; display: flex; align-items: center; margin-top: 12px; text-align: center; padding: 6px 12px; width: 172px; justify-content: center;}
.save-mobile-card .apply-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent;}
.save-mobile-card .save-mobile-btn { border-radius: 8px; background-color: transparent; padding: 6px; border: none; }

.up-skill-box { position: absolute; padding: 16px 24px; margin: 20px; transition: left 0.3s ease; border-radius: 24px; background-color: #FFF; right: 0;}
.up-skill-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px;}
.up-skill-page-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.up-skill-card {  width: 100%; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px; height: 350px; position: relative;}
.up-skill-card .course-img { height: 89px; border-radius: 8px; border: 1px solid #F1F5F9; background: lightgray 0px 0px / 100% 98.876% no-repeat; width: 100%; object-fit: cover; background-size: cover;}
.up-skill-card .up-skill-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 12px; transition: all 0.5s;}
.up-skill-card .up-skill-actively:hover { background-color: #E2E8F0; color: #11274B;}
.up-skill-card .up-skill-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.up-skill-card .up-skill-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.up-skill-card .up-skill-name-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.up-skill-card .up-skill-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.up-skill-card .up-skill-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.up-skill-card .up-skill-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.up-skill-card .apply-btn { border-radius: 8px; background-color: #F1F5F9; display: flex; align-items: center; margin-top: 12px; text-align: center; padding: 6px 12px; width: 172px; justify-content: center;}
.up-skill-card .apply-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent;}
.up-skill-card .up-skill-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px; border: none; margin-top: 12px; }
.up-skill-card .up-skill-hour { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.up-skill-body { display: flex; flex-wrap: wrap; gap: 16px;}
.up-skill-card .view-more-btn { position: absolute; bottom: 16px; display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; border: none; background: transparent; margin-top: 12px; display: flex; border-radius: 8px; background: #F1F5F9; width: 90%; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }


@media(max-width: 480px) {
    .up-skill-card {  width: 100%; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px; height: 300px;}
    .analysis-card-mobile { width: 100%; margin-bottom: 10px;}
}

.skill-mobile-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 12px; width: 252px;}
.skill-mobile-card-mobile { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 12px; margin-bottom: 4px;}
.skill-mobile-card .mobile-hour-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.skill-mobile-card .skill-mobile-title-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.skill-mobile-card .skill-mobile-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 8px;}
.skill-mobile-card .skill-mobile-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 18px; }
.skill-mobile-card .skill-mobile-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 4px;}
.skill-mobile-card .skill-mobile-card-border { border: 1px solid #F1F5F9; margin: 8px 0;}
.skill-mobile-card .skill-mobile-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.skill-mobile-card .skill-mobile-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.skill-mobile-card .skill-learn-btn { border-radius: 8px; background-color: #F1F5F9; color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 100%; padding: 6px 12px; margin-top: 12px;}
.skill-mobile-card .skill-mobile-btn { border-radius: 8px; background-color: transparent; padding: 6px; border: none; }
.skill-mobile-card .improve-learn-btn {display: flex; border-radius: 8px; background-color: #F1F5F9; border: none; width: 100%; padding: 6px 12px; margin-top: 8px;}
.skill-mobile-card .improve-learn-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent;}
.skill-mobile-card-mobile .mobile-hour-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.skill-mobile-card-mobile .skill-mobile-title-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.skill-mobile-card-mobile .skill-mobile-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 8px;}
.skill-mobile-card-mobile .skill-mobile-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 18px; }
.skill-mobile-card-mobile .skill-mobile-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 4px;}
.skill-mobile-card-mobile .skill-mobile-card-border { border: 1px solid #F1F5F9; margin: 8px 0;}
.skill-mobile-card-mobile .skill-mobile-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.skill-mobile-card-mobile .skill-mobile-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.skill-mobile-card-mobile .skill-learn-btn { border-radius: 8px; background-color: #F1F5F9; color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 100%; padding: 6px 12px; margin-top: 12px;}
.skill-mobile-card-mobile .skill-mobile-btn { border-radius: 8px; background-color: transparent; padding: 6px; border: none; }
.skill-mobile-card-mobile .improve-learn-btn { display: flex; border-radius: 8px; background-color: #F1F5F9; border: none; width: 100%; padding: 6px 12px; margin-top: 8px; justify-content: center; align-items: center;}
.skill-mobile-card-mobile .improve-learn-btn button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent;}

@media(max-width: 480px) {
    .skill-mobile-card { margin-right: 0px;}
}

.learn-more-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px 12px; display: flex; margin-top: 12px; align-items: center; transition: all 0.5s;}
.learn-more-btn button { background-color: transparent; border: none; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.learn-more-btn:hover { background-color: #E2E8F0;}
.learn-more-btn:hover button { color: #11274B;}


.career-box { position: absolute; padding: 16px 24px; margin: 20px; transition: left 0.3s ease; border-radius: 24px; background-color: #FFF; right: 0;}
.career-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px;}
.career-page-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.career-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 16px; margin-right: 16px; margin-bottom: 12px;}
.career-card .career-actively { color: #808080; font-family: 'Inter',sans-serif; padding: 2px 6px; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 12px; transition: all 0.5s;}
.career-card .career-actively:hover { background-color: #E2E8F0; color: #11274B;}
.career-card .career-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.career-card .career-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.career-card .career-name-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.career-card .career-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.career-card .career-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.career-card .career-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.career-card .book-your-slot { width: 100%; border-radius: 8px; background-color: #F1F5F9; margin-top: 12px; text-align: center; padding: 6px 12px; display: flex; align-items: center; justify-content: center; transition: all 0.5s;}
.career-card .book-your-slot button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent; transition: all 0.5s;}
.career-card .book-your-slot:hover { background-color: #E2E8F0;}
.career-card .book-your-slot:hover button { color: #11274B; }
.career-card .career-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px; border: none; margin-top: 12px; }
.career-card .career-hour { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }

.career-mobile-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 8px 12px; margin-bottom: 4px;}
.career-mobile-card .career-mobile-actively { color: #808080; font-family: 'Inter',sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-radius: 4px; background-color: #F1F5F9; border: none; margin-bottom: 12px;}
.career-mobile-card .career-mobile-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.career-mobile-card .career-mobile-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.career-mobile-card .career-mobile-name-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.career-mobile-card .career-mobile-card-border { border-bottom: 1px solid #F1F5F9; margin: 4px 0;}
.career-mobile-card .career-mobile-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.career-mobile-card .career-mobile-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.career-mobile-card .book-your-slot { width: 100%; border-radius: 8px; background-color: #F1F5F9; margin-top: 4px; text-align: center; padding: 6px 12px; display: flex; align-items: center; justify-content: center;}
.career-mobile-card .book-your-slot button { color: #475569; font-family: 'Inter',sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent;}
.career-mobile-card .career-mobile-btn { border-radius: 8px; background-color: #F1F5F9; padding: 6px; border: none; margin-top: 12px; }
.career-mobile-card .career-mobile-hour { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }

.calender-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1110; overflow: scroll;}
.calender-box { width: 770px; position: relative; margin: auto; background-color: #ffffff; padding: 20px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px;}
.calender-box .close-btn { position: absolute; top: -30px; right: 0px;}
.calender-box-2 { width: 400px; position: relative; margin: auto; background-color: #ffffff; padding: 20px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px;}
.calender-box-2 .close-btn { position: absolute; top: -30px; right: 0px;}
@media (max-width: 480px) {
    .calender-box-2 {padding: 9px;width: 75% !important;}
}
.calender-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow: scroll;}
.interview-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 900; overflow: scroll;}
.interview-box { max-width: 543px; position: relative; margin: auto; background-color: #ffffff; padding: 20px 24px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px; display: flex; flex-direction: column; align-items: flex-start; gap: 12px;}
.interview-box .close-btn { position: absolute; top: -30px; right: 0px;}
.interview-modal-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; }
.interview-modal-to { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; }
.interview-modal-to .to-span { margin-left: 8px; color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; border-radius: 4px; background: #E2E8F0; padding: 2px 10px;}
.interview-input-title { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; padding-bottom: 4px;}
.interview-input { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; transition: all 0.5s; border-radius: 8px; border: 1px solid #808080; background: #FFF; padding: 10px 12px; width: 100%;}
.interview-input:focus { outline: 2px solid #94A3B8;}
.interview-input:hover { border: 1px solid #94A3B8; color: #94A3B8;}
.active-item { border-radius: 16px; border: 1px solid #11274B; background: #E2E8F0; color: #172742; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; padding: 4px 12px 4px 16px; display: flex; height: 28px; justify-content: center; align-items: center; gap: 4px;}
.inactive-item { border-radius: 16px; border: 1px solid #B3B3B3; background: #fff; color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; padding: 4px 12px 4px 16px; display: flex; height: 28px; justify-content: center; align-items: center; gap: 4px;}

.interview-calender { display: flex; justify-content: space-between; align-items: center; height: 44px; padding: 10px 8px 10px 12px; border-radius: 8px; border: 1px solid #808080; background: #FFF;}
.send-invite { width: 300px; height: 44px; border-radius: 8px; background: #FFCA27; color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; padding: 10px 16px; border: none; transition: all 0.5s;}
.send-invite:hover { background: #FFC000; color: #64748B; }



.calender { border-radius: 8px; border: 1px solid #F1F5F9; background-color: #F8FAFC; box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);}
.calender-title { padding-top: 16px; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding-bottom: 12px;}
.calender .react-calendar { border: none;}
.calender .react-calendar__navigation__prev2-button { display: none;}
.calender .react-calendar__navigation__next2-button { display: none;}
.calender .react-calendar__navigation__prev-button { position: relative;}
.calender .react-calendar__navigation__prev-button::before { position: absolute; content: ""; background-image: url('../assets/calender-left-arrow.svg'); height: 20px; width: 20px; background-repeat: no-repeat; left: 14px;}
.calender .react-calendar__navigation__next-button { position: relative;}
.calender .react-calendar__navigation__next-button::before { position: absolute; content: ""; background-image: url('../assets/calender-right-arrow.svg'); height: 20px; width: 20px; background-repeat: no-repeat; right: 14px;}
.calender .react-calendar__navigation__label__labelText--from { color:#262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }
.calender .react-calendar__month-view__weekdays { color: #2E3D55; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; text-transform: capitalize; text-decoration: none;}
.calender .react-calendar__month-view__days__day--neighboringMonth { color: #B3B3B3; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.calender .react-calendar__tile { padding: 14px 10px; color: #262626 ;}
.calender abbr[title] { text-decoration: none;}
.calender .react-calendar__tile--active { color: #11274B; background-color: #E2E8F0 !important; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; border-radius: 24px; border: 1px solid #475569; background-color: #E2E8F0;}
.calender .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus { border-radius: 24px; border: 1px solid #475569; background-color: #E2E8F0; color: #11274B; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.calender .react-calendar__tile--now { border-radius: 24px; background-color: #E2E8F0; color: #11274B; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.calender-tab button { padding: 10px 12px; color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; border: none; border-bottom: 2px solid transparent; background-color: transparent; }
.calender-tab .active-tab { color: #172742; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; border-bottom: 2px solid #172742;}
.tab-content { padding-top: 12px;}

@media(max-width: 480px) {
    .calender-box { width: 348px; position: relative; margin: auto; background-color: #ffffff; padding: 20px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px;}
    .calender .react-calendar__tile { padding: 10px;}
    .calender .react-calendar__navigation__next-button::before { position: absolute; content: ""; background-image: url('../assets/calender-right-arrow.svg'); height: 20px; width: 20px; background-repeat: no-repeat; right: 14px; top: 13px; }
}

.checkbox-calender-button { display: inline-block; position: relative; cursor: pointer;}
.checkbox-calender-button-input { position: absolute; opacity: 0; width: 0; height: 0; }
.checkbox-calender-button__label { display: inline-block; padding-left: 30px; margin-bottom: 20px; position: relative; font-size: 15px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; cursor: pointer; transition: all 0.3s ease; }
.checkbox-calender-button-input:checked + .checkbox-calender-button__label .checkbox-calender-button__custom { background-image: url('../assets/checkbox-input.svg'); height: 20px; width: 20px; transition: all 0.3s ease; border: none;}
.checkbox-calender-button__custom { position: absolute; top: 0; left: 0; width: 20px; height: 20px; border-radius: 6px; border: 1px solid  #64748B; transition: all 0.3s ease; }

.calender-list { padding: 8px 20px; border-radius: 8px; border: 1px solid #F1F5F9; background-color: #F8FAFC; margin-bottom: 8px;}
.calender-list-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.calender-list-time { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;  }
.calender-list-day { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;  }

.calender-time-table { color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding-top: 18px; padding-bottom: 12px;}
.selected-slots { display: flex; align-items: center; border-radius: 16px; background-color: #F1F5F9; padding: 6px 10px; justify-content: space-between; margin-bottom: 12px; margin-right: 10px;}
.selected-slots .selected-slot-title { color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
@media(max-width: 480px) {
    .selected-slots { display: flex; align-items: center; border-radius: 16px; background-color: #F1F5F9; padding: 4px 8px; justify-content: space-between; margin-bottom: 12px;}
}

.track-box { position: absolute; margin: 20px; transition: left 0.3s ease; right: 0;}
.track-box .track-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.track-btn { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-left: 6px;}
.track-card { border-radius: 8px; border: 1px solid #F1F5F9; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 104px; margin: auto; height: 64px; margin-right: 10px;}
.track-card .track-text { color:#4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.track-box-content { padding: 16px 24px; transition: left 0.3s ease; border-radius: 24px; background-color: #FFF;  margin-bottom: 16px;}

.stepper { display: flex; align-items: center;}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed { color: #0D9488 !important;}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active { background-image: url('../assets/step-icon.png'); height: 18px; width: 18px; color: transparent !important;}
.css-117w1su-MuiStepIcon-text { fill: transparent !important;}

.mobile-track-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.mobile-box-text { color: #E2E8F0; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 12px; padding-bottom: 4px;}
.mobile-track-box { border-radius: 8px; border: 1px solid #E2E8F0; background-color: #FFF; padding: 12px; margin-bottom: 4px;}
.mobile-track-box .mobile-business-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.mobile-track-box .mobile-business-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.mobile-business-card-border { border-bottom: 1px solid #F1F5F9; padding: 8px 0; margin-bottom: 8px;}
.mobile-business-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.mobile-business-dot { width: 4px; height: 4px; background-color: #D9D9D9; border-radius: 10px; margin: 0 6px;}
.accordion-header-track { border-radius: 8px; border: 1px solid #F1F5F9; background-color: #F1F8FE; box-shadow: 0px 1px 1px -3px rgba(0, 0, 0, 0.05); padding: 12px 8px; margin: 12px 0 0 0;}
.accordion-header-track .accordion-button { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.accordion-body { border-radius: 8px; border: 1px solid #F1F5F9; background-color: #F1F8FE; box-shadow: 0px 1px 1px -3px rgba(0, 0, 0, 0.05); padding: 12px 8px; margin: 0 0 12px 0;}
.mobile-track-card { border-radius: 8px; border: 1px solid #F1F5F9; display: flex; flex-direction: row; justify-content: center; align-items: center; width: 175px; margin: auto; height: 28px;}
.mobile-track-card .track-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.feedback-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow: scroll;}
.feedback-filter-box { width: 950px; position: relative; margin: auto; background-color: #ffffff; padding: 20px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 131px; margin-bottom: 107px;}
.feedback-filter-box .close-btn { position: absolute; top: -54px; right: 0px;}
.feedback-title { color:#262626; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; }
.feedback-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.feedback-card { padding: 16px; border-radius: 12px; background-color: #F1F5F9; }
.feedback-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.feedback-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.feedback-border { margin: 18px 0; border-bottom: 1px solid #E2E8F0;}
.rating-box { border-radius: 8px; border: 1px solid #E2E8F0; padding: 12px; margin-bottom: 8px;}
.rating-title { color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding-bottom: 8px;}
.rating-box-text { color: #4D4D4D; text-align: right; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.rating-message { border-radius: 8px; border: 1px solid #808080; background-color: #FFF; padding: 8px 10px; width: 100%; margin-top: 16px; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; }
.rating-submit-btn { border-radius: 8px; background-color: #FFCA27; padding: 6px 12px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 356px; margin-top: 14px;}
.feedback-items { display: grid; gap: 16px; grid-template-columns: 1fr 1fr 1fr;}

.we-are-box { border-radius: 12px; border: 1px solid #FFEAD7; background: #F1F5F9; padding: 16px 100px 16px 24px; margin-bottom: 16px;}
.we-are-title { color: #000; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; }

@media(max-width: 1440px) {
    .feedback-items { display: grid; gap: 16px; grid-template-columns: 1fr 1fr 1fr;}
}
@media(max-width: 1024px) {
    .feedback-items { display: grid; gap: 16px; grid-template-columns: 1fr 1fr;}
}

.chat-container { position: absolute; padding: 20px 25px; transition: left 0.3s ease; right: 0;}
.chat-container .chat-box { border-radius: 24px; background-color: #FFF; padding: 16px 24px;}
.chat-container .chat-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.chat-container .open-card { border-radius: 12px; border: 1px solid #CBD5E1; background-color: #F8FAFC; padding: 16px; margin-right: 16px; min-width: 185px;}
.chat-container .open-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.chat-container .open-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.chat-container .open-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.chat-container .open-card-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.chat-container .open-card-dot { height: 4px; width: 4px; background-color: #D9D9D9; border-radius: 50%; margin: 0 6px;}
.chat-container .chat-btn { margin-top: 8px; border-radius: 8px; border: 1px solid #F1F5F9; background-color: #FFF; padding: 4px 8px; display: flex; flex-direction: column; align-items: center;}
.chat-container .chat-btn .chat-btn-text { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }

.chat-container .close-box { border-radius: 24px; background-color: #FFF; padding: 16px 24px; margin-top: 16px;}
.chat-container .close-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.chat-container .close-card { border-radius: 12px; border: 1px solid #CBD5E1; background-color: #F8FAFC; padding: 16px; margin-right: 16px; margin-bottom: 16px;}
.chat-container .close-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.chat-container .close-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.chat-container .close-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.chat-container .close-card-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.chat-container .close-card-dot { height: 4px; width: 4px; background-color: #D9D9D9; border-radius: 50%; margin: 0 6px;}
.chat-container .chat-btn { margin-top: 8px; border-radius: 8px; border: 1px solid #F1F5F9; background-color: #FFF; padding: 4px 8px; display: flex; flex-direction: column; align-items: center;}
.chat-container .chat-btn .chat-btn-text { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }

/* mobile chat Page */
.chat-mobile-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 12px;}
.chat-mobile-text { color: #E2E8F0; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 4px;}
.open-mobile-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #ffffff; padding: 12px; margin-bottom: 4px;}
.open-mobile-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.open-mobile-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.open-mobile-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.open-mobile-card-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.open-mobile-card-dot { height: 4px; width: 4px; background-color: #D9D9D9; border-radius: 50%; margin: 0 6px;}
.chat-mobile-btn { margin-top: 8px; border-radius: 8px; border: 1px solid #F1F5F9; background-color: #F8FAFC; padding: 4px 8px; display: flex; flex-direction: row; align-items: center; justify-content: center; margin-top: 12px;}

.close-mobile-card { border-radius: 12px; border: 1px solid #CBD5E1; background-color: #F8FAFC; padding: 16px; margin-bottom: 4px;}
.close-mobile-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.close-mobile-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.close-mobile-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.close-mobile-card-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.close-mobile-card-dot { height: 4px; width: 4px; background-color: #D9D9D9; border-radius: 50%; margin: 0 6px;}
.chat-mobile-btn .chat-btn-text { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }

/* Chat Modal CSS */

.chat-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow: scroll;}
.chat-filter-box { max-width: 646px; position: relative; margin: auto; border-radius: 8px; background-color: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 40px 0px rgba(255, 255, 255, 0.20); padding: 20px; margin-top: 107px; margin-bottom: 107px;}
.chat-filter-box .close-btn { position: absolute; top: -30px; right: 0px;}
.chat-modal .chat-modal-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; padding-bottom: 4px;}
.chat-modal .chat-modal-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.chat-modal .chat-modal-btn { color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; background-color: transparent; padding: 0 16px;}
.chat-modal .chat-modal-border { border-bottom: 1px solid #475569; margin: 12px 0;}
.chat-modal .chat-modal-date { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; padding-bottom: 8px;}

@media(max-width: 480px) {
    .chat-filter-box { margin: 123px 10px 40px 10px;}
}

.chat-modal-container { padding: 0 10px; overflow-y: scroll; overflow-x: hidden; height: 420px;}
.chat-modal-container::-webkit-scrollbar { display: none; }
.chat-modal-container .chat-message-left  { padding: 10px 12px; border-radius: 8px; background-color: rgba(255, 255, 255, 0.10); max-width: 429px; position: relative; margin-top: 8px; margin-bottom: 22px;}
.chat-modal-container .chat-message-left p { text-align: left; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 24px; }
.chat-modal-container .chat-message-left .chat-message-time { position: absolute; bottom: -16px; right: 0; color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }

.chat-modal-container .chat-message-right  { padding: 10px 12px; border-radius: 8px; background-color: rgba(255, 255, 255, 0.10); max-width: 429px; position: relative; margin-top: 8px; margin-bottom: 22px; margin-left: auto;}
.chat-modal-container .chat-message-right p { text-align: left; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 24px; }
.chat-modal-container .chat-message-right .chat-message-time { position: absolute; bottom: -16px; right: 0; color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.send-message-input-box { display: flex; align-items: center; justify-content: space-between; gap: 6px; padding: 24px 12px; position: absolute ; bottom: 0;  overflow: hidden;}
.send-message-input-box-desktop { display: flex; align-items: center; justify-content: space-between; gap: 6px; padding: 24px 12px; position: absolute ; bottom: 0;  overflow: hidden;}
.send-message-input-box-mobile { display: none;}
.send-message-input { display: flex; justify-content: space-between; width: 432px; align-items: center; padding: 10px 8px 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color: #FFF;}
.send-message-input .send-message { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; border: none; outline: none;}
.send-message-input .send-btn { border: none; background-color: transparent;}
.send-message-input-box-mobile .emoji-btn { padding: 12px; border-radius: 4px; background-color: #475569; border: none;}
.send-message-input-box .emoji-btn { padding: 12px; border-radius: 4px; background-color: #475569; border: none;}
.send-message-input-box-desktop .emoji-btn { padding: 12px; border-radius: 4px; background-color: #475569; border: none;}
.send-message-input-box-mobile .emoji-btn { padding: 12px; border-radius: 4px; background-color: #475569; border: none;}

@media(max-width: 480px) {
    .send-message-input-box-mobile { display: flex; align-items: center; justify-content: space-between; gap: 6px; background: #172742; padding: 24px 12px; position: absolute ; bottom: 0; width: 100%; overflow: hidden;}
    .send-message-input-box-desktop { display: none;}
    .send-message-input { width: 100%;}
    .send-message-input-box { display: flex; align-items: center; justify-content: space-between; gap: 6px; padding: 12px 0; position: absolute ; bottom: 0;  overflow: hidden; width: 90%;}
}

/* Mobile Open Chat css */

.send-message-input-box { display: flex; justify-content: space-between;}
.chat-mobile-box { margin-top: 100px; border-radius: 8px; background-color: rgba(17, 39, 75, 0.95); padding: 20px 16px; margin-left: 8px; margin-right: 8px; position: relative;}
.chat-mobile-box .chat-mobile-modal-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.chat-mobile-box .chat-mobile-modal-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.dot-btn { border: none; background-color: transparent;}
.card-mobile-chat-border { border-bottom: 1px solid #475569; margin: 18px 0;}
.chat-mobile-date { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 8px;}
.chat-mobile-container { overflow-y: scroll; overflow-x: hidden; height: 528px;}
.chat-mobile-container::-webkit-scrollbar { display: none; }
.chat-mobile-message-left { padding: 10px 12px; border-radius: 8px; background-color: rgba(255, 255, 255, 0.10); max-width: 272px; position: relative; margin-top: 8px; margin-bottom: 22px; }
.chat-mobile-message-left p { text-align: left; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 24px; }
.chat-mobile-message-left .chat-message-time { position: absolute; bottom: -16px; right: 0; color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.chat-mobile-message-right { padding: 10px 12px; border-radius: 8px; background-color: rgba(255, 255, 255, 0.10); max-width: 288px; position: relative; margin-top: 8px; margin-bottom: 22px; margin-left: auto;}
.chat-mobile-message-right p { text-align: left; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 24px; }
.chat-mobile-message-right .chat-message-time { position: absolute; bottom: -16px; right: 0; color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.send-mobile-message-input-box { display: flex; align-items: center; justify-content: space-between; background-color: #172742; padding: 24px; position: fixed; bottom: 0; width: 100%;}
.send-mobile-message-input { display: flex; justify-content: space-between; width: 195px; align-items: center; padding: 8px 8px 8px 10px; border-radius: 8px; border: 1px solid #808080; background-color: #FFF;}
.send-mobile-message-input .send-message { color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; width: 140px; font-style: normal; font-weight: 400; line-height: 24px; border: none; outline: none;}
.send-mobile-message-input .send-btn { border: none; background-color: transparent;}
.send-mobile-message-input-box .emoji-btn { padding: 10px; border-radius: 4px; background-color: #475569; border: none;}
.send-mobile-message-input-box .emoji-btn img { height: 20px; width: 20px; object-fit: cover;}
.chat-mobile-card { position: relative;}
.chat-mobile-card .chat-dropdown { position: absolute; border-radius: 8px; background-color: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); width: 272px; z-index: 20; right: 12px; top: 34px;}
.chat-mobile-card .chat-dropdown button { display: block; border: none; background-color: transparent; width: 100%; padding: 9px 16px; text-align: left; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }

.rating-star { background-color: transparent; border: none; outline: none; cursor: pointer; }
.on { background-image: url('../assets/rating/star-hover.svg'); height: 32px; width: 32px;}
.off { background-image: url('../assets/rating/star-none.svg'); height: 32px; width: 32px;}

/* Rating Title */

.mobile-rating-box { margin-top: 70px;}
.rating-mobile-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; padding-top: 12px; margin-bottom: 6px;}
.rating-mobile-text { color: #F1F5F9; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px;}


/* analysis Css Start */

.analysis-box { position: absolute; margin: 20px; transition: left 0.3s ease; z-index: 50; right: 0;}
.analysis-box .feedback-card { border-radius: 16px; background-color: #C7D2FE; display: flex; padding: 16px 24px; flex-direction: column; align-items: center; gap: 20px;}
.feedback-card .feedback-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.feedback-card .improve-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; background-color: #F1F5F9; display: flex; width: 202px; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border: none; transition: all 0.5s;}
.feedback-card .improve-btn:hover { background: #E2E8F0; color: #11274B; }
.feedback-card .improve-btn-2 { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; background-color: #172742; padding: 6px 12px; border: none; width: 205px; height: 36px;}
.analysis-box .recommend-box { border-radius: 16px; background-color: #ffffff; padding: 20px 24px 20px 24px; margin-top: 20px;}
.recommend-box .recommend-title {color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 18px;}
.recommend-box .view-all {background-color: transparent; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px;border: none;float: right;}
/* .recommend-box .recommend-cards {display: flex; flex-wrap: wrap;width: 788px;} */
.analysis-box .feedback-box { border-radius: 16px; background-color: #ffffff; display: flex; padding: 20px 24px 20px 24px; flex-direction:column;margin-top: 20px;}
.feedback-box .feedback-title {color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.feedback-box .upgrade-premium { background-color:#172742; color: #FFFFFF; padding: 6px 12px; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px;border: none; border-radius: 8px; width: 205px; height: 36px;}
.feedback-box .feedback-subtitle {color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400;line-height: 18px; margin-bottom: 0;}
.feedback-box .feedback-container {width: 788px;height: 432px;gap: 16px;  filter: blur(2px); -webkit-filter: blur(2px);}


/* analysis Css End */

.react-calendar__month-view__days__day--weekend { color: #000 !important;}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus { border-radius: 50% !important;}
.react-calendar__tile--active { background-color:  transparent !important;}


 /* Circle Bar Process Css */


.CircularProgressbar .CircularProgressbar-trail { stroke: #E2E8F0 !important; } 
.CircularProgressbar .CircularProgressbar-path { stroke: #172742 !important;}
.CircularProgressbar .CircularProgressbar-text { fill: #4D4D4D !important; font-family: 'Inter', sans-serif; font-size: 12px !important; font-style: normal !important; font-weight: 600 !important; line-height: 16px !important; }
.circle-text { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-top: 10px;}
.circle-text-mobile { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}

.received-card { width: 100%; height: auto ; padding: 12px ; border-radius: 8px; border: 1px solid #E2E8F0; gap: 8px; display: flex; flex-direction: column;}
.received-card .received-title { color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }
.received-card .received-rating { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.received-card .received-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.received-mobile-card { padding: 12px; border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF; gap: 8px; display: flex; flex-direction: column; margin-bottom: 4px;}
.received-mobile-card .received-mobile-title { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; border-radius: 4px; background: #F1F5F9; padding: 0 6px;}
.received-mobile-card .received-mobile-hour { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.received-mobile-card .received-mobile-rating { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px;}
.received-mobile-card .received-mobile-text {color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.received-mobile-card .received-mobile-border { border-bottom: 1px solid #F1F5F9; }
.feedback-mobile-box {  filter: blur(2px); -webkit-filter: blur(2px);}

.mobile-feedback-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 12px;}
.feedback-box-mobile { border-radius: 12px; background: #C7D2FE; padding: 12px;}
.feedback-box-mobile .rating-box-mobile { border-radius: 8px; background: rgba(255, 255, 255, 0.20); padding: 12px;}
.improve-btn-mobile-2 { color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 100%; border-radius: 8px; background: #172742; padding: 6px 12px; border: none; margin: 12px 0;}
.improve-btn-mobile { color: #64748B; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; align-self: stretch; border: none; width: 100%; background-color: transparent;}
.improve-btn-mobile-unUpgrade { padding: 6px 12px; border-radius: 8px; background: #F1F5F9; margin-top: 12px; width: 100%; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none;}
.mobile-feedback-text { color: #F1F5F9; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px;}
.upgrade-premium-mobile { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; border-radius: 8px; background: #F1F5F9; padding: 6px 12px;}



.filter-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow: hidden;}
.filter-box { width: 368px; position: relative; margin: auto; background-color: #ffffff; padding: 4px; border-radius: 8px; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); margin-top: 107px; margin-bottom: 107px;}
.filter-box .close-btn { position: absolute; top: -30px; right: 0px;}
.filter-item { margin: 0; padding: 0; list-style: none;}
.filter-item li { border-radius: 8px; background: #FFF; padding: 9px 20px 9px 16px; height: 36px; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; transition: all 0.5s; width: 140px;}
.filter-item li:hover { background: #F1F8FE;}
.tab-content  { border-left: 1px solid #F1F5F9;}
.filter-option { margin: 0; padding: 0; list-style: none;}
.filter-option li { padding: 9px 20px 9px 16px;}

.ca-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow: hidden;}
.ca-box { max-width: 400px; position: relative; margin: auto; background-color: #ffffff; border-radius: 24px; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); margin-top: 107px; margin-bottom: 107px; padding: 16px 24px;}
.ca-box .close-btn { position: absolute; top: -30px; right: 0px;}

@media(max-width: 768px) {
    .ca-box { max-width: 350px;}
}



.sidebar-scroll { display: flex; position: fixed; top: 0px; height: 660px;}
/* .sidebar-scroll { overflow-y: scroll; overflow-x: hidden; display: flex; position: fixed; top: 80px; height: 660px;} */


.fc .fc-scroller table thead tr { background-color: #e2e8f0;}
.fc .fc-col-header-cell-cushion { padding: 10px 12px !important; color: #000 !important; text-align: center !important; font-family: 'Inter', sans-serif !important; font-size: 12px !important; font-style: normal; font-weight: 600; line-height: 130%; text-decoration: none !important;}
.fc .fc-daygrid-day-number { display: flex; height: 60px; padding: 6px 12px; justify-content: flex-end; align-items: flex-start; color: #4D4D4D !important; text-align: right; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; text-decoration: none;}
.fc-daygrid-day-events { display: none !important;}
.fc .fc-daygrid-day-frame { display: flex; height: 60px !important; padding: 6px 12px; justify-content: flex-end; align-items: flex-start;}
.fc .fc-scrollgrid-section-body table { height: 364px !important;}
.fc .fc-scrollgrid-liquid { height: 404px !important;}
.fc .fc-view-harness { height: 410px !important;}


.mobile-search-job { display: flex; flex-direction: column; gap: 12px;}
.find-jobs-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s; border-radius: 8px; background: #FFCA27; padding: 6px 12px; border: none; width: 70%;}
.find-jobs-btn:hover { color: #64748B; background-color: #FFC000;}
.apply-jobs-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s; border-radius: 8px; background: #FFF; padding: 6px 16px; border: none; width: 40%;}
.apply-jobs-btn:hover { color: #11274B; background-color: #E2E8F0;}
.search-job-border { border-bottom: 1px solid #CBD5E1; margin-bottom: 12px;}

.mobile-filter { background-color: #F1F8FE; padding: 94px 20px 12px 20px;}
.apply-mobile-text { color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 12px; margin-bottom: 12px;}