.help-page { background: #172742; height: 100vh; width: 100%; padding-top: 80px;}
.help-container { border-radius: 12px; background: rgba(255, 255, 255, 0.75); max-width: 980px; margin: auto; padding: 24px 0;}
.help-container .help-title { color: #262626; font-family: 'Inter', sans-serif; text-align: center; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 8px;}
.help-container .help-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.help-container .help-accordion-box { padding: 0 24px;}
.help-container .help-custom-accordion { padding: 16px 24px;border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; display: flex; flex-direction: column; height: 68px; justify-content: center; margin-bottom: 4px; transition: all 0.5s; width: 100%;}
.help-container .help-custom-accordion a { text-decoration: none;}
.help-container .help-custom-accordion:hover { background: #2E3D55;}
.help-custom-accordion .acc-up-img { background-image: url('../../assets/recruiter-dashboard/acc-arrow.svg'); height: 20px; width: 20px; transition: all 0.5s;}
.help-custom-accordion:hover .acc-up-img { background-image: url('../../assets/recruiter-dashboard/light-acc-arrow.svg');}
.help-custom-accordion .help-acc-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0; transition: all 0.5s;}
.help-custom-accordion:hover .help-acc-title { color: #fff;}
.contact-us-card { border-radius: 12px; width: 224px; height: 116px; border: 1px solid #E2E8F0; background: #FFF; display: flex; padding: 16px; flex-direction: column; align-items: flex-start; gap: 8px;}
.contact-us-card .contact-us-card-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 8px;}
.contact-us-card .contact-us-card-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}

@media(max-width: 480px) {
    .help-page { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); padding: 12px; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; padding-top: 104px; padding-bottom: 30px; background-attachment: fixed; overflow: hidden;}
    .help-container { padding: 16px 10px;}
    .help-container .help-title { font-size: 16px; line-height: 20px; margin-bottom: 4px;}
    .help-container .help-text { color: #262626; font-size: 10px; line-height: 16px; }
    .help-container .help-accordion-box { padding: 0;}
    .help-container .help-custom-accordion { padding: 12px; height: 44px;}
    .help-custom-accordion .help-acc-title { font-size: 14px; line-height: 18px; }
    .contact-us-card { width: 100%; flex-direction: row; height: 54px; padding: 8px 16px; gap: 12px;}
    .contact-us-card .contact-us-card-title { margin-bottom: 4px; font-size: 14px; line-height: 18px; }
}
/* Setting Page CSS Start */


/* Account and security Section CSS Start */

.setting-page { background: #172742; height: 100%; width: 100%; padding-top: 80px; padding-bottom: 80px;}
.setting-container { border-radius: 12px; background: rgba(255, 255, 255, 0.75); max-width: 980px; margin: auto; padding: 24px; display: flex; flex-direction: column; gap: 12px;}
.setting-container .setting-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; text-align: center; margin-bottom: 8px;}
.setting-container .setting-text {color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; width: 362px; margin: auto;}
.setting-option .setting-option-title { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; margin-bottom: 4px;}
.setting-option-box { border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px 24px; display: flex; justify-content: space-between; align-items: center; width: 460px;}
.setting-input { border-radius: 8px; border: 1px solid #808080; background: #FFF; padding: 8px 8px 8px 10px; display: flex; justify-content: space-between; align-items: center;}
.setting-input input { padding: 0; border: none; outline: none; color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.setting-option-box .setting-option-box-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 8px;}
.setting-option-box .setting-option-box-email { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.setting-option-box .setting-option-box-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.setting-option-box .setting-option-box-span { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 0;}
.setting-option-box .setting-change-btn { border-radius: 8px;  color: #2E3D55;  padding: 10px 0px; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; transition: all 0.5s; border: none; background-color: transparent;}
.setting-option-box .setting-change-btn:hover { background-color: #F8FAFC; color: #94A3B8;}
.setting-save-btn { width: 300px; border-radius: 8px; background: #FFCA27; padding: 16px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s;}
.setting-save-btn:hover { color: #64748B; background-color: #FFC000;}
.setting-mobile-change-btn { display: none;}
.setting-option-box-2 { border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px 24px; display: flex; justify-content: space-between; align-items: center; width: 460px; flex-direction: row;}
.setting-option-box-2 .setting-option-box-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 8px;}
.setting-option-box-2 .setting-option-box-email { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.setting-option-box-2 .setting-option-box-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.setting-option-box-2 .setting-option-box-span { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 0;}
.setting-delete-btn { width: 276px; border-radius: 8px; background-color: #F1F5F9; padding: 16px; color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s;}
.setting-delete-btn:hover { color: #11274B; background-color: #E2E8F0;}

.switch { font-size: 17px; position: relative; display: inline-block; width: 48px; height: 28px; }
.switch input { opacity: 1; width: 0; height: 0; }   
.slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0px; background: #cbd5e1; transition: .4s; border-radius: 30px; border: 1px solid #ccc; }   
.slider:before { position: absolute; content: ""; height: 24px; width: 24px; border-radius: 16px; left: 2px; top: 1.2px; bottom: 0; background-color: white; box-shadow: 0 2px 5px #999999; transition: .4s;}
input:checked + .slider { background-color: #ffc000; border: 1px solid transparent; }   
input:checked + .slider:before { transform: translateX(18px); }

@media(max-width: 480px) {
    .setting-page { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); padding: 12px; height: 100%; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; padding-top: 104px; padding-bottom: 30px; overflow: hidden;}
    .setting-container { max-width: 980px; padding: 16px 12px;}
    .setting-container .setting-title { font-size: 16px; line-height: 20px; margin-bottom: 4px;}
    .setting-container .setting-text { font-size: 10px; width: 327px;}
    .setting-option-box { border-radius: 8px; padding: 8px 12px;}
    .setting-option-box .setting-option-box-title { font-size: 14px; line-height: 18px; margin-bottom: 8px;}
    .setting-option-box .setting-option-box-email {display: none;}
    .setting-option-box .setting-option-box-text { width: 208px; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
    .setting-option-box .setting-option-box-span { font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 0;}
    .setting-option-box-2 { border-radius: 8px; padding: 12px; flex-direction: column; align-items: start;}
    .setting-option-box-2 .setting-option-box-title { font-size: 14px; line-height: 18px; margin-bottom: 4px;}
    .setting-option-box-2 .setting-option-box-email {display: none;}
    .setting-option-box-2 .setting-option-box-text { font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0; margin-bottom: 8px;}
    .setting-option-box-2 .setting-option-box-span { font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 0;}
    .setting-save-btn {display: none;}
    .setting-mobile-change-btn { height: 36px; display: block; width: 100%; border-radius: 8px; background: #FFCA27; padding: 6px 12px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; transition: all 0.5s;}
    .setting-mobile-change-btn:hover { color: #64748B; background-color: #FFC000;}
    .setting-delete-btn { width: unset; border-radius: 8px; background-color: transparent; padding: 6px 12px; color: #64748B; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; transition: all 0.5s;}
    .setting-delete-btn:hover { color: #94A3B8; background-color: #F8FAFC;}
}

/* Account and security Section CSS End */


/* FAQ CSS Start */

.faq-page { background: #172742; height: 100vh; width: 100%; padding-top: 80px;}
.faq-container { border-radius: 12px; background: rgba(255, 255, 255, 0.75); max-width: 980px; margin: auto; padding: 24px 0;}
.faq-container .faq-title { color: #262626; font-family: 'Inter', sans-serif; text-align: center; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 8px;}
.faq-container .faq-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.faq-container .faq-accordion-box { padding: 0 24px;}
.faq-container .faq-custom-accordion { padding: 16px 24px;border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; margin-bottom: 4px; transition: all 0.5s; display: flex; flex-direction: column; gap: 8px;}
.faq-container .faq-custom-accordion a { text-decoration: none;}
.faq-custom-accordion .faq-custom-accordion-box { height: 32px; display: flex; align-items: center; justify-content: space-between;}
.faq-container .faq-custom-accordion:hover { background: #2E3D55 !important;}
.faq-custom-accordion .acc-up-img { background-image: url('../../assets/recruiter-dashboard/down-arrow.svg'); height: 32px; width: 32px; transition: all 0.5s;}
.faq-custom-accordion .img-active { background-image: url('../../assets/recruiter-dashboard/light-down-arrow.svg'); height: 32px; width: 32px; transition: all 0.5s; transform: rotate(0deg) !important;}
.faq-custom-accordion:hover .acc-up-img { background-image: url('../../assets/recruiter-dashboard/light-down-arrow.svg'); transform: rotate(180deg);}
.faq-custom-accordion .faq-acc-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0; transition: all 0.5s;}
.faq-custom-accordion .faq-acc-content { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0; transition: all 0.5s;}
.faq-custom-accordion:hover .faq-acc-title { color: #fff !important;}
/* .contact-us-card { border-radius: 12px; width: 224px; height: 116px; border: 1px solid #E2E8F0; background: #FFF; display: flex; padding: 16px; flex-direction: column; align-items: flex-start; gap: 8px;} */
.prev-page { padding-left: 24px;}

@media(max-width: 480px) {
    .faq-page { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); padding: 12px; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; padding-top: 104px; padding-bottom: 30px; background-attachment: fixed;}
    .faq-container { max-width: 980px; padding: 16px 12px;}
    .faq-container .faq-title { font-size: 16px; line-height: 20px; margin-bottom: 4px; text-align: left;}
    .faq-container .faq-text { font-size: 10px; width: 327px; text-align: left;}
    .faq-container .faq-accordion-box { padding: 0px;}
    .faq-container .faq-custom-accordion { padding: 12px;}
    .faq-custom-accordion .faq-custom-accordion-box { height: 18px;}
    .prev-page { padding-left: 0px;}
}

/* FAQ CSS End */


/* Privacy Policy CSS Start */

.privacy-page { background: #172742; height: 100%; width: 100%; padding-top: 80px; padding-bottom: 80px;}
.refund { background: #172742; height: 100vh; width: 100%; padding-top: 80px; padding-bottom: 80px;}
.grievance-card { border-radius: 12px; background: #64748B; padding: 8px 12px; display: flex; align-items: flex-start; width: 286px;}
.grievance-card .grievance-card-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 4px;}
.grievance-card .grievance-card-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}

@media(max-width: 480px) {
    .refund { height: 100%; }
    .privacy-page { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); padding: 12px; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; padding-top: 104px; padding-bottom: 30px; overflow: hidden; background-attachment: fixed;}
}

/* Privacy Policy CSS End */

/* Write Us CSS Start */

.write-page { background: #172742; height: 100vh; width: 100%; padding-top: 80px; padding-bottom: 80px;}
.write-container { border-radius: 12px; background: rgba(255, 255, 255, 0.75); max-width: 980px; margin: auto; padding: 24px 0;}
.write-container .faq-title { color: #262626; font-family: 'Inter', sans-serif; text-align: center; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 8px;}
.write-container .faq-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}
.write-container .faq-accordion-box { padding: 0 24px;}
.write-container .faq-custom-accordion { padding: 16px 24px;border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; margin-bottom: 4px; transition: all 0.5s; display: flex; flex-direction: column; gap: 8px;}
.write-container .faq-custom-accordion a { text-decoration: none;}

.help-modal-input { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color:  #FFF; width: 300px; text-transform: capitalize; transition: all 0.5s;}
.help-modal-input:hover { color: #94A3B8; border: 1px solid #94A3B8;}
.help-modal-input:focus { outline: 2px solid #94A3B8;}

.help-back-btn { width: 128px; border-radius: 8px; background-color: #F1F5F9; padding: 16px; color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s; margin-right: 16px;}
.help-back-btn:hover { color: #11274B; background-color: #E2E8F0;}
.help-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(68, 81, 103, 0.40); z-index: 1000; overflow: scroll; }
.help-modal-box { border-radius: 12px; background: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 250px 0px rgba(255, 255, 255, 0.15); display: flex; width: 523px; padding: 40px 32px; flex-direction: column; align-items: center; gap: 32px; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.help-modal-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 20px;}
.help-modal-text { color: #F8FAFC; text-align: center; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; margin-bottom: 0;}
.help-close-btn { border-radius: 1000px; background: #CBD5E1; padding: 6px 12px; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 156px; border: none;}
.help-input-container { padding: 0 24px;}
.help-input-items { display: flex; flex-wrap: wrap; gap: 16px;}
.help-input-container textarea { margin-top: 16px !important; margin-bottom: 16px !important;}


@media(max-width: 480px) {
    .write-page { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; padding-top: 94px; padding-bottom: 30px;}
    .write-container { max-width: 980px; padding: 16px 12px; margin: 0 12px;}
    .write-container .faq-title { font-size: 16px; line-height: 20px; margin-bottom: 4px;}
    .write-container .faq-text { font-size: 10px; width: 327px;}
    .write-container .faq-accordion-box { padding: 0px;}
    .write-container .faq-custom-accordion { padding: 12px;}
    .faq-custom-accordion .faq-custom-accordion-box { height: 18px;}
    .help-back-btn { display: none;}
    .help-modal-input { width: 100%;}
    .help-input-container { padding: 0;}
    .help-input-items { gap: 12px;}
    .help-input-container textarea { margin-top: 12px !important; margin-bottom: 0 !important;}
    .help-modal { margin: 0 8px;}
    .help-modal-box {width: unset; padding: 24px 12px; gap: 16px;}
    .help-modal-title { margin-bottom: 12px;}
    .help-modal-text { font-size: 12px; }
}

/* Write Us CSS End */

/* logout CSS Start */


.logout-page { background-color: #172742; position: relative; height: 100vh; width: 100%;}
.logout-page .logout-box { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: inline-flex; padding: 40px 32px; flex-direction: column; align-items: center; gap: 40px; border-radius: 12px; background: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 250px 0px rgba(255, 255, 255, 0.15); width: 480px;}
.logout-box .logout-text { color: #F8FAFC; text-align: center; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 400; line-height: 22px; margin: 0;}
.logout-box .logout-btn-box { display: flex; flex-direction: row; width: 100%; gap: 16px;}
.logout-btn-box .logout-btn { width: 100%; border-radius: 8px; background-color: #F1F5F9; padding: 16px; color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s; }
.logout-btn-box .logout-btn:hover { color: #11274B; background-color: #E2E8F0;}
.logout-btn-box .logout-back-btn {  width: 100%; border-radius: 8px; background: #FFCA27; padding: 16px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; transition: all 0.5s;}
.logout-btn-box .logout-back-btn:hover { color: #64748B; background-color: #FFC000;}

@media(max-width: 480px) {
    .logout-page .logout-box { padding: 40px 20px; gap: 40px; width: 95%;}
    .logout-box .logout-btn-box { flex-direction: column; gap: 12px;}
    .logout-btn-box .logout-btn { padding: 6px 12px;}
    .logout-btn-box .logout-back-btn { padding: 6px 12px;}
}

/* logout CSS End */

.bug-report-input { border-radius: 8px; border: 1px solid #808080; background: #FFF; width: 96%; margin: 0 20px; padding: 8px 10px; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; transition: all 0.5s;}
.bug-report-input:focus { outline: 2px solid #94A3B8;}
.bug-report-input:hover { border: 1px solid #94A3B8; color: #94A3B8;}


.report-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1000;}
.report-modal .report-overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; opacity: 0.5; background: #172742;}
.report-box { display: flex; width: 523px; padding: 40px 32px; flex-direction: column; align-items: center; gap: 32px; border-radius: 12px; background: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 250px 0px rgba(255, 255, 255, 0.15); position: relative; transform: translate(-50%, -50%); top: 50%; left: 50%;}
.report-modal-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 12px;}
.report-modal-text { color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; }
.close-btn- { display: flex; width: 156px; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; border-radius: 1000px; background: #CBD5E1; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; }

@media(max-width: 480px) {
    .bug-report-input { border-radius: 8px; border: 1px solid #808080; background: #FFF; width: 94%; margin: 12px 12px 0 12px;}
    .report-box { width: 300px; padding: 24px 12px; flex-direction: column; align-items: center; gap: 32px; border-radius: 12px; background: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 250px 0px rgba(255, 255, 255, 0.15); position: relative; transform: translate(-50%, -50%); top: 50%; left: 50%;}
}

.custom-modal-overlay { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);  justify-content: center; align-items: center; z-index: 1060;} 
.custom-modal { position: absolute; top: 50%; left: 50%; background-color: white; padding: 12px; border-radius: 5px; width: 500px; overflow-y: auto; transform: translate(-50%, -50%); overflow: hidden;}
.close-button { background: none; border: none; cursor: pointer; position: absolute; top: 10px; right: 10px; }  
.modal-content { background-color: #FFF; padding: 20px; margin: 0 20px; overflow: hidden;}
.sign-input { width: 100%; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 18px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color: #FFF; outline: #000; transition: all 0.9s; position: relative;}
.sign-up-btn { border-radius: 8px; background-color:  #FFCA27; width: 100%; padding: 12px 0; border: none; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; display: flex; align-items: center; justify-content: center; transition: all 0.5s; margin-top: 16px;}
.sign-up-btn:hover { color: #64748B; background-color: #FFC000;} 