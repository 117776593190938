.sub-header-container { padding-top: 80px; background-color: #2E3D55;}
.sub-header { height: 55px; margin: auto; flex-shrink: 0; padding: 0 80px; display: flex; justify-content: space-between; align-items: center; flex-direction: row;}
.sub-header-dashboard-btn { color: #94A3B8; font-family: 'Inter'; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; background-color: transparent; border: none; padding: 6px; text-decoration: none; display: flex; align-items: center;}
.sub-header-right-btn { padding: 6px; background-color: transparent; border: none; color: #94A3B8; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.sub-active { border-radius: 4px; background: #64748B; padding: 6px; color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border: none; text-decoration: none; }

@media(max-width: 1474px) {
    .sub-header { height: 55px; margin: auto; flex-shrink: 0; padding: 0 60px; display: flex; justify-content: space-between; align-items: center;}
}
@media(max-width: 1024px) {
    .sub-header { height: 55px; margin: auto; flex-shrink: 0; padding: 0 85px; display: flex; justify-content: space-between; align-items: center;}
}
@media(max-width: 1000px) {
    .sub-header-container { padding-top: 65px; background-color: #2E3D55;}
    .sub-header { height: auto; margin: auto; flex-shrink: 0; padding: 0 85px; display: flex; flex-direction: column; justify-content: space-between; align-items: center; gap: 10px;}
}


@media(max-width: 480px) {
    .sub-active { background: #64748B; padding: 10px 12px; width: 100%;}
    .sub-header-dashboard-btn { padding: 10px 12px; width: 100%;}
}

