footer  { position: relative; }
footer .footer-wrapper { background-image: url('../../assets/footer-wrapper.svg'); height: 574px; width: 810px; object-fit: cover; background-size: cover; background-repeat: no-repeat; overflow: visible; position: absolute; z-index: -1; top: -50px;}
.footer-content { display: flex; justify-content: space-between; align-items: center; padding: 50px 75px 0px 170px;  position: relative; z-index: 100;}
.footer-content .social-media { padding-top: 55px; }
.footer-content .social-media .footer-title {color: #808080; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; margin-bottom: 24px;}
.footer-content .social-media a { display: block; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; text-decoration: none; margin-bottom: 16px;}
.footer-content .follow-media { padding-top: 49px; }
.footer-content .follow-media .footer-title {color: #808080; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; margin-bottom: 24px;}
.footer-content .follow-media a { display: block; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; text-decoration: none; margin-bottom: 16px;}
.footer-content .social-btn { display: flex; gap: 16px;}
.footer-content .social-btn a { display: inline-block; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px; text-decoration: none; margin-bottom: 0;}
.footer-content .footer-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 40px; margin: 0;}
.footer-content .footer-text a { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; text-decoration-line: underline;}
footer .footer-logo { position: relative; max-width: 284px;}
footer .footer-logo .footer-logo-title { padding-top: 32px; color: #808080; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 400; line-height: 26px;}


@media(max-width: 780px) {
    footer { overflow: hidden;}
}
@media(max-width: 480px) {
    footer { position: relative; }
    footer .footer-wrapper { position: absolute; background-image: url('../../assets/footer-mobile.svg'); height: 346px; max-width: 455px; object-fit: cover; background-size: cover; background-repeat: no-repeat; z-index: -1; top: -20px !important; left: -68px;}
    .footer-content { display: flex; justify-content: space-between; flex-direction: column; align-items: center; padding: 0px; }
    .footer-content .follow-media { padding-top: 0px; padding-bottom: 20px;}
    .footer-content .social-media { padding-top: 40px; max-width: 246px;  margin: auto;}
    .footer-content .social-btn a { margin-bottom: 4px; margin-right: 4px;}
    .footer-content .footer-text {padding-left: 0px; font-size: 8px; padding-top: 20px; text-align: center;}
    .footer-content .social-btn { gap: 8px;}
    footer .footer-logo { display: none;}
    .footer-content .social-media .footer-title { font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 12px;}
    .footer-content .follow-media .footer-title { font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 12px;}
    .footer-content .social-media a { font-size: 12px; font-weight: 400; line-height: 16px; margin-bottom: 12px;}
    .footer-content .follow-media a { font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; text-decoration: none; margin-bottom: 0px; margin-left: 0;}
    .footer-content .footer-text a { font-size: 8px; text-decoration-line: underline;}
}