.haAclf { border-radius: 1000px !important; background: #E2E8F0 !important; width: 100% !important; height: 36px !important;}

    /* Login Page CSS */

    .login-hero { display: flex; justify-content: center; align-items: center; background-image: url('../../../assets/login/login-hero.svg'); height: 100vh; width: 100%; background-size: cover; object-fit: cover; background-repeat: no-repeat;}


.login-hero .sign-up { border-radius: 12px; background: rgba(255, 255, 255, 0.95); padding: 24px; margin-left: 93px; max-width: 410px; display: flex; flex-direction: column; gap: 12px; width: 100%;}
.login-hero form { display: flex; flex-direction: column; gap: 12px;}
.login-hero .form-border { border-bottom: 1px solid #C7D2FE; margin: 12px 0;}
.login-hero .sign-up .sign-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 20px; font-style: normal; font-weight: 600; line-height: 26px; margin-bottom: 8px;}
.login-hero .sign-up .sign-text { color: #262626; text-align: center; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.login-hero .sign-up .google-btn { border-radius: 1000px; background: #E2E8F0; width: 100%; padding: 8px 0; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; border: none;}
.login-hero .sign-up .sign-input { width: 100%; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 18px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background-color: #FFF; outline: #000; transition: all 0.9s; position: relative;}
.login-hero .sign-up .sign-input-error { width: 100%; color: #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 18px; padding: 10px 12px; border-radius: 8px; border: 1px solid #FB7185; background-color: #FFF; outline: #000; transition: all 0.9s; position: relative;}
.login-hero .sign-up .input-error-text { color: #E11D48; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; text-align: left; text-transform: capitalize; padding-top: 4px;}
.login-hero .sign-up .show-pass { position: relative; }
.error-input-text { color: #E11D48; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; text-align: left;}
.login-hero .sign-up .show-pass .show-eyes { position: absolute; right: 0px; top: 50%; transform: translate(-50%, -50%);}
.login-hero .sign-up .sign-input:focus { border-radius: 8px; border: 1px solid #808080; background-color: #FFF; color: #262626; outline: 2px solid #94A3B8; }

.login-hero .sign-up .sign-input:hover { border: 1px solid #94A3B8; color: #94A3B8 !important; }
.login-hero .sign-up .sign-input:focus { border-radius: 8px; border: 1px solid #808080; background-color: #FFF; color: #262626; outline: 2px solid #94A3B8; }
.login-hero .sign-up .sign-up-btn { border-radius: 8px; background-color:  #FFCA27; width: 100%; padding: 16px 0; border: none; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; display: flex; align-items: center; justify-content: center; transition: all 0.5s;}
.login-hero .sign-up .sign-up-btn:hover { color: #64748B; background-color: #FFC000;} 
.login-hero .sign-up .link-btn { color: #64748B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 24px; text-decoration: none;}
.login-hero .sign-up .link-btn a { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 700; line-height: 16px; text-decoration-line: underline;}

@media(max-width: 768px) {
    .login-hero { background-image: url('../../../assets/login/login-hero.svg'); height: 100vh; width: 100%; background-size: cover; object-fit: cover; background-repeat: no-repeat; background-position: center;}
    .login-container { display: flex; align-items: center; padding-top: 82px; flex-direction: column; padding-bottom: 42px;}
    .login-hero .sign-up { margin-left: 0; }
}
@media(max-width: 480px) {
    .login-hero { height: 100vh;}
    .login-hero .sign-up .link-btn { padding: 0; }
    .login-hero .sign-up { padding: 16px; margin-left: 20px; margin-right: 20px; max-width: 350px;}
    .login-container { display: flex; align-items: center; padding-top: 63px; flex-direction: column; padding-bottom: 42px;}
    .login-hero .login-content { padding-left: 40px; padding-right: 20px;}
    .login-content .login-title { font-size: 24px; text-align: center; font-weight: 600; line-height: 28px; margin-bottom: 0;}
    .login-content .span { font-size: 24px; font-weight: 600; line-height: 28px; text-align: center; margin-bottom: 27px;}
    .login-hero .sign-up .sign-title { font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 4px;}
    .login-hero .sign-up .sign-text { font-size: 10px; font-weight: 400; line-height: 16px; padding-bottom: 12px; margin: 0;}
    .login-hero .sign-up .sign-input { margin: 0 0 12px 0; padding: 10px 12px;}
    .login-hero .sign-up .sign-up-btn { padding: 8px 0; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 12px;}
}
@media(max-width: 350px) {
    .login-hero .sign-up { padding: 16px; margin-left: 20px; margin-right: 20px; max-width: 320px;}
}

.forget-text { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; text-decoration: none; text-align: left; margin-top: -8px;}