/* .dropdown { z-index: 1112;} */
.dropdown-content {
    display: none;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .dropdown-content.open {
    display: block;
  }
  
  .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .option.selected,
  .option:hover {
    background-color: #f2f9fc;
    color: #333;
  }
  

  .styled-select { height: 44px; overflow: hidden; overflow: -moz-hidden-unscrollable;  border-radius: 8px; border: 1px solid #808080; background: url('../../assets/select-down.svg') no-repeat right 8px top 12px white;  display: inline-block; position: relative;  margin-bottom: 10px; width: 100%;}
  .styled-select:hover {
    background: url('../../assets/mobile/down-arrow-hover.svg') no-repeat right 8px top 12px white; border: 1px solid #94A3B8;
  }
  
  .styled-select .custom-select {
    padding: 10px 8px 10px 12px; 
    height: 44px;
    width: 100%;
    border-radius: 8px; border: none;
    outline: none;
    background: transparent;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    top: 0; color:  #808080; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; 
  }
  
    .styled-select .custom-select:hover { color: #94A3B8; }
    .custom-select option { background: rgba(241, 248, 254, 1);  margin: 40px; border-radius: 8px; padding: 9px 16px; color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; position: relative;}
    /* .custom-select option:checked,
    .custom-select option:hover { background: rgba(255, 255, 255, 1); } */
    .select-overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: #000; height: 100vh; width: 100%; z-index: 1111; opacity: 0;}
    .custom-select:focus option:hover { background: rgba(255, 255, 255, 1); }
    /* .custom-select option:not(:checked) {
      background: #fff;
    } */

    
.input-container { border: 1px solid #808080; border-radius: 8px; background: #FFFFFF; padding: 10px 12px; }
.input-container input { color: #808080; font-size: 16px; font-weight: 400; font-family: Inter;}