/* Recruiter Dashboard CSS Start */

.recruiter-dashboard { background-color: #F1F5F9; padding: 24px 84px; display: flex; flex-direction: column; gap: 20px;}
.recruiter-dashboard-container { display: flex; flex-direction: row; gap: 16px;}
.chart-container { display: flex; flex-direction: column; gap: 16px; width: 30%;}
.chart-box { border-radius: 16px; background: rgba(199, 210, 254, 0.25); display: flex; padding: 20px; flex-direction: column; align-items: flex-start; gap: 16px;}
.chart-box .chart-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 0;}
.chart-round { height: 130px; width: 130px; position: relative;}
.chart-text { display: flex; gap: 17px; flex-direction: column;}
.total-box { display: flex; align-items: center;}
.total-box .box { border-radius: 2px; background: #FFC000; height: 10px; width: 10px; margin-bottom: 0; margin-right: 10px;}
.total-box p {color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}
.open-box { display: flex; align-items: center;}
.open-box .box { border-radius: 2px; background: #026D92; height: 10px; width: 10px; margin-bottom: 0; margin-right: 10px;}
.open-box p {color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}
.closed-box { display: flex; align-items: center;}
.closed-box .box { border-radius: 2px; background: #CBD5E1; height: 10px; width: 10px; margin-bottom: 0; margin-right: 10px;}
.closed-box p {color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}
.selected-box { display: flex; align-items: center;}
.selected-box .box { border-radius: 2px; background: #FFEAD7; height: 10px; width: 10px; margin-bottom: 0; margin-right: 10px;}
.selected-box p {color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}


.interviewers { display: flex; width: 30%; height: 434px; padding: 12px; flex-direction: column; gap: 8px; flex-shrink: 0; border-radius: 16px; background: #FFF; overflow-y: scroll;}
.interviewers::-webkit-scrollbar { width: 4px; }
/* .interviewers::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  } */
.interviewers::-webkit-scrollbar-thumb { background: #D9D9D9; border-radius: 100px;}
.interviewers-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.interview-accordion { padding: 4px 8px; border-left: 1px solid #026D92; background: #FFF; display: flex; gap: 8px; flex-direction: column; width: 100%;}
.interview-accordion .acc-box { display: flex; flex-direction: column; gap: 4px;}
.interview-accordion .acc-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.interview-accordion .interview-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.interview-accordion .interview-text-number { color: #026D92; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 14px; text-transform: uppercase;}
.interview-mobile-box { padding: 4px 8px; border-radius: 4px; border: 1px solid #DBD9D9; background: #FFF; width: 204px;}


@media(max-width: 480px) {
    .interviewers { display: block; width: 311px; height: auto; padding-bottom: 30px; overflow: hidden;}
    .interview-mobile-box .acc-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 4px;}
    .interview-mobile-box .interview-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 4px !important;}
    .interview-mobile-box .interview-text-number { color: #026D92; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 14px; text-transform: uppercase; margin-bottom: 4px !important;}
    .chart-box { width: 294px;}
}

.recruiter-calender { display: flex; flex-direction: column; gap: 16px; width: 40%;}
.recruiter-calender .calender-  { padding: 12px 16px; flex-direction: column; align-items: center; gap: 16px; align-self: stretch; border-radius: 16px; background: #FFF; height: 182px;}
.calender .calender-box-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0;}
.calender- .calender-btn { border-radius: 4px; background: #FFEAD7; display: flex; padding: 4px 6px; align-items: center; gap: 6px; border: none;color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px;  }
.upcoming  { display: flex; padding: 12px 16px; flex-direction: column; align-items: center; gap: 16px; align-self: stretch; border-radius: 16px; background: #FFF; height: 236px;}
.upcoming .upcoming-box-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0;}

.recruiter-draft { display: flex; padding: 20px 24px; flex-direction: column; align-items: flex-start; gap: 16px; align-self: stretch; border-radius: 12px; background: #FFF;}
.view-all-btn-recruiter { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; background-color: transparent; border: none;}
.recruiter-chart { display: none;}

.upcoming-card { border-radius: 8px; border: 1px solid #F1F5F9; display: flex; padding: 8px 12px; align-items: flex-start; gap: 8px; align-self: stretch;}
.upcoming-card .upcoming-card { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.upcoming-card .upcoming-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px;}
.upcoming-card .upcoming-title { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px;}
.upcoming-card .upcoming-category { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; }
.upcoming-card .upcoming-date { color:#4D4D4D; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px;}

@media(max-width: 480px) {
    .recruiter-dashboard { padding: 8px 20px; flex-direction: column; gap: 8px;}
    .recruiter-chart { display: block; padding: 12px 0 20px 20px; margin-top: 84px; background-color: #F1F5F9;}
    .recruiter-dashboard-container { flex-direction: column;}
    .interviewers { width: 100% !important;}
    .recruiter-calender .calender- { width: 100% !important;}
    .upcoming { width: 100% !important; display: block; height: 132px;}
    .recruiter-calender { display: flex; flex-direction: column; gap: 8px; width: 100%;}
    .upcoming-card { width: 208px;}
    .upcoming .upcoming-box-title { font-size: 14px; }
    .upcoming-slider { margin-top: 12px;}
    .recruiter-draft { display: block; padding: 20px 0 30px 20px; border-radius: 0px; }
}
.feedback-container { display: flex; flex-direction: row; gap: 17px; overflow: hidden;}
.feedback-container .feedback-box { display: flex; width: 50%; padding: 20px; flex-direction: column; align-items: flex-start; gap: 16px; align-self: stretch; border-radius: 16px; background: #FFEAD7;}
.feedback-container .feedbacks-box { display: flex; flex-direction: row;}
.feedback-box .feedback-box-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px;}
.recruiter-feedback-card { display: flex; width: 256px; padding: 12px; flex-direction: column; align-items: flex-start; gap: 8px; flex: 1 0 0; border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF;}
.recruiter-feedback-card .title { color: #808080; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0;}
.recruiter-feedback-card .rating { margin: 0; color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; text-transform: uppercase;}
.recruiter-feedback-card .center-border { border-radius: 8px; border-bottom: 1px solid #E2E8F0; background: #FFF; width: 100%;}
.rating-item {display: flex; flex-direction: row; gap: 8px; align-items: center;}
.rating-item .rating-num { padding-left: 4px; color: #FFC000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; }
.rating-item .rating-name { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.rating-item .rating-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}
.recruiter-feedback-card .rating-comment { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}

@media(max-width: 480px) {
    .feedback-container { display: block; }
    .feedback-container .feedback-box { display: block; width: 100%; padding: 16px 0 34px 16px;}
    .feedback-container .feedbacks-box {flex-direction: column;}
    .feedback-slider { margin-top: 12px;}
    .recruiter-feedback-card { width: 274px; }
}
.subscribe-box { padding: 20px 0px; padding-bottom: 34px; border-radius: 16px; background: rgba(17, 39, 75, 0.95); box-shadow: 0px 4px 40px 0px rgba(255, 255, 255, 0.20); width: 50%; overflow: hidden;}
.subscribe-box .title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; margin: 0;}
.radio-title { display: inline-block; padding-left: 30px; position: relative; font-size: 16px; color: #FFF; font-family: 'Inter', sans-serif; font-style: normal; font-weight: 400; line-height: 24px; cursor: pointer; transition: all 0.3s ease; }
.radio-button__input:checked + .radio-title .radio-button__custom { background-color: #FFF; border: 6px solid  #FFC000; box-shadow: 0 0 20px #4c8bf580; }
.upgrade-card-one { border-radius: 12px; background: rgba(71, 85, 105, 0.80); padding: 20px; width: 254px; height: 422px;}
.upgrade-card-two { border-radius: 12px;background: rgba(100, 116, 139, 0.60); padding: 20px; width: 254px; height: 422px;}
.upgrade-card-three { border-radius: 12px; background-color: #2E3D55; padding: 20px; width: 254px; height: 422px;}
.upgrade-card-four { border-radius: 12px; background: rgba(148, 163, 184, 0.50); padding: 20px; width: 254px; height: 422px;}
.upgrade-box-title { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.upgrade-box-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; text-transform: capitalize; }
.upgrade-card-two .upgrade-box-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.upgrade-card-four .upgrade-box-title { color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.upgrade-card-two .upgrade-box-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; text-transform: capitalize; }
.upgrade-box-border-one { border: 1px solid #94A3B8; margin: 8px 0; width: 100%;}
.upgrade-box-border-two { border: 1px solid #94A3B8; margin: 8px 0; width: 100%;}
.upgrade-box-border-three { border: 1px solid #64748B; margin: 8px 0; width: 100%;}
.upgrade-box-border-four { border: 1px solid #CBD5E1; margin: 8px 0; width: 100%;}
.upgrade-card-one-bold { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 12px; display: flex;}
.upgrade-card-one-text { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px; padding-left: 6px;}
.upgrade-card-two .upgrade-card-one-bold { color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 12px; display: flex;}
.upgrade-card-four .upgrade-card-one-bold { color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 12px; display: flex;}
.upgrade-card-two .upgrade-card-one-text { color: #FFF; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 12px; padding-left: 6px;}
.upgrade-starter-btn { border-radius: 8px; background-color: #E2E8F0; height: 24px; padding: 6px 12px; color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 100%; text-align: center; display: flex; align-items: center; justify-content: center;}
.upgrade-basic-btn { border-radius: 8px; background-color: #FFCA27; padding: 6px 12px; height: 24px; display: flex; justify-content: center; align-items: center; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 100%; text-align: center;}
.extra-one { color: #64748B; text-align: center; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 600; line-height: 10px; border-radius: 12px; background: #FFEAD7; display: flex; padding: 4px; justify-content: flex-end; align-items: center; gap: 10px; border: none; margin-left: 12px;}

@media(max-width: 480px) {
    .subscribe-box { width: 100%; border-radius: 0; padding-bottom: 30px;}
}
.subscribe-slider .slick-dots { bottom: -25px !important; }
.subscribe-slider .slick-dots li { margin: 0 !important;}
.subscribe-slider .slick-dots li button { width: 0; height: 0; padding: 0; transition: all 0.5s;}
.subscribe-slider .slick-dots li button:before { width: 3px; height: 3px; background-color: #64748B; opacity: 1; color: transparent;}
.subscribe-slider .slick-dots li.slick-active button:before { width: 16px; height: 3px; background-color: #FFF; border-radius: 25px; opacity: 1; color: transparent; transform: translate(-7px, 0);}

.information-card { border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; display: flex; padding: 8px 12px; flex-direction: column; align-items: flex-start; gap: 8px; width: 244px;}
.information-card .information-sm-btn { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding: 2px 6px; border-radius: 4px; background: #F1F5F9;}
.information-card .information-hour { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.information-card .course-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 2px;}
.information-card .course-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.information-card .course-cart-text-one { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.information-card .course-enroll-btn { border-radius: 8px; background: #0D9488; display: flex; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border: none; width: 100%;}

.course-container { padding: 50px 20px 50px 85px; background: rgba(255, 255, 255, 0.90); overflow: hidden;}
.course-container .course-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.course-container .course-card { width: 248px; border-radius: 12px; border: 1px solid #E2E8F0; background: #FFF; padding: 16px; height: 346px; position: relative;}
.course-container .course-card .course-img { height: 89px; border-radius: 8px; border: 1px solid #F1F5F9; background: lightgray 0px 0px / 100% 98.876% no-repeat; width: 100%; object-fit: cover; background-size: cover;}
.course-card .view-more-btn { display: flex; max-width: 220px; height: 36px; padding: 6px 12px; justify-content: center; align-items: center; gap: 6px; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; border: none; background: transparent; margin: 0; display: flex; border-radius: 8px; background: #0D9488; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; position: absolute; bottom: 12px; left: 50%; transform: translate(-50%, 0%);}
.course-btn { color: #F8FAFC; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding: 0px 4px; border-radius: 4px; background: #64748B; border: none; margin-bottom: 2px;}
.course-content-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 2px; margin-top: 6px;}
.course-content-text {color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.course-border { margin: 6px 0; border-bottom: 1px solid #F1F5F9;}
.article-container { padding: 50px 20px 50px 85px; background: rgba(226, 232, 240, 0.80); overflow: hidden;}
.article-container .article-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 20px;}
.article-card { border-radius: 12px; background: #FFF; box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10); padding: 16px; width: 205px; transition: all 0.5s; height: 334px;}
.article-card:hover { background: #11274B; }
.article-card .article-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; transition: all 0.5s; margin-bottom: 8px;}
.article-card .article-text { text-transform: capitalize; color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; transition: all 0.5s; margin: 0;}
.article-card .article-read-more { color: #172742; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; transition: all 0.5s; border: none; background-color: transparent; padding: 0; border-bottom: 1px solid transparent;}
.article-card .article-read-more:hover { border-bottom: 1px solid #fff;}
.article-card:hover .article-title { color: #FFF; }
.article-card:hover .article-text { color: #FFF; }
.article-card:hover .article-read-more { color: #FFF; }

@media(max-width: 480px) {
    .course-container { padding: 20px 0 30px 20px;}
    .article-container { padding: 20px 0 30px 20px;}
}
/* Recruiter Dashboard CSS End */


.recruiter-container { background-color: #F1F5F9; padding: 24px 120px 53px 120px; overflow: hidden; }
.new-job-container { border-radius: 24px; background: #FFF; padding: 24px 20px; display: flex; flex-direction: column; gap: 16px;}
.new-job-container .new-job-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin: 0;}
.custom-accordion { padding: 20px 24px; border-radius: 24px; border: 1px solid #CBD5E1; background: #FFF; display: flex; gap: 12px; flex-direction: column;}
.custom-accordion .acc-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.custom-accordion .acc-text { color: #000; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.acc-box { transition: all 0.5s; }
.acc-box .acc-input { display: flex; flex-direction: column; margin-bottom: 12px; transition: all 0.5s;}
.acc-input label { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 4px;}
.acc-input input { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background: #FFF; outline: none;}
.new-job-add-btn { border: none; background-color: transparent; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 0; display: flex; align-items: center;}
.clear-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background: #F1F5F9; border: 0; padding: 16px; width: 280px; transition: all 0.5s; margin-right: 24px;}
.clear-btn:hover { color: #11274B; background-color: #E2E8F0;}
.preview-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border-radius: 8px; background: #FFCA27; border: 0; padding: 16px; width: 280px; transition: all 0.5s;}
.preview-btn:hover { color: #64748B; background-color: #FFC000;}
.draft-container { border-radius: 24px; background: #FFF; padding: 24px 20px; display: flex; flex-direction: column; gap: 16px; margin-top: 16px; position: relative; z-index: 1;}
.draft-container .new-job-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin: 0;}
.acc-input textarea { color: #262626; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; padding: 10px 12px; border-radius: 8px; border: 1px solid #808080; background: #FFF; outline: none;}
.acc-input textarea::-webkit-scrollbar { width: 5px; }
.acc-input textarea::-webkit-scrollbar-track { background: transparent; }
.acc-input textarea::-webkit-scrollbar-thumb { background: #94A3B8; border-radius: 10px;}

.draft-boxes { display: flex; flex-direction: row; gap: 16px; flex-wrap: wrap;}
.draft-card { display: flex; flex-direction: column; gap: 12px; border-radius: 12px; border: 1px solid #F1F5F9; background: #FFF; width: 252px; padding: 16px; position: relative;}
.draft-card .draft-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 2px;}
.draft-card .draft-card-text {color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}
.draft-card .draft-days { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; margin: 0;}
.draft-card .draft-list { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; padding-left: 14px; margin: 0;}
.draft-card .draft-edit-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 100%; border-radius: 8px; background: #F1F5F9; padding: 6px 12px; transition: all 0.5s; text-align: center; border: none;}
.draft-card .draft-edit-btn:hover { color: #11274B; background-color: #E2E8F0;}
.draft-card .draft-sub-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.draft-card .draft-skills { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-right: 12px !important;}
.draft-card .draft-mange-btn { border-radius: 8px; color: #64748B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border: none; background-color: transparent; width: 100%; text-align: center; padding: 6px 12px; transition: all 0.5s;}
.draft-card .draft-mange-btn:hover { background-color: #F8FAFC; color: #94A3B8;}
.draft-view-all-box { display: flex; flex-direction: column; justify-content: center; border-radius: 12px; border: 1px solid #F1F5F9; background: #FFF; width: 252px; padding: 34px 64px;}
.draft-view-all-box img { height: 64px;}
.view-all-box-btn { border-radius: 8px; padding: 16px; color: #172742; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; border: none; background-color: transparent;}
.recruiter-detail { border-radius: 16px 24px 24px 16px; background: #FFF; padding: 16px;}

@media(max-width: 480px) {
    .draft-card { width: 100%;}
    .recruiter-container { background-color: #F1F5F9; padding: 12px 20px; overflow: hidden; position: relative;}
    .custom-accordion { padding: 16px; border-radius: 12px; border: 1px solid #CBD5E1; background: #FFF;}
    .acc-input input { font-size: 14px; line-height: 20px; padding: 8px 10px; }
    .acc-input textarea { font-size: 14px; line-height: 20px; padding: 8px 10px; }
    .draft-container { padding: 20px 0 20px 20px; }
}


/* Draft Page css Start */

.draft-search { border-radius: 8px; background: #FFF; box-shadow: 0px 2px 4px 0px rgba(46, 61, 85, 0.10); padding: 8px 16px; width: 246px; display: flex; justify-content: space-between;}
.draft-search input { border: none; outline: none; color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 500; line-height: normal;}
.draft-container .draft-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px;}
.draft-dropdown { border-radius: 16px; background: #E2E8F0; padding: 4px 16px;}
.draft-dropdown .dropdown-btn { cursor: pointer; display: flex; align-items: center; justify-content: space-between; transition: all 0.5s; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 20px; border: none;}
.draft-dropdown:hover { border-radius: 16px; background: #172742;}
.draft-dropdown:hover .dropdown-btn { color: #F8FAFC;}
.draft-dropdown:hover .draft-dropdown-content { display: block;}
.draft-dropdown-content { margin-top: 8px; position: absolute; z-index: 100; width: 250px; padding: 4px; border-radius: 8px; background: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); display: none;}
.draft-dropdown-content .item { border-radius: 8px; background: #F1F8FE; cursor: pointer; padding: 8px 16px; color: #0F172A; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; }
.draft-dropdown-content .item:hover { background: #FFF; border-radius: 8px; }

/* Draft Page css End */


/* Table Page css Start */

.table-select-btn { border-radius: 4px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; display: flex; width: 100%; padding: 4px 5px; align-items: center; gap: 10px; border: none;}
.table-container { display: flex; padding: 16px 20px 32px 20px; flex-direction: column; align-items: flex-start; gap: 24px; border-radius: 24px 0px 0px 24px; background-color: #FFF; }
.table-overflow {width: 100%; overflow-x: scroll; height:auto; position: relative; height: 500px;}
.table-overflow::-webkit-scrollbar { height: 4px; width: 7px;}
.table-overflow::-webkit-scrollbar-track { background: transparent; }
.table-overflow::-webkit-scrollbar-thumb { background: #94A3B8; border-radius: 8px;}
.table-container .table-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px;}
.table-container .download-btn { border-radius: 8px; background-color: #FFCA27; border: none; padding: 6px 8px 6px 12px; width: 160px; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; display: flex; align-items: center; justify-content: center;}
.table-body { width: 1550px;}
.table-body thead tr th { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #E2E8F0; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 130%;  position: sticky;top: 0;z-index: 1; }
.table-body tbody tr .first-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .second-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; text-decoration-line: underline; transition: all 0.5s;}
.table-body tbody tr .second-box:hover { color: #0D9488;}
.table-body tbody tr .third-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .four-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .screen-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .five-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%;}
.table-body tbody tr .six-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .seven-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .seven-box button { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; border-radius: 4px; background: #F1F5F9; border: none; padding: 3px 4px;}
.table-body tbody tr .eight-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #0D9488; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; }
.table-body tbody tr .nine-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; }
.table-body tbody tr .ten-box { border-top: 1px solid #F1F5F9; border-left: 1px solid #F1F5F9; background-color: #FFF; padding: 10px 12px; color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 130%; width: 180px;}
.interviewing-btn { border-radius: 4px; background: #F59E0B; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}
.decision-btn { border-radius: 4px; background: #DBD9D9; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}
.hire-btn { border-radius: 4px; background: #2DD4BF; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}
.not-hire-btn { border-radius: 4px; background: #FB7185; padding: 4px 5px; color: #000; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 130%; border: none;}

.table-box { border-radius: 12px; background: #FFF; padding: 16px;}
.table-box .table-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.table-card { padding: 12px; border-radius: 8px; border: 1px solid #E2E8F0; background: #FFF;}
.table-card .table-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 4px;}
.table-card .table-card-email { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 4px;}
.table-card .table-card-date { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 4px; text-align: right;}
.table-card .table-card-date-text { color: #000; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; text-decoration-line: underline; text-align: right;}
.table-card .table-card-border { border: 1px solid #F1F5F9; width: 100%; margin: 8px 0;}
.table-card .table-card-summary { color: #262626; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; }
.table-card .table-card-summary-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px;}
.table-card .table-card-summary-text input { border: none; outline: none;}
.table-card .table-card-status { color: #808080; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 10px; }
.table-card .view-profile-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-top: 8px; border-radius: 8px; background: #F1F5F9; transition: all 0.5s; border: none; padding: 6px 12px; width: 100%;}
.table-card .view-profile-btn:hover {color: #11274B; background: #E2E8F0;}


/* Table Page css End */

/* interviews Page css Start */

.interviews-container { border-radius: 24px; background: #FFF; padding: 16px 24px; margin: auto; max-width: 1095px; display: flex; flex-direction: column; gap: 20px;}
.interviews-container .interview-title { margin: 0; color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.interviews-container .interview-btn { display: flex; align-items: center; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; background: #FFCA27; padding: 8px 12px; border: none;}
.interviews-container .card-container { display: flex; gap: 16px; flex-wrap: wrap;}
.interview-card { border-radius: 12px; border: 1px solid #F1F5F9; background: #fff; padding: 16px; width: 242px; position: relative; transition: all 0.5s;}
.interview-card:hover { background: #F8FAFC;}
.interview-card .interview-card-dropdown { position: absolute; flex-direction: column; width: 166px; border-radius: 8px; background: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); z-index: 100; right: -120px; top: 45px; transition: all 0.5s;}
.interview-card .interview-card-dropdown button { border: none; background-color: transparent; color: #0F172A; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; text-align: left; padding: 9px 16px;}
.interview-card .interview-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px;}
.interview-card .interview-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px;}
.interview-card .dot-btn { border: none; background-color: transparent;}
.interview-card .open-record-btn { border: none; width: 100%; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; padding: 6px 12px; border-radius: 8px; background: #F1F5F9; transition: all 0.5s;}
.interview-card .open-record-btn:hover { background: #E2E8F0; color: #11274B;}
.selected-item { border-radius: 16px; background: #F1F5F9; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-right: 4px; padding: 4px 8px; margin-bottom: 4px;}
.remove-btn { border-radius: 8px; background: #F1F5F9; color: #475569; font-family: 'Inter',sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; padding: 16px; width: 280px; border: none; height: 56px; margin-right: 24px; transition: all 0.5s;}
.remove-btn:hover { color: #11274B; background-color: #E2E8F0;}
.invite-btn {border-radius: 8px; background: #FFCA27; display: flex; align-items: center; justify-content: center; color: #2E3D55; font-family: 'Inter',sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; padding: 16px; width: 280px; border: none; height: 56px; transition: all 0.5s;}
.invite-btn:hover { background-color: #FFC000; color: #64748B;}

@media(max-width: 480px) {
    .interview-card { border-radius: 12px; border: 1px solid #F1F5F9; background: #fff; padding: 16px; width: auto; position: relative; transition: all 0.5s; margin-bottom: 8px;}
    .interview-card .interview-card-dropdown { right: 0;}
    .table-select-btn {width: 36%;}
}

/* interviews Page css End */


/* interviews Page Modal css Start */

.all-app-dropdown { position: absolute; flex-direction: column; width: 166px; border-radius: 8px; background: #F1F8FE; box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15); z-index: 100; right: -135px; bottom: 0px; transition: all 0.5s;}
.all-app-dropdown button { border: none; background-color: transparent; color: #0F172A; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; text-align: left; padding: 9px 16px;}

.remove-interviews-modal { width: 403px; position: relative; margin: auto; background-color: #ffffff; padding: 48px 32px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); margin-top: 107px; margin-bottom: 107px; }
.remove-interviews-modal .close-btn { position: absolute; top: -30px; right: 0px;}
.remove-interviews-modal .remove-modal-title { color: #11274B; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 48px;}
.remove-interviews-modal .remove-modal-btn { border: none; padding: 6px 12px; width: 100%; border-radius: 8px; background: #FFCA27; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 20px; transition: all 0.5s;}
.remove-interviews-modal .remove-modal-btn:hover { color: #64748B; background-color: #FFC000;}
.remove-interviews-modal .no-modal-btn { border: none; padding: 6px 12px; width: 100%; border-radius: 8px; background: #F1F5F9; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.remove-interviews-modal .no-modal-btn:hover { background: #E2E8F0; color: #11274B;}

.assign-modal-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgb(68, 81, 103, 0.70); z-index: 1000; overflow-y: scroll;}
.assign-modal { width: 636px; position: relative; background-color: #ffffff; padding: 48px 32px; border-radius: 12px; box-shadow: -2px 4px 8px 0px rgba(23, 39, 66, 0.30); transform: translate(-50%, -50%); top: 50%; left: 50%;}
.assign-modal .close-btn { position: absolute; top: -30px; right: 0px;}
.assign-modal .assign-modal-title { color: #000; font-family: 'Inter', sans-serif; font-size: 18px; font-style: normal; font-weight: 600; line-height: 22px; margin-bottom: 12px;}
.assign-modal .assign-modal-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; margin-bottom: 32px;}
.assign-modal .assign-job-label { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal;font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 4px;}
.assign-modal .select-option { border-radius: 16px; background: #F1F5F9; padding: 6px 8px; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-right: 4px; margin-bottom: 4px;}
.assign-modal .assign-modal-btn { margin-top: 32px;}
.assign-modal .remove-modal-btn { border: none; padding: 6px 12px; width: 100%; border-radius: 8px; background: #FFCA27; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 20px; transition: all 0.5s;}
.assign-modal .remove-modal-btn:hover { color: #64748B; background-color: #FFC000;}
.assign-modal .no-modal-btn { border: none; padding: 6px 12px; width: 100%; border-radius: 8px; background: #F1F5F9; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.assign-modal .no-modal-btn:hover { background: #E2E8F0; color: #11274B;}


@media(max-width: 480px) {
    .assign-modal { width: auto; margin: 0 20px; left: unset; transform: translate(0, -50%); padding: 48px 20px;}
    .assign-modal .assign-modal-title { font-size: 16px; font-style: normal; font-weight: 600; line-height: 20px; margin-bottom: 4px;}
    .assign-modal .assign-modal-text { font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-bottom: 20px;}
    .assign-modal .assign-modal-btn { margin-top: 20px;}
}
/* interviews Page Modal css End */


/* Sidebar css Start */

.dashboard { background-color: #F1F5F9; padding-top: 84px; position: relative; z-index: 10; padding-bottom: 100px; height: 156vh;}
.dashboard-box { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); height: 100%; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; padding-bottom: 15px;}
.dashboard .recruiter-sidebar { position: fixed; top: 80px; z-index: 15; border-radius: 0px 0 42px 0; background: #172742; padding: 9px 0 9px 0px; display: flex; left: 410px; transition: left 0.3s ease; height: 608px;}
.side-icon-box { position: relative; background-color: #172742; padding: 9px; border-radius: 0px 0 24px 24px; position: fixed; z-index: 20;}
.recruiter-icon { border-radius: 20px; background: #F6F6FA; position: relative; z-index: 20; display: flex; flex-direction: column; width: 67px; }
.recruiter-icon a { padding: 15px 24px; position: relative;}
.recruiter-icon a.active::before { position: absolute; content: ""; height: 49px; width: 5px; background-color: #172742; left: 0; border-radius: 0 16px 16px 0; top: 8px;}
.border { width: 28px; margin: 2px auto;}
.sidebar-text { position: relative; padding: 9px 0 9px 0;}
.search-btn { border-radius: 8px; background-color: #2E3D55; padding: 8px 16px;}
.search-btn .search-input { background-color: transparent; border: none; outline: none; color: #FFF !important; font-family: "Inter", sans-serif; font-size: 12px; font-style: normal; font-weight: 500; line-height: normal;}
.media-title { padding: 9px 0; color: #BCBCC1; font-family: "Inter", sans-serif; font-size: 12px; font-style: normal; font-weight: 300; line-height: normal;}
.recruiter-sidebar.open { left: 85px; }  
.recruiter-sidebar.closed { left: -360px; }  
.pie-chart { height: 130px; width: 130px; border-radius: 50%; position: relative; }
.pie-chart .pie-total { position: absolute; left: 30px; top: 25%; color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 500; line-height: 14px; }
.pie-chart .pie-closed { position: absolute; right: 30px; top: 65%; color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 500; line-height: 14px; }
.pie-chart .chart-total { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); height: 40px; width: 40px; background-color: #FFC000; filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.20)); border-radius: 50%; display: flex; justify-content: center; align-items: center; color: #262626; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 14px; }
.pie-chart-2 { height: 130px; width: 130px; border-radius: 50%; position: relative; }
.pie-chart-2 .pie-total-2 { position: absolute; left: 30px; top: 25%; color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 500; line-height: 14px; }
.pie-chart-2 .pie-closed-2 { position: absolute; right: 15px; top: 45%; color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 500; line-height: 14px; }
.pie-chart-2 .pie-process-2 { position: absolute; right: 85px; top: 70%; color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 500; line-height: 14px; }
.pie-chart-2 .chart-total { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); height: 40px; width: 40px; background-color: #FFC000; filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.20)); border-radius: 50%; display: flex; justify-content: center; align-items: center; color: #262626; font-family: 'Inter', sans-serif; font-size: 8px; font-style: normal; font-weight: 400; line-height: 14px; }


@media(min-width: 1024px) {
    .dashboard { padding-top: 80px; }
    .dashboard-box { background-color: #F1F5F9; display: flex; background-image: unset; background-attachment: fixed;}
}
.sidebar-wrapper { display: flex; flex-direction: row; background-color: #f1f5f9;}
/* .sidebar-top-wrapper { position: absolute; background-image: url('../../assets/sideBar/sidebar-wrapper.svg'); height: 40px; width: 40px; right: -40px; top: 0;} */
.sidemenu { list-style-type: none; padding: 0; margin: 0; color: #fff; }
.sidemenu li { padding: 15px 5px 15px 22px; background-color: #172742;}
.sidemenu li a { text-decoration: none; color: #FFF; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 300; line-height: normal;}
.sidemenu li.active a { color: #010023; font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; background-color: #f1f5f9; border-radius: 30px; }
.sidemenu li.active { font-family: 'Inter', sans-serif; font-size: 16px; font-style: normal; font-weight: 600; line-height: normal; background-color: #f1f5f9; border-radius: 30px; position: relative;}
.sidemenu li.active::before { position: absolute; content: ""; background-image: url('../../assets/sideBar/sidebarBorder.svg'); height: 50px; width: 22px; background-repeat: no-repeat; background-size: cover; background-position: center; left: 0px; top: 0px;}
.sidemenu li.active + li  { border-top-right-radius: 20px; }
.sidemenu li:has(+ .active)  { border-bottom-right-radius: 20px; }
.sidebar.open { left: 85px; }  
.sidebar.closed { left: -360px; }  
.close-button { margin: 10px; }



@media(max-width: 480px) {
    .dashboard { padding-top: 84px; padding-bottom: 0; height: 100%;}
    .side-icon-box { display: none;}
    .dashboard .sidebar { display: none;}
    .mobile-box { background-image: url('../../assets/mobile-hero-bg.svg'); width: 100%; height: 100%; padding: 10px 12px; background-position: center; background-attachment: fixed;}
}
/* Sidebar css End */


/* Post New Job css Start */

.post-job-container { position: absolute; padding: 20px 24px; margin: 20px; transition: left 0.3s ease; border-radius: 24px; background-color: #FFF; right: 0; display: flex; flex-direction: column; gap: 12px;}
.post-jov-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin: 0;}
.view-draft-btn { border: none; border-radius: 8px; background: #F1F5F9; padding: 6px 12px; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; transition: all 0.5s;}
.view-draft-btn:hover { background: #E2E8F0; color: #11274B;}
.post-draft-container { position: absolute; margin: 20px 20px 78px 20px; transition: left 0.3s ease; right: 0; display: flex; flex-direction: column; gap: 20px;}
.post-draft-container .post-draft-box {padding: 20px 24px; border-radius: 24px; background-color: #FFF;}
.post-review-container { position: absolute; padding: 16px; margin: 20px 20px 78px 20px; transition: left 0.3s ease; border-radius: 24px; background-color: #FFF; right: 0;}
.post-review-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 4px;}
.post-review-text { color: #000; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0px;}
.post-review-list { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.post-job-review-title { color: #262626; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 16px; margin: 0;}
.post-job-review-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }
.preview-link-title { margin-bottom: 4px; color:#808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }
.preview-link { color: #11274B; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 8px;}

/* Post New Job css End */


/* ALl Job css Start */

.all-job-container { position: absolute; margin: 20px; transition: left 0.3s ease; right: 0; display: flex; flex-direction: column; gap: 16px;}
.all-job-container .all-job-box { padding: 16px 24px; border-radius: 24px; background-color: #FFF;}
.all-job-title { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin: 0;}

/* ALl Job css End */

.slick-dots { bottom: -25px !important; display: flex !important; justify-content: center; gap: 7px;}
.slick-dots li { margin: 0 !important; height: 3px; width: 3px;}
.slick-dots li button { width: 0; height: 0; padding: 0; transition: all 0.5s;}
.slick-dots li button:before { width: 3px; height: 3px; background-color: #94A3B8; opacity: 1; color: transparent; border-radius: 50%;}
.slick-dots li.slick-active button:before { width: 16px; height: 3px; background-color: #11274B; border-radius: 25px; opacity: 1; color: transparent; transform: translate(-13px, 0);}

@media(max-width: 480px) {
    .slick-dots { bottom: -14px !important;}
}

.bottom-box { padding: 20px; border-top: 1px solid #2E3D55; background: #172742; box-shadow: 8px 0px 20px 0px rgba(255, 255, 255, 0.10); bottom: 0; width: 100%; display: flex; gap: 20px; flex-direction: column;}
.bottom-preview-btn { color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; height: 36px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; background: #FFCA27; padding: 6px 12px; border: none; transition: all 0.5s;}
.bottom-preview-btn:hover { color: #64748B; }
.bottom-clear-btn { color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; height: 36px; font-style: normal; font-weight: 600; line-height: 20px; border-radius: 8px; background: #F1F5F9; padding: 6px 12px; border: none; transition: all 0.5s;}
.bottom-clear-btn:hover { color: #11274B; background: #E2E8F0;}

.profile-detail-box { padding: 16px 0 24px 0; background-color: #FFF; border-radius: 16px 24px 24px 16px;}
.profile-detail-content { display: flex; padding: 16px 24px; flex-direction: column; align-items: flex-start; gap: 14px; }
.back-profile-btn { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin-bottom: 0; display: flex; align-items: center;}
.profile-detail { display: flex; gap: 12px; flex-direction: column;}
.profile-detail .profile-name { display: flex; gap: 100px; align-items: center; }
.profile-name .profile-title { width: 150px; color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 0;}
.profile-name .profile-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 16px;font-style: normal; font-weight: 400; line-height: 24px; margin: 0;}
.profile-center-border { border-bottom: 1px solid #E2E8F0; margin: 14px 0;}
.back-profile-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }

@media(max-width: 480px) {
    .profile-detail-content { background-color: #FFF; border-radius: 12px; padding: 16px; gap: 12px;}
    .profile-name .profile-title { width: 120px;}
    .profile-name .profile-text { font-size: 14px; line-height: 18px; text-align: right;}
    .profile-detail { width: 100%;}
    .profile-detail .profile-name { display: flex; gap: unset; align-items: center; justify-content: space-between; width: 100%;}
    .back-profile-text { margin-bottom: 12px;}
    .profile-mobile-box { display: flex; flex-direction: column; gap: 8px;}
}

.profile-detail- .assign-job-label { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal;font-weight: 600; line-height: 12px; text-transform: uppercase; margin-bottom: 4px;}
.profile-detail- .select-option { border-radius: 16px; background: #F1F5F9; padding: 6px 8px; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-right: 4px; margin-bottom: 4px;}

.select-option { border-radius: 16px; background: #F1F5F9; padding: 6px 8px; color: #475569; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 600; line-height: 16px; margin-right: 4px; margin-bottom: 4px;}

.save-btn- { border-radius: 8px; background: #FFCA27; color: #2E3D55; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 200px; padding: 6px 12px; border: none; height: 36px; transition: all 0.5s;}
.save-btn-:hover { color: #64748B; background: #FFC000;}
.edit-btn { border-radius: 8px; background: #F1F5F9; color: #475569; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; width: 200px; padding: 6px 12px; border: none; height: 36px; transition: all 0.5s;}
.edit-btn:hover { color: #11274B; background: #E2E8F0;}


.dummy-footer { margin-top: 24px; display: flex; padding: 16px 100px; justify-content: space-between; align-items: center; background: #172742;}
.dummy-reach { display: flex; align-items: center;}
.dummy-follow { display: flex; align-items: center; justify-content: end;}
.dummy-footer .dummy-footer-title { color: #DBD9D9; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; margin: 0; padding-right: 18px;}
.dummy-footer .dummy-link { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; border-right: 1px solid #808080; margin-right: 12px; padding-right: 12px; text-decoration: none;}
.dummy-footer .dummy-contact { color: #FFF; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; text-decoration: none;}
.dummy-footer .copyright-text { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 0;}


@media(max-width: 480px) {
    .dummy-reach { display: flex; flex-direction: column; align-items: center;}
    .dummy-follow { display: flex; align-items: center; justify-content: center; flex-direction: column;}
    .dummy-footer { margin-top: 11px; display: flex; flex-direction: column; padding: 16px 40px; justify-content: space-between; align-items: center; background: #172742; gap: 14px;}
    .dummy-footer .dummy-footer-title { margin: 8px 0 0 0; padding-right: 0px;}
    .dummy-footer .copyright-text { margin-top: 14px;}
}
.sidebar-scroll { display: flex; position: fixed; top: 80px; height: 660px;}
/* .sidebar-scroll { overflow-y: scroll; overflow-x: hidden; display: flex; position: sticky; top: 80px; height: 660px;} */
.table-selector { border-radius: 50px; background-color: rgb(226, 232, 240); padding: 4px 16px; color: rgb(71, 85, 105); font-family: 'Inter', sans-serif; font-size: 12px; font-weight: 600; line-height: 16px; border: none; outline: none;}

.up-scroll { display: flex; flex-direction: column; width: 100%; overflow-y: scroll; gap: 4px;}
.up-scroll::-webkit-scrollbar { display: none;}

.zero-circle { position: relative; background-color: #fff; border-radius: 50%; height: 150px; width: 150px;}
.zero-box-center { position: absolute; background-color: #FFC000; height: 70px; width: 70px; transform: translate(-50%, -50%); top: 50%; left: 50%; border-radius: 50%; display: flex; justify-content: center; align-items: center;}
.total-box-center { position: absolute; background-color: #FFC000; height: 70px; width: 70px; transform: translate(-50%, -50%); top: 53%; left: 50%; border-radius: 50%; display: flex; justify-content: center; align-items: center;}

@media(max-width: 480px) {
    .zero-circle { height: 100px; width: 100px;}
    .zero-box-center { position: absolute; background-color: #FFC000; height: 35px; width: 35px; transform: translate(-50%, -50%); top: 50%; left: 50%; border-radius: 50%; display: flex; justify-content: center; align-items: center;}
    .total-box-center { position: absolute; background-color: #FFC000; height: 45px; width: 45px; transform: translate(-50%, -50%); top: 55%; left: 50%; border-radius: 50%; display: flex; justify-content: center; align-items: center;}
    .table-card-text { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 8px;}
    .table-normal-text { color: #262626; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 8px;}
    .table-bold-text { color: #262626; text-align: right; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; padding-bottom: 8px;}
    .screen-text-bold { color: #475569; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 12px; padding-bottom: 4px;}
    .screen-text-normal { color: #4D4D4D; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
}

.view-cards { border-radius: 12px; border: 1px solid #F1F5F9; background: #FFF; display: flex; padding: 16px 0px; flex-direction: column; gap: 12px; margin-top: 8px;}
.view-title { color: #B3B3B3; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 14px; text-transform: uppercase; padding: 0 16px;}
.view-cards .table-card-status { color: #262626; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-left: 16px;}
.view-cards .table-select-btn { padding: 8px; border-radius: 4px;}
.view-cards .view-border { width: 100%; border-bottom: 1px solid #E2E8F0;}
.view-text-aria { margin: 0 16px; padding: 8px 10px; border-radius: 8px; border: 1px solid  #808080; background:  #FFF; overflow: hidden; color: #262626; text-overflow: ellipsis; white-space: nowrap; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; }


.summary-box { height: 100px; overflow-y: auto; overflow-x: hidden;}
.summary-box::-webkit-scrollbar { width: 3px; }
.summary-box::-webkit-scrollbar-track { background: transparent; }
.summary-box::-webkit-scrollbar-thumb { background: #94A3B8; border-radius: 8px;}


.input-error-css { color: #E11D48; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; text-align: left; text-transform: capitalize; padding-top: 4px;margin-bottom: 0;}


.card-container .open-card { border-radius: 12px; border: 1px solid #CBD5E1; background-color: #F8FAFC; padding: 16px; margin-right: 16px; min-width: 185px;}
.card-container .open-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.card-container .open-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.card-container .open-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.card-container .open-card-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.card-container .open-card-dot { height: 4px; width: 4px; background-color: #D9D9D9; border-radius: 50%; margin: 0 6px;}
.card-container .chat-btn { margin-top: 8px; border-radius: 8px; border: 1px solid #F1F5F9; background-color: #FFF; padding: 4px 8px; display: flex; flex-direction: column; align-items: center;}
.card-container .chat-btn .chat-btn-text { color: #4D4D4D; text-align: center; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 600; line-height: 16px; }

.open-mobile-card { border-radius: 12px; border: 1px solid #E2E8F0; background-color: #ffffff; padding: 12px; margin-bottom: 4px;}
.open-mobile-card-title { color: #000; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 600; line-height: 18px; }
.open-mobile-card-text { color: #808080; font-family: 'Inter', sans-serif; font-size: 12px; font-style: normal; font-weight: 400; line-height: 16px; padding-top: 4px;}
.open-mobile-card-border { border-bottom: 1px solid #F1F5F9; margin: 8px 0;}
.open-mobile-card-item { color: #808080; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; }
.open-mobile-card-dot { height: 4px; width: 4px; background-color: #D9D9D9; border-radius: 50%; margin: 0 6px;}

@media (max-width: 480px){
    .dashboard-box { background-color: unset; display: block; background-image: url('../../assets/login/login-hero.svg'); height: 100%; width: 100%; object-fit: cover; background-repeat: no-repeat; background-size: cover; background-position: center; position: relative; z-index: 10; background-attachment: fixed;}
    .mobile-recommended {
        padding-top: 12px 
        px
        ;
        padding-left: 12px;
        padding-right: 12px;
    }
}