.mobile-recruiter-menu-box { background-color: #172742; position: fixed; bottom: 19px; right: 8px; border-radius: 12px; height: 0; width: 276px; margin-bottom: 100px; z-index: 11;}
.mobile-recruiter-menu { position: relative;}
.mobile-menu-item { padding: 8px 16px;  border-radius: 3px; display: flex; align-items: center; transition: all 0.3s; margin-bottom: 16px;}
.mobile-menu-item img { padding-right: 8px;}
.mobile-menu-item a { text-decoration: none; color: #FFF; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; transition: all 0.3s; }
.mobile-menu-item:hover { background-color: #F1F5F9; }
.mobile-media-title { padding-left: 0px; color: #BCBCC1; font-family: 'Inter', sans-serif; font-size: 10px; font-style: normal; font-weight: 400; line-height: 16px; padding-bottom: 16px;}
.show-on-hover { display: none; }
.mobile-menu-item:hover .show-on-hover { display: block; }
.mobile-menu-item:hover .hide-on-hover { display: none; }
.mobile-recruiter-menu-wrapper { position: absolute; background-image: url('../../assets/mobile/mobile-wrapper.svg');  bottom: -90px; height: 100px; width: 276px; z-index: 11; right: 0px; background-repeat: no-repeat;}
.menu-border { border-bottom: 1px solid #2E3D55; margin: 20px 0;}

.active-header { background-color: #F1F5F9;  padding: 8px 16px;  border-radius: 3px; display: flex; align-items: center; transition: all 0.3s; margin-bottom: 16px; }
.active-header a { color: #262626; text-decoration: none; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; transition: all 0.3s; }
.active-header .show-on-hover { display: block; }
.active-header .hide-on-hover { display: none; }
.active-header img { padding-right: 8px;}


.active-header { background-color: #F1F5F9;  padding: 8px 16px;  border-radius: 3px; display: flex; align-items: center; transition: all 0.3s; margin-bottom: 16px; }
.active-header a { color: #262626; text-decoration: none; font-family: 'Inter', sans-serif; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18px; transition: all 0.3s; }
.active-header .show-on-hover { display: block; }
.active-header .hide-on-hover { display: none; }
.active-header img { padding-right: 8px;}